import React, { useState, useEffect } from 'react';
import './dispute.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Dashboard/SideBar/sidebar';
import MenuBar from '../Dashboard/Menubar/menubar';
import { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { creditReport } from '../../redux/actions/dashboard_action';
import { Dropdown } from 'bootstrap';
import DatePicker from "react-datepicker";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import { createDisputeData } from '../../redux/actions/dashboard_action';
import Loader from '../loader/loader';

import "react-datepicker/dist/react-datepicker.css";

const GoBackImg = require("../../images/assets/backgo.png");
const AxisBank = require("../../images/Bank/Bank.png");

const CreateDispute = (props) => {
    const creditData = useLocation();
    const [creditDisputeData, setcreditDisputeData] = useState(creditData.state);

    const navigate = useNavigate();


    const [name, setName] = useState("");

    const [cdateOfBirth, setDateOfBirth] = useState("");
    const [dateOfBirth, setdateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [pan, setPan] = useState("");

    const [accountHolderName, setAccountHodlerName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [cdateOpened, setdateOpened] = useState("");
    const [dateOpened, setDateOpened] = useState("");

    const [cdateClosed, setdateClosed] = useState("");
    const [dateClosed, setDateClosed] = useState("");

    const [ownership, setOwnership] = useState("");

    const [startDate, setStartDate] = useState("");
    const [dateReported, setDateReported] = useState("");

    const [loanType, setLoanType] = useState("");
    const [accountStatus, setAccountStatus] = useState("");
    const [amountOverdue, setAmountOverdue] = useState("");
    const [repaymentTenure, setRepaymentTenure] = useState("");
    const [emiAmount, setEmiAmount] = useState("");

    const [activeTab, setActiveTab] = useState(1);
    const [loader, setLoader] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const handleTabSubmit = (tabNumber) => {
        setActiveTab(tabNumber);
        // if(tabNumber === 1){
        //     setActiveTab(2);
        // }
    };

    const creditDisputeJson = {
        "bureauName": "EXPERIAN",
        "accountNumber": creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.accountNumber ? creditDisputeData.creditAccountTerms.accountNumber : "",
        "lender": creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.lender ? creditDisputeData.creditAccountInformation.lender : "",
        "userReferenceId": "",
        "mobileNumber": creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.phoneDetails && creditDisputeData.consumerDetails.phoneDetails[1] && creditDisputeData.consumerDetails.phoneDetails[1].phoneNumber ? creditDisputeData.consumerDetails.phoneDetails[1].phoneNumber : "",
        "personalInformation": {
            "name": {
                "existing": creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountHolderName ? creditDisputeData.creditAccountInformation.accountHolderName : "",
                "updated": name,
            },
            "dateOfBirth": {
                "existing": creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.dateOfBirth ? creditDisputeData.consumerDetails.dateOfBirth : "",
                "updated": dateOfBirth,
            },
            "gender": {
                "existing": creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.gender ? creditDisputeData.consumerDetails.gender : "",
                "updated": gender,
            },
            "pan": {
                "existing": creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.identificationDetails && creditDisputeData.consumerDetails.identificationDetails[0].idNumber ? creditDisputeData.consumerDetails.identificationDetails[0].idNumber : "",
                "updated": pan
            }
        },
        "creditAccountInformationDetails": {
            "creditAccountInformation": {
                "accountHolderName": {
                    "existing": creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountHolderName ? creditDisputeData.creditAccountInformation.accountHolderName : "",
                    "updated": accountHolderName
                },
                "accountType": {
                    "existing": creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountType ? creditDisputeData.creditAccountInformation.accountType : "",
                    "updated": accountType
                }
            },
            "creditAccountTerms": {
                "dateOpened": {
                    "existing": creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.dateOpened ? creditDisputeData.creditAccountTerms.dateOpened : "",
                    "updated": dateOpened
                },
                "dateClosed": {
                    "existing": creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.dateClosed ? creditDisputeData.creditAccountTerms.dateClosed : "",
                    "updated": dateClosed
                },
                "ownership": {
                    "existing": creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.ownership ? creditDisputeData.creditAccountTerms.ownership : "",
                    "updated": ownership
                }
            },
            "creditAccountDescription": {
                "dateReported": {
                    "existing": creditDisputeData.creditAccountDescription && creditDisputeData.creditAccountDescription.dateReported ? creditDisputeData.creditAccountDescription.dateReported : "",
                    "updated": dateReported
                },
                "loanType": {
                    "existing": creditDisputeData.creditAccountDescription && creditDisputeData.creditAccountDescription.loanType ? creditDisputeData.creditAccountDescription.loanType : "",
                    "updated": loanType
                },
                "accountStatus": {
                    "existing": creditDisputeData.creditAccountDescription && creditDisputeData.creditAccountDescription.accountStatus ? creditDisputeData.creditAccountDescription.accountStatus : "",
                    "updated": accountStatus
                },
                "amountOverdue": {
                    "existing": creditDisputeData.creditAccountDescription && creditDisputeData.creditAccountDescription.amountOverdue ? creditDisputeData.creditAccountDescription.amountOverdue : "",
                    "updated": amountOverdue
                }
            },
            "creditAccountDetails": {
                "repaymentTenure": {
                    "existing": creditDisputeData.creditAccountDetails && creditDisputeData.creditAccountDetails.repaymentTenure ? creditDisputeData.creditAccountDetails.repaymentTenure : "",
                    "updated": repaymentTenure
                },
                "emiAmount": {
                    "existing": creditDisputeData.creditAccountDetails && creditDisputeData.creditAccountDetails.emiAmount ? creditDisputeData.creditAccountDetails.emiAmount : "",
                    "updated": emiAmount
                }
            }
        }
    };

    const handleChange = (date) => {
        setStartDate(date)
        const formattedDate = moment(date).format('DD-MM-YYYY');
        setDateReported(formattedDate)
    };

    const handleChangedateOpen = (date) => {
        setdateOpened(date)
        const formattedDate = moment(date).format('DD-MM-YYYY');
        setDateOpened(formattedDate)
    };

    const handleChangedateClose = (date) => {
        setdateClosed(date)
        const formattedDate = moment(date).format('DD-MM-YYYY');
        setDateClosed(formattedDate)
    };

    const handleChangedateBirth = (date) => {
        setDateOfBirth(date)
        const formattedDate = moment(date).format('DD-MM-YYYY');
        setdateOfBirth(formattedDate)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        //  console.log("accountHolderName", accountHolderName);
        //  console.log("dateReported", dateReported);
        //  console.log(creditDisputeJson);
        // console.log('Final tab submitted:', activeTab);
        setLoader(true);
        createDisputeData(creditDisputeJson).then(resp => {
            setLoader(false);
            setIsVisible(true);
            // console.log(resp);
        }).catch((error) => {
            setLoader(false);
            //setLoader(false);
        })
    };

    const GoBack = () => {
        navigate('/disputelist')
    }

    return (
        <>
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                {loader && <Loader />}
                <div id='disputeDetails' className="sidebat-inner-box sidnav-inner-box pl-10">
                    <div className='container-lg container-fluid my-3 '>
                        <div className="go-back" onClick={() => { GoBack() }}><img src={GoBackImg} /> Go Back</div>

                        <div className='bank-information'>
                            <p><img src={AxisBank} width={30}></img> {creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.lender ? creditDisputeData.creditAccountInformation.lender : ""}, {creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountType ? creditDisputeData.creditAccountInformation.accountType : ""}, {creditDisputeData.creditAccountTerms ? creditDisputeData.creditAccountTerms.accountNumber : ""}</p>
                        </div>
                        {isVisible && <div className="alert alert-success" role="alert">
                            Your dispute has been raised successfully
                        </div>}
                        <Tabs>
                            <TabList>
                                <Tab>Account</Tab>
                                <Tab>Personal</Tab>
                            </TabList>
                            <form className='mt-4 dispute-form' onSubmit={handleSubmit}>
                                <TabPanel onSubmit={() => handleTabSubmit(1)} active={activeTab === 1}>
                                    <div className='col-12 d-flex'>
                                        <div className="col-4 mr-col">
                                            <div className="mb-3">
                                                <label for="accountHoldername" className="form-label">Account Holder Name</label>
                                                <input type="text" className="form-control" id="accountHolderName" name="accountHolderName" value={accountHolderName} onChange={(e) => setAccountHodlerName(e.target.value)} aria-describedby="acHolderName" />
                                                <div id="acHolderName" className="form-text">Current : {creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountHolderName ? creditDisputeData.creditAccountInformation.accountHolderName : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label for="accountType" className="form-label">Account Type</label>
                                                <select name="accountType" className="form-control" aria-describedby="accountType" value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                                                    <option value="">Select Option</option>
                                                    <option value="Saving">Saving</option>
                                                    <option value="Current">Current</option>
                                                    <option value="Education Loan">Education Loan</option>
                                                    <option value="Credit Card">Credit Card</option>
                                                </select>
                                                <div id="accountType" className="form-text">Current : {creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountType ? creditDisputeData.creditAccountInformation.accountType : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label for="accountStatus" className="form-label">Account Status</label>
                                                <select name="accountStatus" className="form-control" aria-describedby="accountStatus" value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                                                    <option value="">Select Option</option>
                                                    <option value="ACTIVE">Active</option>
                                                    <option value="CLOSED">Close</option>
                                                </select>
                                                <div id="accountStatus" className="form-text">Current : {creditDisputeData.creditAccountDescription && creditDisputeData.creditAccountDescription.accountStatus ? creditDisputeData.creditAccountDescription.accountStatus : "N/A"}</div>
                                            </div>

                                            <div className="mb-3 grid-field">
                                                <label for="dateReported" className="form-label">Date Reported</label>
                                                <DatePicker className="form-control datePicker" selected={startDate} onChange={handleChange} dateFormat="dd-MM-yyyy" />
                                                <div id="dateReported" className="form-text">Current : {creditDisputeData.creditAccountDescription && creditDisputeData.creditAccountDescription.dateReported ? creditDisputeData.creditAccountDescription.dateReported : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="emiAmount" className="form-label">EMI Amount</label>
                                                <input type="text" className="form-control" id="emiAmount" name="emiAmount" value={emiAmount} onChange={(e) => setEmiAmount(e.target.value)} placeholder="" aria-describedby="emiAmount" />
                                                <div id="emiAmount" className="form-text">Current : {creditDisputeData.creditAccountDetails && creditDisputeData.creditAccountDetails.emiAmount != "-" && creditDisputeData.creditAccountDetails.emiAmount ? creditDisputeData.creditAccountDetails.emiAmount : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="emiRepaymentHistory" className="form-label">EMI Repayment History</label>
                                                <input type="text" className="form-control" id="emiRepaymentHistory" name="emiRepaymentHistory" value={repaymentTenure} onChange={(e) => setRepaymentTenure(e.target.value)} placeholder="" aria-describedby="emiRepaymentHistory" />
                                                <div id="emiRepaymentHistory" className="form-text">Current : {creditDisputeData.creditAccountDetails && creditDisputeData.creditAccountDetails.repaymentTenure
                                                    ? creditDisputeData.creditAccountDetails
                                                        .repaymentTenure
                                                    : "N/A"}</div>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="mb-3 grid-field">
                                                <label for="accountOpenDate" className="form-label">Account Open Date</label>
                                                <DatePicker className="form-control datePicker" aria-describedby="accountOpenDate" selected={cdateOpened} onChange={handleChangedateOpen} dateFormat="dd-MM-yyyy" />
                                                <div id="accountOpenDate" className="form-text">Current : {creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.dateOpened
                                                    ? creditDisputeData.creditAccountTerms.dateOpened
                                                    : "N/A"}</div>
                                            </div>

                                            <div className="mb-3 grid-field">
                                                <label for="accoutCloseDate" className="form-label">Account Close Date</label>
                                                <DatePicker className="form-control datePicker" aria-describedby="accoutCloseDate" selected={cdateClosed} onChange={handleChangedateClose} dateFormat="dd-MM-yyyy" />
                                                <div id="accoutCloseDate" className="form-text">Current : {creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.dateClosed
                                                    ? creditDisputeData.creditAccountTerms.dateClosed
                                                    : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label for="loanType" className="form-label">Loan Type</label>
                                                <select name="loanType" className="form-control" aria-describedby="loanType" value={loanType} onChange={(e) => setLoanType(e.target.value)}>
                                                    <option value="">Select Option</option>
                                                    <option value="Home Loan">Home Loan</option>
                                                    <option value="Personal Loan">Personal Loan</option>
                                                    <option value="Car Loan">Car Loan</option>
                                                    <option value="Gold Loan">Gold Loan</option>
                                                </select>
                                                <div id="loanType" className="form-text">Current : {creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountDescription.loanType ? creditDisputeData.creditAccountDescription.loanType : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="overdueAmount" className="form-label">Overdue amount</label>
                                                <input type="text" className="form-control" id="overdueAmount" name="overdueAmount" value={amountOverdue} onChange={(e) => setAmountOverdue(e.target.value)} placeholder="" aria-describedby="overdueAmount" />
                                                <div id="overdueAmount" className="form-text">Current : {creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountDescription.amountOverdue && creditDisputeData.creditAccountDescription.amountOverdue != "-" ? "$" + creditDisputeData.creditAccountDescription.amountOverdue : "N/A"}</div>
                                            </div>

                                            <div className="mb-3 grid-field">
                                                <label for="loanOwnership" className="form-label">Loan Ownership</label>
                                                <select name="loanOwnership" className="form-control" aria-describedby="loanOwnership" value={ownership} onChange={(e) => setOwnership(e.target.value)}>
                                                    <option value="">Select Option</option>
                                                    <option value="individual">Individual</option>
                                                    <option value="closed">Close</option>
                                                </select>
                                                <div id="loanOwnership" className="form-text">Current : {creditDisputeData.creditAccountTerms && creditDisputeData.creditAccountTerms.ownership ? creditDisputeData.creditAccountTerms.ownership : "N/A"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel onSubmit={() => handleTabSubmit(1)} active={activeTab === 2}>

                                    <div className='col-12 d-flex'>
                                        <div className="col-4 mr-col">
                                            <div className="mb-3">
                                                <label for="personName" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="personName" name="personName" value={name} onChange={(e) => setName(e.target.value)} aria-describedby="personName" />
                                                <div id="personName" className="form-text">Current : {creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountHolderName ? creditDisputeData.creditAccountInformation.accountHolderName : "N/A"}</div>
                                            </div>

                                            <div className="mb-3 grid-field">
                                                <label for="birthDate" className="form-label">Birthdate</label>
                                                <DatePicker selected={cdateOfBirth} className="form-control datePicker" aria-describedby="birthDate" onChange={handleChangedateBirth} dateFormat="dd-MM-yyyy" />
                                                <div id="birthDate" className="form-text">Current : {creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.dateOfBirth ? creditDisputeData.consumerDetails.dateOfBirth : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label for="gender" className="form-label">Gender</label>
                                                <select name="gender" className="form-control" aria-describedby="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <div id="gender" className="form-text">Current : {creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.gender ? creditDisputeData.consumerDetails.gender : "N/A"}</div>
                                            </div>

                                            <div className="mb-3">
                                                <label for="panNumber" className="form-label">PAN Number</label>
                                                <input type="text" className="form-control" id="panNumber" name="panNumber" value={pan} onChange={(e) => setPan(e.target.value)} aria-describedby="panNumber" />
                                                <div id="panNumber" className="form-text">Current : {creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.identificationDetails && creditDisputeData.consumerDetails.identificationDetails[0].idNumber
                                                    ? creditDisputeData.consumerDetails.identificationDetails[0].idNumber
                                                    : "N/A"}</div>
                                            </div>

                                            {/* <div className="mb-3">
                                                <label for="mobileNumber" className="form-label">Mobile Number</label>
                                                <input type="text" className="form-control" id="mobileNumber" name="mobileNumber" value={pan} aria-describedby="mobileNumber" />
                                                <div id="mobileNumber" className="form-text">Current : N/A</div>
                                            </div>
                                            
                                            <div className="mb-3">
                                                <label for="emailId" className="form-label">Email Id</label>
                                                <input type="text" className="form-control" id="emailId" name="emailId" value="" aria-describedby="emailId" />
                                                <div id="emailId" className="form-text">Current : N/A</div>
                                            </div> */}
                                        </div>

                                        <div className="col-4">
                                            {/* <div className="mb-3">
                                                <label for="address1" className="form-label">Address 1</label>
                                                <input type="text" className="form-control" id="address1" name="address1" value="" aria-describedby="address1" />
                                                <div id="address1" className="form-text">Current : N/A</div>
                                            </div>

                                            <div className="mb-3">
                                                <label for="address2" className="form-label">Address 2</label>
                                                <input type="text" className="form-control" id="address2" name="address2" value="" aria-describedby="address2" />
                                                <div id="address2" className="form-text">Current : N/A</div>
                                            </div> */}

                                        </div>

                                    </div>


                                    {/* <div className='col-12 d-flex'>
                                        <div className='col-6  d-flex'>
                                            <div className='col-4'>
                                                <p>Current</p>

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Name</label>
                                                    <p htmlFor="exampleInputEmail1">{creditDisputeData.creditAccountInformation && creditDisputeData.creditAccountInformation.accountHolderName ? creditDisputeData.creditAccountInformation.accountHolderName : "N/A"}</p>
                                                </div>

                                                <div className="form-group ">
                                                    <label htmlFor="emi_amount">Birthdate</label>
                                                    <p htmlFor="exampleInputEmail1">{creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.dateOfBirth ? creditDisputeData.consumerDetails.dateOfBirth : "N/A"}</p>
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="emi_amount">Gender</label>
                                                    <p htmlFor="exampleInputEmail1">{creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.gender ? creditDisputeData.consumerDetails.gender : "N/A"}</p>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="emi_repayment">PAN Number</label>
                                                    <p htmlFor="exampleInputEmail1">
                                                        {creditDisputeData.consumerDetails && creditDisputeData.consumerDetails.identificationDetails && creditDisputeData.consumerDetails.identificationDetails[0].idNumber
                                                            ? creditDisputeData.consumerDetails.identificationDetails[0].idNumber
                                                            : "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='col-5 form-5'>
                                                <p>Edit</p>
                                                <div className="form-group grid-field">
                                                    <label htmlFor="personName">Name</label>
                                                    <input type="text" id="personName" value={name} onChange={(e) => setName(e.target.value)} name="personName" placeholder="" />
                                                </div>

                                                <div className="form-group grid-field">
                                                    <label htmlFor="dateOfBirth">Birthdate</label>
                                                    <DatePicker selected={cdateOfBirth} onChange={handleChangedateBirth} dateFormat="dd-MM-yyyy"/>
                                                </div>
                                                
                                                <div className="form-group grid-field">
                                                    <label htmlFor="gender">Gender</label>
                                                    <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="pan">PAN Number</label>
                                                    <input type="text" id="pan" name="pan" value={pan} onChange={(e) => setPan(e.target.value)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                </TabPanel>
                                <div className='form-btn'>
                                    {/* <button type="cancel" className='cancel-btn' onClick={() => { GoBack() }}>Cancel</button>  */}
                                    <button type="submit" className='save-btn'>Raise a dispute</button>
                                </div>
                            </form>
                        </Tabs>

                    </div>
                </div>
            </div>
        </>
    )

}

export default CreateDispute;