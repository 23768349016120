import './loader.css';
function Loader() {

    return (
        <>
            <div className="loading">Loading&#8230;</div>
        </>
    );
}

export default Loader;