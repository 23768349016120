
import { useState, useEffect } from "react";
import MenuBar from "../../pages/Dashboard/Menubar/menubar.js";
import Sidebar from "../../pages/Dashboard/SideBar/sidebar.js";

import ProfileHeaderBanner from "./profile_component/profile_header";
import getUserData from "../../Common/helper.js";
import ProfileDropdown from "./profile_component/profile_dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./profile.css";
import { Link } from "react-router-dom";

const Avatar_logo = require("../../images/assets/avatar-profile.png");

const Profile = () => {

  const [businessName, setBusinessName] = useState()
  const [userData, setUserData] = useState();
  useEffect(() => {

    const data = getUserData();
    setUserData(data);

    // getProfileData()
    // .then((resp) => {
    //    if(resp.statusCode === "SUCCESS") {
    //     setFullName(resp.fullName)
    //     setEmail(resp.email)
    //     setBusinessName(resp.businessName)
    //    }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });       
  }, [])

  return (
    <>
      <MenuBar />
      <div className="sidebar-box sidnav-box">
        <Sidebar></Sidebar>

        <div className="sidebat-inner-box sidnav-inner-box">
          <div id="main_section" className="content ">
            <ProfileHeaderBanner name={userData?.name ?? ''} businessName={businessName}></ProfileHeaderBanner>
            <section className="profile-sub-section">
              <p className="profile-sub-section-label">Personal Details</p>
              <div className="profile-sub-section-divider"></div>
            </section>
            <section className="profile-text-form col-12 col-sm-12 col-md-6 col-lg-5">
              <p className="profile-text-field-label">First Name</p>
              <input
                type="text" readOnly
                value={userData?.given_name ?? ''}
                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">Last Name</p>
              <input
                type="text" readOnly value={userData?.family_name ?? ''}
                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">Email</p>
              <input
                type="text" readOnly
                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">Mobile Number</p>
              <input
                type="text" readOnly value={userData?.preferred_username ?? ''}
                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">PAN</p>
              <input
                type="text" readOnly
                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">Passport</p>
              <input
                type="text" readOnly
                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">Date Of Birth</p>
              <input
                type="text" readOnly

                className="profile-text-field-layout"

              ></input>

              <p className="profile-text-field-label">Address</p>
              <textarea
                rows="4" cols="50"
                type="text"
                className="profile-text-area-layout"
              ></textarea>


            </section>
            <section className="space"></section>
          </div>
          {/* <Footer /> */}
        </div>
      </div>




    </>
  );
};
export default Profile;
