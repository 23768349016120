import React from 'react';
import AsyncSelect from 'react-select/async';  // or the appropriate path to your AsyncSelect component
const LANG_IC = require('../../../images/assets/language.png');

const LanguageSelector = ({ options, customStyles, onLanguageChange }) => {

    const handleChange = (selectedOption) => {
        onLanguageChange(selectedOption);
    };

    return (
        <div className="d-flex flex-row align-content-center align-items-center justify-content-between">
            <div className="d-flex flex-row align-content-center align-items-center ps-1">
                <img src={LANG_IC} alt="DL_IC" className="lang-ic" />
                <p className="lang-text ps-1">Language</p>
            </div>
            <AsyncSelect
                styles={customStyles}
                defaultOptions={options}
                defaultValue={{ label: options[0].label, value: options[0].value }}
                options={options}
                isSearchable={false}
                isClearable={false}
                placeholder=""
                onChange={handleChange}
            />
        </div>
    );
};

export default LanguageSelector;
