import React, { useState, useRef } from "react";

const Tooltip = (props) => {
    const [hover, setHover] = useState(false);
    const hoverTimeout = useRef(null);

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setHover(true);
        }, 300);
    };

    const handleMouseLeave = () => {
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
            hoverTimeout.current = null;
        }
        setHover(false);
    };

    return (
        <div className="cursor-pointer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative", display: "inline-block", cursor: "pointer", }}>
            {hover && (
                <div
                    style={{

                        cursor: "pointer",
                        position: "absolute",
                        top: "-35px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "white",
                        color: "#727272",
                        padding: "8px 5px",
                        borderRadius: "4px",
                        borderRadius: "6px",
                        border: '2px solid #D6D6D6',
                        zIndex: 1,
                        whiteSpace: "nowrap",
                        fontSize: "9px",
                        opacity: 1.0,
                        fontSize: "11px",

                    }}>
                    <div className="p-0 m-0" style={{ textTransform: "none", }}>
                        {props.content}
                    </div>
                </div>
            )}

            {props.children}
        </div>
    );
};

export default Tooltip;
