import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { fetchCreditDetailStage, fetchCreditReportSubmitAnswer, initQuestions, submitArrayAnswer } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import '../createanaccount/createanaccount.css';
import InputMask from 'react-input-mask';

const Logo = require("../../../images/assets/Logo.png");

function CreditVerifyQA() {
    const [ssn, setSSN] = useState('');
    const [dob, setDOB] = useState('');
    const [requestID, setRequestID] = useState('');
    const [sessionId, setSessionId] = useState('');

    const [questionArray, setQuestionArray] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});

    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [fillupdetails, setFillupdetails] = useState();
    const navigate = useNavigate();
    const dateInputRef = useRef(null);
    const { user } = useSelector(state => {
        return state.authReducer;
    });


    useEffect(() => {
        initQuestionsList()
    }, [])

    useEffect(() => {
        const dateInput = dateInputRef.current;
        if (dateInput) {
            dateInput.addEventListener('click', function () {
                this.showPicker();
            });
        }
        // Cleanup the event listener
        return () => {
            if (dateInput) {
                dateInput.removeEventListener('click', function () {
                    this.showPicker();
                });
            }
        };
    }, [navigate]);

    const handleAnswerChange = (questionId, answerId) => {
        setUserExists('');
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answerId
        }));
    };

    const initQuestionsList = () => {
        setLoader(true)
        initQuestions().then(resp => {
            setLoader(false);
            if (resp != null) {
                setRequestID(resp.requestId);
                setSessionId(resp.sessionId);

                if (resp.questions) {
                    setQuestionArray(resp.questions)
                }
            }
        }).catch(error => {
            setLoader(false);
            setUserExists(error?.response.data.errorMessage)

        })
    }

    const save = () => {


        setUserExists('');
        setFillupdetails('');
        setShowError(false);
        if (Object.keys(selectedAnswers).length != questionArray.length) {
            setShowError(true)
            setUserExists('Please attempt to all answer');
        }
        else {
            const requestBody = {
                requestId: requestID,
                answers: selectedAnswers
            };
            //  console.log("Request Body:", requestBody);
            setLoader(true)
            submitArrayAnswer(requestBody, sessionId).then(resp => {

                setLoader(false);
                if (resp.statusCode === 'SUCCESS') {
                    navigate('/dashboard')
                } else {
                    setUserExists(resp.statusMessage)
                }

            }).catch(error => {
                setLoader(false);
                setUserExists(error?.response.data.errorMessage)

            })
        }
    }


    const btnEnableDisable = {
        opacity: Object.keys(selectedAnswers).length == questionArray.length ? 1.0 : 0.5,
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand logo-images-boxes' href='#'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-2'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-10 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Confirm your identity
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        Please help us confirm your identity by providing answers to some questions below.
                                    </p>
                                    <div className='form-input-box mt-md-2 '>
                                        <form className='form-qa-parent'>
                                            {questionArray && questionArray.length > 0 ? (
                                                questionArray.map((item, index) => (
                                                    <div className='form-inner-box' key={item.id}>
                                                        <p className='form-qa p-0 m-0'>{index + 1}. {item.text}</p>
                                                        <div className='pt-2'>
                                                            <ul className='form-qa-options'>
                                                                {item.answers && item.answers.length > 0 ? (
                                                                    item.answers.map((option) => (
                                                                        <li className='p-0 m-0 pt-1' key={option.id}>
                                                                            <div className='d-flex flex-row'>
                                                                                <input className='p-0 m-0' type="radio" id={`${item.id}-${option.id}`} name={`question${item.id}`} value={option.text}
                                                                                    onChange={() => handleAnswerChange(item.id, option.id)} />
                                                                                <label className='p-0 m-0 ps-2' htmlFor={`${item.id}-${option.id}`}>{option.text}</label>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <li>No options available</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <></>
                                            )}

                                        </form>

                                        {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                        <div className='d-flex flex-inline justify-content-lg-end
                                            justify-content-md-end justify-content-center pt-4'>
                                            <div className='col-sm-12 col-md-6 order-1 order-sm-2'>
                                                <div className='text-center text-sm-center text-md-end'>
                                                    <button type='button' className='next-button'
                                                        style={btnEnableDisable}
                                                        onClick={() => {
                                                            if (questionArray && questionArray.length > 0) {
                                                                save()
                                                            } else {
                                                                navigate('/dashboard')
                                                            }

                                                        }} >
                                                        {questionArray && questionArray.length > 0 ?
                                                            'Next' : 'Skip'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>
            <div className='footer-box'>
                <Footer isChatIconShow={false} />
            </div>
        </>
    );
}

export default CreditVerifyQA;