import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { boostScoreReport, refreshToken } from '../../../redux/actions/dashboard_action';
import { emailVerification, emailParseData } from '../../../redux/actions/auth_action';
import { useSelector } from 'react-redux';
import Loader from '../../loader/loader';
import '../boost-score-bank/boost-score-bank.css'
import './add-bank-account.css'
import '../../../App.css'
import BankStatmentForm from '../bank-statement/bank-statement'
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-js-dialog-box/dist/index.css'

const FiuError = require("../../../images/assets/fiu_error.png");

const Fiuerrorscreen = (props) => {
  const [isFiuOpenDialog, setIsFiuOpenDialog] = useState(false);
  const [fiuerrorscreen, setFiuErrorScreen] = useState(true);
  const [dialogTitle, setDialogTitle] = useState('Upload Bank Statement')
  const [dialogHeight, setDialogHeight] = useState('auto');
  const [fipTspDialog, setFipTspModel] = useState(false);
  const [FipTSPData, setFipTSPData] = useState(false);
  const [getUrl, setUrl] = useState();
  const [showModal, setShowModal] = useState(false);
  const [score, setScore] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fiuRequestId, setFiuRequestId] = useState();
  const [getRefreshTokenStatus, setRefreshTokenData] = useState();
  const { state } = useLocation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState();

  const [boostScoreReportData, setBoostScoreReportData] = useState();
  const [analysisReportData, setAnalysisReportData] = useState();
  const [incomeVsExpenseData, setIncomeVsExpenseData] = useState();
  const [topSpendingCategoryData, setTopSpendingCategoryData] = useState();
  const [topMerchantData, setTopMerchantData] = useState();
  const [topCardUtilizationData, setTopCardUtilizationData] = useState();
  const [topMonthlyObligationData, setMonthlyObligationData] = useState();
  const [selectedAnalysisData, setSelectedAnalysisData] = useState();
  const [insightsDialog, setInsightsDialog] = useState(false);
  const [investmentData, setInvestmentData] = useState();


  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.access_token) {
      navigate('/');
    } else {
      getRefreshTokenData();
    }
  }, [])

  const getRefreshTokenData = () => {
    refreshToken().then(resp => {
      setRefreshTokenData(resp);
    }).catch(error => {
      setRefreshTokenData(error);
    })
  }

  const { user } = useSelector(state => {
    return state.authReducer;
  });

  const submit = async () => {
    var param = {
      "email": email,
      "isBankStatement": true,
      "isCreditReport": false,
      "isBankStatementAsync": false
    }
    setLoader(true);
    emailVerification(param).then(resp => {
      setLoader(false);
      if (resp.statusCode === "SUCCESS") {
        window.location = resp.url;
      }
    }).catch(error => {
      setLoader(false);
      if (error.statusCode === "ERROR") {
        setMessage({ error: error.statusMessage });
        navigate('/boostscore', { state: { "error": error.statusMessage } });
      }
    })
  }


  const getBoostScoreReport = () => {
    boostScoreReport().then((resp) => {
      setBoostScoreReportData(resp);
      setAnalysisReportData(resp.analysis)
      setIncomeVsExpenseData(resp.analysis.incomeVsExpenseAnalysis);
      setTopSpendingCategoryData(resp.analysis.categoryAnalysis);
      setTopMerchantData(resp.analysis.merchantAnalysis);
      setTopCardUtilizationData(resp.analysis.creditCardUtilization);
      setMonthlyObligationData(resp.analysis.obligation);
      setInvestmentData(resp.analysis.investment);
      if (resp.score > 0) {
        setScore(resp.score)
      }
      window.dispatchEvent(new Event('resize'))
    }).catch((error) => {

    })
  }

  const refreshTokenBtn = () => {
    setLoader(true);
    var param = {
      "isBankStatement": true
    }
    setMessage("")
    emailParseData(param).then(resp => {
      setLoader(false);
      if (resp.statusCode && resp.statusCode === "SUCCESS") {
        setMessage({ success: resp.statusMessage });
        props.setFipTspModel(false);
        props.setIsOpenDialog(false);
        setFiuErrorScreen(false);
        props.scrollToTop()
        navigate('/boostscore', { state: { "success": resp.statusMessage } });
        getBoostScoreReport();
      } else {
        setMessage({ error: resp.statusMessage });
        props.setFipTspModel(false);
        props.setIsOpenDialog(false);
        setFiuErrorScreen(false);
        props.scrollToTop()
        navigate('/boostscore', { state: { "error": resp.statusMessage } });
      }
    }).catch(error => {
      setLoader(false);
      if (error.statusCode === "ERROR") {
        setMessage({ error: error.statusMessage });
        props.setFipTspModel(false);
        props.setIsOpenDialog(false);
        setFiuErrorScreen(false);
        props.scrollToTop()
        navigate('/boostscore', { state: { "error": error.statusMessage } });
      }
    })
  }

  const openDialog = () => {
    props.setFipTspModel(false);
    setIsFiuOpenDialog(true)
    setFiuErrorScreen(false);
  }

  const closeDialog = () => {
    setIsFiuOpenDialog(false)
    setDialogTitle('Upload Bank Statement')
    setDialogHeight('auto')
    props.scrollToTop()
  }

  const closePopup = () => {
    props.setFiuErrorModel(false);
    props.setIsOpenDialog(false);
    props.setFipTspModel(false);
    setIsFiuOpenDialog(false)
  }

  const tryAgain = () => {
    props.setFipTspModel(true);
    props.setIsOpenDialog(true);
    props.addBankAccount();
    setFiuErrorScreen(false);
  }

  return (
    <>

      {loader && <Loader />}
      {fiuerrorscreen && (
        <span id="close-icon" className='bi bi-x' onClick={() => {
          closePopup()
        }}></span>)}

      <div className='mb-4 fiu-box'>
        <div className='container-lg container-fluid my-md-4 fiu-response'>
          {fiuerrorscreen && (
            <div className='row'>
              <div className='col-12 text-center my-3'>
                <img src={FiuError} alt='fiu_error' className='fiu_error_success_img' />
                <div className="fiu_error_text pt-2">Something went wrong</div>
              </div>

              <div className='col-12 text-center my-3'>
                <div className='no_ac_box p-3'>
                  <div className='no_ac_text'>No account found</div>
                  <span className='no_ac_sub_txt'>We could not find any accounts on the selected bank. Please try again.</span>
                </div>
              </div>

              {/* <div className='col-12 text-center mt-5'>
                                <button className='mb-0 plain-btn' onClick={() => { tryAgain() }}>Try Again</button>
                            </div> */}

              {/* <div className='col-12 text-center my-3'>
                                <button className='mb-0 upload-statement-btn' onClick={() => { openDialog() }}>Upload Bank Statement</button>
                            </div>
                            <div className='col-12 text-center'>
                                    <span className='or-txt'>or</span>
                            </div>
                            <div className='col-12 text-center pt-2'>
                            {getRefreshTokenStatus && getRefreshTokenStatus.statusCode && getRefreshTokenStatus.statusCode === "INVALID" && 
                                <div className='score-buttton-box box-score-credit-box'>
                                    <div className="abcRioButton abcRioButtonBlue gl-btn" onClick={() => { submit() }}>
                                        <div className="abcRioButtonContentWrapper">
                                            <div className="abcRioButtonIcon gl-padding">
                                                <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18 gl-svg-btn">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                                                    </path>
                                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                                    </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <span className="abcRioButtonContents gl-btn-text">
                                                <span id="gl-btn">Login with Google</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>}

                            {getRefreshTokenStatus && getRefreshTokenStatus.statusCode && getRefreshTokenStatus.statusCode === "SUCCESS" && 
                            <button type="button" className="refresh-box-btn refresh-score-btn"  onClick={() => { refreshTokenBtn() }}>
                                    <span className="bi bi-arrow-repeat"></span>
                                    <span className="msg-btn-text">Refresh Score</span>
                            </button>
                          }

                         </div> */}
            </div>)}

          {isFiuOpenDialog && (
            <BankStatmentForm
              closeDialog={closeDialog}
              setScore={props.setScore}
              setDialogTitle={setDialogTitle}
              setDialogHeight={setDialogHeight}
              setMessage={props.setMessage}
              setIsOpenDialog={props.setIsOpenDialog}
              setFiuErrorModel={props.setFiuErrorModel}
              setFipTspModel={props.setFipTspModel}
              addBankAccount={props.addBankAccount}
              setIsFiuOpenDialog={setIsFiuOpenDialog}
              setFiuErrorScreen={setFiuErrorScreen}
              scrollToTop={props.scrollToTop}
              setAnalysisReportData={props.setAnalysisReportData}
              setIncomeVsExpenseData={props.setIncomeVsExpenseData}
              setTopSpendingCategoryData={props.setTopSpendingCategoryData}
              setTopMerchantData={props.setTopMerchantData}
              setTopCardUtilizationData={props.setTopCardUtilizationData}
              setMonthlyObligationData={props.setMonthlyObligationData}
              setInvestmentData={props.setInvestmentData}
            />)}
        </div>
      </div>
    </>
  )
}

export default Fiuerrorscreen;