import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MiddleContentCD from '../Component/middle-cd';
import getUserData from '../../../Common/helper';
import '../boost-ai.css'
import { Fragment, } from 'react';
import './chat-selection-dialog.css';
const BMLOGO = require("../../../images/assets/ai-profile.png");
const AILOGOGIF = require("../../../images/boost-ai.gif");
const BM_SMALL_IC = require("../../../images/bm-small-ic.png");
const QB_SMALL_IC = require("../../../images/qb-small-ic.png");
const INFO_IC = require("../../../images/assets/info.png");
const CHAT_IC = require("../../../images/assets/msg_ic.png");
const PRIVACY_IC = require("../../../images/assets/privacy.png");

function AIInfo(props) {

    const navigate = useNavigate();
    const [userData, setUserData] = useState();

    const [showChatAIBox, setShowChatAIBox] = useState(false)
    const [dialogHeight, setDialogHeight] = useState('auto');
    const [dialogTitle, setDialogTitle] = useState('BOOST AI')
    const { user } = useSelector(state => {
        return state.authReducer;
    });
    const [showModalPayload, setShowModalPayload] = useState({
        showModel: false,
        msgErrorSuccess: ''
    });


    function ChatBotSelection({ onQBClick, onBMClick }) {
        return (
            <div className="modal-overlay" >
                <div className="modal-content-experian bg-white" onClick={e => e.stopPropagation()}>

                    <section className='mt-3 '>
                        <h4 className='p-0 m-0 text-center'>Select your source</h4>
                        <p className='p-0 m-0 text-center pt-3'>Which source of information do you want to proceed with?</p>
                        <div className='d-flex text-center pt-4 justify-content-center align-items-center'>
                            <div className='border-img' onClick={onQBClick}>
                                <img src={QB_SMALL_IC} className='img-small-ic' />
                            </div>
                            <div className='px-3'></div>
                            <div className='border-img' onClick={onBMClick}>
                                <img src={BM_SMALL_IC} className='img-small-ic' />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }


    useEffect(() => {
        if (!user?.access_token) {
            navigate('/')
        }
        const data = getUserData()
        setUserData(data)
    }, [])

    const handleOpenModal = () => {
        setShowModalPayload({
            showModel: true,
            msgErrorSuccess: ''
        });

    }


    const handleCloseModal = (clickEvent) => {
        setShowModalPayload({
            showModel: false,
            msgErrorSuccess: ''
        });
        localStorage.removeItem('messagesAI');
        props.setShowChatAIInfoBox(true)
        props.setShowChatIcon(false)
        props.setShowAIInfoBox(false)
        props.setShowGenAI(true)
        if (clickEvent === "QB-Click") {
            props.setChatUrl('https://genai-qb-uat.boost.money/api/v1/bot/chat')
        } else {
            props.setChatUrl('https://genai-us-uat.boost.money/api/v1/bot/chat')
        }

    }
    const openChatGenAI = () => {
        const value = localStorage.getItem('companyName');
        console.log(value)
        const relalmID = localStorage.getItem('realmId');
        if (value != 'undefined' && value != null && relalmID != undefined && relalmID != null) {
            handleOpenModal()
        } else {
            props.setChatUrl('https://genai-us-uat.boost.money/api/v1/bot/chat')
            localStorage.removeItem('messagesAI');
            props.setShowChatAIInfoBox(true)
            props.setShowChatIcon(false)
            props.setShowAIInfoBox(false)
            props.setShowGenAI(true)
        }
        // setDialogTitle('BOOST AI')
        // setDialogHeight('auto')
    }

    return (
        <>
            <div className='container mt-2'>
                <section className="middle-content mb-10">
                    <div className="profile-main text-center pt-1">
                        <img src={AILOGOGIF} className='chat-ai-logo' />
                        <h5 className='chat-profile-name mt-3'>Hi {userData?.given_name}!</h5>
                        <p className="chat-sub-line">Welcome to Boost.Money</p>
                    </div>

                    <div className="container bg-content-center mt-2">
                        <p className="tips-txt pt-3">Tips for getting started</p>
                        <div className='hr-white-line'></div>
                        <div className="col-12 d-flex flex-wrap pt-4 ps-4 pe-4 pb-3 justify-content-between">
                            <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                                <div className="img-txt">
                                    <img src={CHAT_IC} alt="sub-icon" className="sub-icon" />
                                    <p className="sub-title">Ask Boost AI</p>
                                </div>
                                <p className="sub-desc pt-2 ps-1">BOOST AI can answer your questions related to your financial data like Bank transactions, Tax  and Credit Report.</p>
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-12 pt-3'>
                                <div className="img-txt">
                                    <img src={PRIVACY_IC} alt="sub-icon" className="sub-icon" />
                                    <p className="sub-title">Sensitive data</p>
                                </div>
                                <p className="sub-desc pt-2 ps-1">Your conversations are processed by human reviewers to improve Boost AI. Don’t enter anything you wouldn’t want reviewed or used.</p>
                            </div>

                            <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                                <div className="img-txt">
                                    <img src={INFO_IC} alt="sub-icon" className="sub-icon" />
                                    <p className="sub-title">Check your facts</p>
                                </div>
                                <p className="sub-desc pt-2 ps-1">We have safeguards, but sometimes Boost AI can make mistakes.</p>
                            </div>

                        </div>
                        <div className="py-4">
                            <button onClick={() => { openChatGenAI() }} id="callApiOpenSdk" className="upload-statement-btn wd-100">Let’s get started</button>
                        </div>
                    </div>


                    {showModalPayload.showModel &&
                        <ChatBotSelection onQBClick={() => {
                            handleCloseModal("QB-Click")
                        }}
                            onBMClick={() => {
                                handleCloseModal("BM-Click")
                            }}
                        />
                    }
                    {/* <MiddleContentCD setShowChatAIInfoBox={props.setShowChatAIInfoBox} setShowChatIcon={props.setShowChatIcon} closeInfoDialog={props.closeInfoDialog}> </MiddleContentCD> */}
                </section >
            </div>
        </>
    );
}

export default AIInfo;

