// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidabar-inner-box {
    margin-left: 20%;
}

.sidebat-inner-box {
    width: 100% !important;
}

.sidnav-inner-box {
    overflow-y: scroll !important;
}

.sidnav-inner-box::-webkit-scrollbar {
    width: 0em;
}`, "",{"version":3,"sources":["webpack://./src/pages/boost-score/boost-score.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,UAAU;AACd","sourcesContent":[".sidabar-inner-box {\n    margin-left: 20%;\n}\n\n.sidebat-inner-box {\n    width: 100% !important;\n}\n\n.sidnav-inner-box {\n    overflow-y: scroll !important;\n}\n\n.sidnav-inner-box::-webkit-scrollbar {\n    width: 0em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
