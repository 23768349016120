import { ToastContainer } from 'react-toastify';
import '../../App.css';
import './dashboard.css';
import ScoreCards from './Header-Page/Scroe-card';
import MenuBar from './Menubar/menubar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Sidebar from './SideBar/sidebar';
import Footer from '../landing-page/Footer/Footer';
import { useSelector } from 'react-redux';
import { Link, useNavigate, } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function DashBoard() {
    const navigate = useNavigate();
    const scrollToTop = () => {
        var d = document.getElementById("scoreDetails");
        d.scrollTo({ top: 0, behavior: 'smooth' })
    };

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/login');
        }
    }, []);
    return (
        <>
            {user?.access_token && <MenuBar />}
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    <ScoreCards scrollToTop={scrollToTop} />
                    <ToastContainer />
                </div>
            </div>
        </>
    );
}

export default DashBoard;