// Import your bank logos here
const DefaultBankLogo = require("../../../../images/Bank/Bank.png");
const BankAmericaLogo = require("../../../../images/Bank/bank_america.png");
const ChaseBankLogo = require("../../../../images/Bank/chase_bank.png");
const USBankLogo = require("../../../../images/Bank/us-bank.png");
const AmericanExpLogo = require("../../../../images/Bank/american_express.png");
const WellsFargoLogo = require("../../../../images/Bank/wells_fargo.png");
const CitiBankLogo = require("../../../../images/Bank/citi.png");
const CitiZenBankLogo = require("../../../../images/Bank/citizen_bank.png");
const NavyFedLogo = require("../../../../images/Bank/navy_federal_bank.png");
const USAALogo = require("../../../../images/Bank/usaa_bank.png");
const RegionsBankLogo = require("../../../../images/Bank/regions_bank.png");
const TDBankLogo = require("../../../../images/Bank/td_bank.png");
const MerrillEdgeBankLogo = require("../../../../images/Bank/merrill_edge_bank.png");
const CharlesBankLogo = require("../../../../images/Bank/us-bank.png");
const HuntingtonBankLogo = require("../../../../images/Bank/huntington_bank.png");
const PNCBankLogo = require("../../../../images/Bank/pnc_bank.png");
const BetterMentLogo = require("../../../../images/Bank/betterment_bank.png");
const CapitalOneLogo = require("../../../../images/Bank/capital_one.png");

const GetBankLogo = (bankName) => {


    switch (bankName) {
        case 'Bank of America':
            return BankAmericaLogo;
        case 'Chase' || 'Chase Bank':
            return ChaseBankLogo;
        case 'U.S. Bank':
            return USBankLogo;
        case 'American Express':
            return AmericanExpLogo;
        case 'Wells Fargo':
            return WellsFargoLogo;
        case 'USAA' || 'USAA BANK':
            return USAALogo;
        case 'Citibank Online':
            return CitiBankLogo;
        case 'Citizens Bank':
            return CitiZenBankLogo;
        case 'Navy Federal Credit Union':
            return NavyFedLogo;
        case 'Regions Bank':
            return RegionsBankLogo;
        case 'TD Bank':
            return TDBankLogo;
        case 'Merrill Edge Bank':
            return MerrillEdgeBankLogo;
        case 'Charles Schwab':
            return CharlesBankLogo;
        case 'Huntington Bank':
            return HuntingtonBankLogo;
        case 'PNC Bank':
            return PNCBankLogo;
        case 'Betterment Bank':
            return BetterMentLogo;
        case 'Capital One':
            return CapitalOneLogo;
        default:
            return DefaultBankLogo;
    }
};

export default GetBankLogo;
