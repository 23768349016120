import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { fetchCreditDetailStage } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import '../createanaccount/createanaccount.css';
import InputMask from 'react-input-mask';

const Logo = require("../../../images/assets/Logo.png");

function AddressPage() {
    const [streetName, setStreetName] = useState('');
    const [cityName, setCityName] = useState('');
    const [stateName, setStateName] = useState('');
    const [pinCodeNumber, setPinCodeNumber] = useState('');
    const [isAcceptTerm, setIsAccepTerm] = useState(false)
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorPincode, setShowPincodeError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [fillupdetails, setFillupdetails] = useState();
    const navigate = useNavigate();

    const { user } = useSelector(state => {
        return state.authReducer;
    });



    const validateZipcode = (input) => {
        const cleanedInput = input.replace(/[^0-9]/g, '');

        // Format the ZIP code based on length
        let formattedZipCode = '';
        if (cleanedInput.length <= 5) {
            formattedZipCode = cleanedInput; // 12345
        } else if (cleanedInput.length <= 9) {
            formattedZipCode = cleanedInput.slice(0, 5) + '-' + cleanedInput.slice(5); // 12345-6789
        } else {
            formattedZipCode = cleanedInput.slice(0, 5) + '-' + cleanedInput.slice(5, 9); // Truncate to 12345-6789
        }
        // Set the formatted ZIP code
        setPinCodeNumber(formattedZipCode);
        if (formattedZipCode.length == 5 || formattedZipCode.length == 10) {
            setShowPincodeError(false)
        } else {
            setShowPincodeError(true)
        }
    }



    const save = () => {
        setUserExists('');
        setFillupdetails('');
        setShowError(false);
        setShowPincodeError(false)
        if (streetName === '' || cityName === '' || pinCodeNumber === '' || stateName === '') {
            setShowError(true)
        }
        else if (pinCodeNumber.length !== 5 && pinCodeNumber.length !== 10) {
            setShowPincodeError(true)
        } else {
            const param = {
                "street": streetName,
                "city": cityName,
                "zip": pinCodeNumber,
                "state": stateName
            };
            setLoader(true)
            fetchCreditDetailStage(param, 'stage2').then(resp => {
                setLoader(false);
                if (resp.statusCode === 'SUCCESS') {
                    navigate('/credit-verify-qa')
                } else {
                    setUserExists(resp.statusMessage)
                }

            }).catch(error => {
                setLoader(false);
                setUserExists(error?.response.data.errorMessage)

            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            save()
        }
    };

    const btnEnableDisable = {
        opacity: streetName == ''
            || cityName == '' || pinCodeNumber === '' || stateName === '' ? 0.5 :
            ((pinCodeNumber.length === 5 || pinCodeNumber.length === 10) ? 1.0 : 0.5),
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-2'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-8 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Give us your Address
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        Your current residential address
                                    </p>
                                    <div className='form-input-box mt-md-3'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>Street </label>
                                                </div>
                                                <div>
                                                    <input type="text" name='fname' id='fname' className='input-box form-control' value={streetName} onChange={(e) => { setStreetName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && streetName === '') && <span className="demo-tetx py-0 my-0">Please enter your Street name</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>City </label>
                                                </div>
                                                <div>
                                                    <input type="text" id='lname' className='input-box form-control' value={cityName} onChange={(e) => { setCityName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && cityName === '') && <span className="demo-tetx">Please enter your City name</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>State </label>
                                                </div>
                                                <div>
                                                    <input type="stateName" id='stateName' className='input-box form-control' value={stateName} onChange={(e) => { setStateName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && stateName === '') && <span className="demo-tetx">Please enter State name</span>}
                                                </div>



                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>Zip Code </label>
                                                </div>
                                                <div>
                                                    <InputMask
                                                        type="tel"
                                                        maxLength={10}
                                                        maskChar=''
                                                        className='input-box form-control'
                                                        value={pinCodeNumber}
                                                        onChange={(e) => {
                                                            validateZipcode(e.target.value)
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                        autoComplete='off'
                                                        placeholder='eg.12345 or 12345-6789'
                                                    ></InputMask>

                                                    {/* <input type="tel" className='input-box form-control' value={pinCodeNumber}
                                                        onKeyDown={(e) => {
                                                            setPinCodeNumber(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onChange={(e) => {
                                                            setPinCodeNumber(e.target.value)
                                                        }} minLength="10" placeholder='555-555-5555' maxLength="10" autoComplete='off' /> */}
                                                    {(showError && pinCodeNumber === '') && <span className="demo-tetx">Please enter your Zipcode</span>}
                                                    {(showErrorPincode && ((pinCodeNumber.length !== 5 || pinCodeNumber.length !== 10) && pinCodeNumber !== '')) && <span className="demo-tetx">Please enter valid Zipcode</span>}
                                                    {userExists !== '' && <span className="demo-tetx mt-1">{userExists}</span>}
                                                </div>
                                            </div>

                                            <div className='form-inner-box'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-sm-12 col-md-6 order-2 order-sm-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>

                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6 order-1 order-sm-2'>
                                                        <div className='text-center text-sm-center text-md-end'>
                                                            <button type='button' className='next-button'
                                                                style={btnEnableDisable}
                                                                onClick={() => {
                                                                    save()
                                                                }} >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>

            <div className='footer-box'>
                <Footer isChatIconShow={false} />
            </div>
        </>
    );
}

export default AddressPage;