const getControlWidth = () => {
    const pageWidth = window.innerWidth;
    return pageWidth > 576 ? '400px' : '100%';
};



export const customStyles = {

    control: (base, state) => ({
        ...base,
        height: '64px', // Set the height of the dropdown
        width: getControlWidth(), // Set the width of the dropdown
        border: '1.5px solid #4243f1', // Add a border
        borderRadius: '12px',
        padding: '6px 12px',
        fontSize: '18px',
        cursor: 'pointer',
        boxShadow: '0 2px 12px rgba(0, 0, 0, .1)',
        fontFamily: 'Poppins',
        fontWeight: '400'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        width: '340px',
        padding: '0px 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
    menu: (provided) => ({
        ...provided,
        width: getControlWidth(),
        borderRadius: '12px',
        padding: '0px 0px',
        cursor: 'pointer',

    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: '0px',
        borderRadius: '12px',

    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'black',
        backgroundColor: state.isSelected ? '#EDEBFD' : 'white',
        borderBottom: state.isSelected ? 'none' : '1px solid #D1D1D1',
        padding: '16px 16px',
        cursor: 'pointer',
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontFamily: 'Poppins',
            fontWeight: '400',
            color: '#1c1c1c', // Set the color of the placeholder text
            fontSize: '1em', // Adjust the font size as needed
            // Add other styles here
        };
    },

    // ... other styles for other components like menu, option, etc.
};