import React, { useState, useEffect } from 'react'
import '../boost-score-bank/boost-score-bank.css'
import './add-bank-account.css'
import '../../../App.css'
import Fiuerrorscreen from '../add-bank-account/fiu-error-screen'
import Fiusuccessscreen from '../add-bank-account/fiu-success-screen'
import Loader from '../../loader/loader';
import { getFipTSPUrl, getFiuStatus, boostScoreReport, initiateAccount, tokenExchange,plaidStatus } from '../../../redux/actions/dashboard_action'
import { ReactDialogBox } from 'react-js-dialog-box'
import { PlaidLink } from "react-plaid-link"
import config from '../../../config.json'
import 'react-js-dialog-box/dist/index.css'
import OpenPlaidLink from './open-plaid-link'
import { uuid } from '../../../utils/uuid'

const AddBank = require("../../../images/assets/AddBank.png");
const GetInsights = require("../../../images/assets/Getinsights.png");
const Finances = require("../../../images/assets/Finances.png");

const Addbankaccount = (props) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Upload Bank Statement')
    const [dialogHeight, setDialogHeight] = useState('auto');
    const [fipTspDialog, setFipTspModel] = useState(false);
    const [fiuerrorModel, setFiuErrorModel] = useState(false);
    const [fiusuccessModel, setFiuSuccessModel] = useState(false);
    const [fipTSPData, setFipTSPData] = useState(false);
    const [getUrl, setUrl] = useState();
    const [score, setScore] = useState(0);
    const [loader, setLoader] = useState(false);
    const [fiuRequestId, setFiuRequestId] = useState();
    const [tspLoader, setTspLoader] = useState(false);
    const [plaidLinkTokenData, setPlainLink] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [userIdData, setUserId] = useState();
    const [requestIdData, setRequestId] = useState();
    let fipRequestCheck;
    let plaidStatusCheck;

    // useEffect(() => {
    //     window.addEventListener('message', fetchIframeData, false);
    //     function fetchIframeData(e){
    //         try{
    //             const iframeData = JSON.parse(e.data);
    //             const status = iframeData.status;
    //             const responseFrom = iframeData.responseFrom;
    //             const fiuRequestId = iframeData.fiuRequestId;
    //             if((status === 'SUCCESS' || status === 'success') && responseFrom === "FIUTSP"){
    //                 setTspLoader(true);
    //                 fipRequestCheck = setInterval(() => {
    //                     fetchFIURequestStatus(fiuRequestId);
    //                 }, 6000);
    //             }else if((status === 'ERROR' || status === 'error') && responseFrom === "FIUTSP"){
    //                 setFiuErrorModel(true);
    //                 setIsOpenDialog(true)
    //                 setFipTspModel(false);
    //                 setFiuSuccessModel(false); 
    //                 setTspLoader(false);
    //             }
    //         }catch(e){
    //             setTspLoader(false);
    //             console.log(e);
    //         }
    //     }
    // }, []);

    // const fetchFIURequestStatus = (fiuRequestId) => {
    //     if(fiuRequestId && fiuRequestId !=""){
    //              var param = {
    //                  "fiuRequestId" : fiuRequestId
    //              }
    //              getFiuStatus(param) .then(resp => {
    //                  if(resp.data && resp.data.statusCode === "SUCCESS"){
    //                      if(resp.data.status === "SCORE_GENERATED"){
    //                              console.log("Score Generated");
    //                              getBoostScoreReport();
    //                              clearInterval(fipRequestCheck);
    //                              setUrl("");
    //                              setFiuSuccessModel(true); 
    //                              setIsOpenDialog(true); 
    //                              setFipTspModel(false);
    //                              setTspLoader(false);
    //                              props.scrollToTop() 
    //                      }else if(resp.data.status === "CONSENT_REJECTED" || resp.data.status === "SCORE_NOT_GENERATED" || resp.data.status === "TRANSACTION_DATA_NOT_AVAILABLE" || resp.data.status === "FIU_REQUEST_ID_NOT_FOUND"){
    //                         console.log("Consent Rejected");
    //                          setLoader(false);
    //                          clearInterval(fipRequestCheck);
    //                          setUrl("");
    //                          setFiuErrorModel(true);
    //                          setIsOpenDialog(true)
    //                          setFipTspModel(false);
    //                          setFiuSuccessModel(false); 
    //                          setTspLoader(false);
    //                          props.scrollToTop()
    //                      }

    //                  }else{
    //                     clearInterval(fipRequestCheck);
    //                  }
    //              }).catch(error => {
    //                  clearInterval(fipRequestCheck);
    //                  props.setMessage({ error: error});
    //              })
    //      }
    // }

    const getBoostScoreReport = () => {
        boostScoreReport().then((resp) => {
            let resAccount = resp.accounts[0]
            // console.log(resp);
            setScore(resAccount.score)
            props.setScore(resAccount.score)
            props.setMessage({ success: "Your Score is generated" })
            props.setAnalysisReportData(resAccount?.analysis ? resAccount.analysis : [])
            // props.setNewBankname(resAccount?.analysis.accountDetail.bankName + " Bank | " + resAccount?.analysis.accountDetail.accountType + ' Account - ' + resAccount?.analysis.accountDetail.accountNumber.slice(-4))
            // props.setAcHolderName(resAccount.analysis.accountDetail.accountHolderName)
            // props.setTransactionPeriod(resAccount.analysis.accountDetail.transactionStartDate + ' - ' + resAccount.analysis.accountDetail.transactionEndDate)
            props.setIncomeVsExpenseData(resAccount?.analysis?.incomeVsExpenseAnalysis ? resAccount.analysis.incomeVsExpenseAnalysis : [])
            props.setTopSpendingCategoryData(resAccount?.analysis?.categoryAnalysis ? resAccount.analysis.categoryAnalysis : [])
            props.setTopMerchantData(resAccount?.analysis?.merchantAnalysis ? resAccount.analysis.merchantAnalysis : [])
            props.setTopCardUtilizationData(resAccount.analysis.creditCardUtilization)
            props.setMonthlyObligationData(resAccount.analysis.obligation)
            props.setInvestmentData(resAccount.analysis.investment)
            // props.insightsAnalysisReports(resAccount.analysis)
            // props.setBankIndexCurrent(index)
            props.setMessage({ success: resp.statusMessage })
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    const successResponse = () => {
        setFiuSuccessModel(true);
        setIsOpenDialog(true);
        // getBoostScoreReport();
        props.onChildEvent();
        setFipTspModel(false);
        setTspLoader(false);
        props.scrollToTop();
    }

    const errroResponse = () => {
        setFiuErrorModel(true);
        setIsOpenDialog(true)
        setFipTspModel(false);
        setFiuSuccessModel(false);
        props.scrollToTop();
    }

    const getPlaidLinkData = () => {
        setLoader(true)
        initiateAccount(uuid).then((resp) => {
            setLoader(false)
            var result = resp.data;
            setPlainLink(result.plaidLinkToken);
            setSessionId(result.sessionId);
            // setUserId(result.userId);
            setRequestId(result.requestId);
            //  openPlaidLink(result.plaidLinkToken);
        }).catch((error) => {
            setLoader(false)
            console.log(error);
        })
    }

    const getPlaidStatus = () => {
        setLoader(true)
        const statusData = {
            "plaidRequestId": requestIdData
        }
        plaidStatus(statusData).then((resp) => {
            if (resp.data.plaidStatus === "TRANSACTION_PROCESS_COMPLETED") {
                successResponse()
                clearInterval(plaidStatusCheck)
                setLoader(false)
            }else if (resp.data.plaidStatus === "LINK_TOKEN_GENERATION_FAILED" || 
                    resp.data.plaidStatus === "PUBLIC_TOKEN_EXCHANGE_FAILED" || 
                    resp.data.plaidStatus === "IDENTITY_FETCH_FAIL" || 
                    resp.data.plaidStatus === "TRANSACTION_FETCH_FAIL" || 
                    resp.data.plaidStatus === "ANALYSIS_FAILED") {
                errroResponse()
                clearInterval(plaidStatusCheck)
                setLoader(false)
            }
        }).catch((error) => {
            setLoader(false)
            errroResponse()
            clearInterval(plaidStatusCheck)
        })
    }

    const onSuccess = (token, metadata) => {
        const exchangeData = {
            "publicToken": token,
            "requestId": requestIdData
        }
        setLoader(true)
        tokenExchange(exchangeData,sessionId,uuid).then((resp) => {
                setPlainLink(null);
                setSessionId(null);
            if (resp.data.statusCode === "SUCCESS") {
                plaidStatusCheck = setInterval(() => {
                getPlaidStatus()
            }, 2000)
            } else if (resp.data.statusCode === "ERROR") {
                errroResponse()
                setLoader(false)
            }
        }).catch((error) => {
                setLoader(false)
                setSessionId(null)
                setPlainLink(null)
                errroResponse()
        })
    };

    const onExit = (error, metadata) => {
        setSessionId(null);
        setPlainLink(null);
        // Handle when the user exits the Link flow
        if (error != null) {
            errroResponse();
            console.warn('Link exit:', error, metadata);
        }
    }

    const PlaidLinkButton = () => {

        const onSuccess = (token, metadata) => {
            // console.log('Link success:', token, metadata);
            const exchangeData = {
                "publicToken": token,
                // "userId": userIdData,
                "requestId": requestIdData
            };
            setLoader(true);
            tokenExchange(exchangeData, sessionId).then((resp) => {
                if (resp.data.statusCode === "SUCCESS") {
                    setLoader(false);
                    successResponse();

                } else if (resp.data.statusCode === "ERROR") {
                    errroResponse();
                }
            }).catch((error) => {
                console.log(error);
                errroResponse();
            })
        };

        const onExit = (error, metadata) => {
            // Handle when the user exits the Link flow
            if (error != null) {
                errroResponse();
                console.warn('Link exit:', error, metadata);
            }
        }

        return (

            <PlaidLink
                clientName="Boost Money"
                env={config?.sdkEnv}
                product={["transactions"]}
                token={plaidLinkTokenData}
                onExit={onExit}
                onSuccess={onSuccess}
                className="mb-0 add-bank-btn"
            >Add Bank Account</PlaidLink>
        )
    }


    // const openDialog = () => {
    //      setFiuSuccessModel(false)
    //      setFiuErrorModel(false)
    //      setIsOpenDialog(true)
    //      addBankAccount()
    // }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setFiuSuccessModel(false);
        setDialogTitle('Upload Bank Statement')
        setDialogHeight('auto')
        props.scrollToTop()
    }

    // const FipTspModel = (props) => {
    //     return <div className='tsp-iframe-popup'>
    //         <iframe
    //             title={props.src}
    //             allowFullScreen
    //             frameBorder="0"
    //             height="550px"
    //             src={props.src}
    //             width="400px"
    //             id="tspiframe"
    //         />
    //     </div>
    // }

    const TspLoader = () => {
        return <div className="text-center" id="loading">
            <div className="row justify-content-center mtp-50">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="row text-center justify-content-center">
                    <div className="loader-txt mt-20">Please wait...</div>
                    <div className="loader-txt">Aggregating and Analyzing Transactions</div>
                </div>
            </div>
        </div>
    }

    // const addBankAccount = () => {
    //     setFipTspModel(true);
    //     setLoader(true);
    //     getFipTSPUrl()
    //     .then(resp => {
    //         if(resp.data.statusCode === "SUCCESS"){
    //             setLoader(false)
    //             let fiuUrl = resp.data.url;
    //             let urlQuery = fiuUrl.split('?');
    //             setUrl(resp.data.url);
    //             //setUrl("http://localhost/fiu-tsp-web/?"+urlQuery[1]);
    //             setFiuRequestId(resp.data.fiuRequestId);
    //             setFipTSPData(resp)
    //             }
    //     }).catch(error => {
    //         clearInterval(fipRequestCheck);
    //         console.log(error)
    //     })
    // }


    return (
        <>
            {loader && <Loader />}
            <div className='mb-4 fiu-box'>
                <div className='container-lg container-fluid my-md-4'>
                    <div className='bank-state-box'>
                        <div className='row'>
                            <div className='co-12 text-center '>
                                <h1 className='boosted-score-text'>Get A boosted credit score</h1>
                                <p className='boosted-moneytetx'>Share the bank statement and  get insights and a boosted score</p>
                            </div>
                        </div>

                        <div className='row mt-3 g-2 g-sm-4 text-center'>
                            <div className='col-sm-12 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>01.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={AddBank} className='img-fluid ' />
                                        </div>
                                        <p className='get-score-images-text text-break '>Add your bank account(s)</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>02.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={GetInsights} className='img-fluid ' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Get insights and recommendations</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>03.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={Finances} className='img-fluid ' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Boost your credit score and finances</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 g-3'>

                            {/* {fipTspDialog ?         
                                    <PlaidLink
                                        clientName="React Plaid Setup"
                                        env="sandbox"
                                        product={["transactions"]}
                                        token="link-sandbox-49493970-ad0f-4509-87c1-427e623a65f3"
                                        onExit={onExit}
                                        onSuccess={onSuccess}
                                        className="test"
                                    ></PlaidLink> 
                                    :null
                                } */}


                            <div className='col-12 text-center my-3 fiupopup d-flex justify-content-center'>
                                <div className='mb-0 upload-statement-btn '
                                    onClick={() => { getPlaidLinkData() }}>Add Bank Account</div>

                                {/* <PlaidLinkButton onClick={() => { getPlaidLinkData() }}></PlaidLinkButton> */}
                                {/* <button className='mb-0 upload-statement-btn' onClick={() => { PlaidLinkButtonDemo() }}>Add Bank Account</button> */}
                            </div>

                            {plaidLinkTokenData && <div className='col-12 text-center my-3 fiupopup'>
                                {plaidLinkTokenData &&
                                    <OpenPlaidLink token={plaidLinkTokenData}
                                        clientName={'Boost Money'}
                                        env={config?.sdkEnv}
                                        product={["transactions"]}
                                        onSuccess={onSuccess}
                                        onExit={onExit}>
                                    </OpenPlaidLink>}
                            </div>}

                            {isOpenDialog && (

                                <ReactDialogBox
                                    headerBackgroundColor='white'
                                    headerTextColor='black'
                                    closeButtonColor='black'
                                    bodyBackgroundColor='white'
                                    bodyTextColor='black'
                                    bodyHeight={dialogHeight}
                                    borderRadius='8px'
                                    headerHeight='65px'
                                >
                                    {/* {fipTspDialog ? 
                                     <FipTspModel src={getUrl} /> 
                                     : null
                                 } */}

                                    {tspLoader ?
                                        <TspLoader></TspLoader>
                                        : null
                                    }

                                    {fiuerrorModel ?
                                        <Fiuerrorscreen
                                            closeDialog={closeDialog}
                                            setScore={props.setScore}
                                            setDialogTitle={setDialogTitle}
                                            setDialogHeight={setDialogHeight}
                                            setFipTspModel={setFipTspModel}
                                            setIsOpenDialog={setIsOpenDialog}
                                            setFiuErrorModel={setFiuErrorModel}
                                            newReferenceId={uuid}
                                            // addBankAccount ={addBankAccount}
                                            fipRequestCheck={fipRequestCheck}
                                            setMessage={props.setMessage}
                                            scrollToTop={props.scrollToTop}
                                            setAnalysisReportData={props.setAnalysisReportData}
                                            setIncomeVsExpenseData={props.setIncomeVsExpenseData}
                                            setTopSpendingCategoryData={props.setTopSpendingCategoryData}
                                            setTopMerchantData={props.setTopMerchantData}
                                            setTopCardUtilizationData={props.setTopCardUtilizationData}
                                            setMonthlyObligationData={props.setMonthlyObligationData}
                                            setInvestmentData={props.setInvestmentData}

                                        /> : null}

                                    {fiusuccessModel ?
                                        <Fiusuccessscreen
                                            closeDialog={closeDialog}
                                            setDialogTitle={setDialogTitle}
                                            setDialogHeight={setDialogHeight}
                                            setFiuSuccessModel={setFiuSuccessModel}
                                            setFipTspModel={setFipTspModel}
                                            setIsOpenDialog={setIsOpenDialog}
                                            scrollToTop={props.scrollToTop}
                                        /> : null}
                                </ReactDialogBox>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Addbankaccount;