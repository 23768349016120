// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-statement-butoon {
    background: linear-gradient(145deg, #7D25F6 41.74%, #2553F6 84.59%);
    box-shadow: 0px 10px 30px rgba(206, 206, 206, 0.6);
    border-radius: 12px;
    padding: 10px 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    border: none;
    text-transform: capitalize !important;
}

.poweredby-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    color: #191D23;
}

.boost-score-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(120%);
}

.powerby-img-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    transform: translatey(150%);
}

._br_-q h4 {
    margin-bottom: 0px !important;
}

#boot-icon{
    font-size: 25px;
}

#close-icon{
    font-size: 30px;
    float: right;
}

.upload-title-txt{
    font-size: 18px;
    font-weight: 400;
}

@media (max-width:767px) {
    ._br_-q h4 {
        font-size: 15px !important;
    }

    ._Xt-75 {
        left: 50% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/boost-score/bank-statement/bank-statement.css"],"names":[],"mappings":"AAAA;IACI,mEAAmE;IACnE,kDAAkD;IAClD,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,YAAY;IACZ,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,uBAAuB;IACvB,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,0BAA0B;IAC9B;;IAEA;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".upload-statement-butoon {\n    background: linear-gradient(145deg, #7D25F6 41.74%, #2553F6 84.59%);\n    box-shadow: 0px 10px 30px rgba(206, 206, 206, 0.6);\n    border-radius: 12px;\n    padding: 10px 30px;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 20px;\n    color: #FFFFFF;\n    border: none;\n    text-transform: capitalize !important;\n}\n\n.poweredby-text {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    letter-spacing: -0.01em;\n    text-transform: capitalize;\n    color: #191D23;\n}\n\n.boost-score-box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    transform: translateY(120%);\n}\n\n.powerby-img-box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: end;\n    transform: translatey(150%);\n}\n\n._br_-q h4 {\n    margin-bottom: 0px !important;\n}\n\n#boot-icon{\n    font-size: 25px;\n}\n\n#close-icon{\n    font-size: 30px;\n    float: right;\n}\n\n.upload-title-txt{\n    font-size: 18px;\n    font-weight: 400;\n}\n\n@media (max-width:767px) {\n    ._br_-q h4 {\n        font-size: 15px !important;\n    }\n\n    ._Xt-75 {\n        left: 50% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
