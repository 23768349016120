import React, { useEffect, useRef } from 'react';

const ImageCloseIC = require('../../../images/close_ic_white.png')
const FullScreenModal = ({ isOpen, onClose, children }) => {


    if (!isOpen) return null;
    return (
        <section className='p-0' >
            <div className="fullscreen-modal">
                <div className="modal-content">
                    <img onClick={onClose} src={ImageCloseIC} className="close-button " />
                    {children}
                </div>
            </div>
        </section>
    );
};

export default FullScreenModal;