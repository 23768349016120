// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-score-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(0%) !important;
}

.password-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.password-icon {
    cursor: pointer;
    margin-left: -35px;
    width: 25px;
    margin-right: 5px;
    opacity: 0.6;
}

.needs-validation {
    margin-right: 20px !important;
}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 100px;
    background: orange;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    box-shadow: 0 5px 10px #ccc;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/credit-report/credit-report.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,oBAAoB;IACpB,YAAY;IACZ,2BAA2B;AAC/B","sourcesContent":[".credit-score-box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    transform: translateY(0%) !important;\n}\n\n.password-box {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.password-icon {\n    cursor: pointer;\n    margin-left: -35px;\n    width: 25px;\n    margin-right: 5px;\n    opacity: 0.6;\n}\n\n.needs-validation {\n    margin-right: 20px !important;\n}\n\n.back-to-top {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    font-size: 100px;\n    background: orange;\n    color: white;\n    cursor: pointer;\n    border-radius: 100px;\n    border: none;\n    box-shadow: 0 5px 10px #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
