import React, { useRef } from "react";
import '../../../App.css';
import { authCode } from '../../../redux/actions/auth_action';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const ConsentLogo = require("../../../images/assets/consent_logo.png");

const Consent_Verify = () =>  {
    const [loader, setLoader] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
    setCompleted(5)
    setTimeout(() => {  
        if (window.location.search && window.location.search !== "" && window.location.search.includes("state")) {
            if (!isVerified) {
                setIsVerified(true)
                setCompleted(15)
                var data = window.location.search.split("&");
                var state = data[0].split("=")[1];
                var code = data[1].split("=")[1];
                setCompleted(40)
                var url_state = JSON.parse(decodeURI(state));
                var isBankStatement =url_state.isBankStatement;
                setCompleted(60)  
                var isCreditReport =url_state.isCreditReport;
                var isBankStatementAsync =url_state.isBankStatementAsync;
                setCompleted(85)    
                var param = {
                    "oauthCode": code,
                    "isBankStatement" : isBankStatement,
                    "isCreditReport" : isCreditReport,
                    "isBankStatementAsync" : isBankStatementAsync
                }
                setCompleted(99)    
                authCode(param).then(resp => {
                    console.log(resp);
                    if (resp.statusCode === "ERROR") {
                        navigate('/dashboard', { state: { "error": resp.statusMessage } });
                    } else if(resp.statusCode === "SUCCESS" && isCreditReport) {
                        navigate('/dashboard', { state: { "success": resp.statusMessage } })
                    }else if(resp.statusCode === "SUCCESS" && isBankStatement){
                        navigate('/boostscore', { state: { "success": resp.statusMessage } })     
                    }
                }).catch(error => {
                    if (error.statusCode === "ERROR") {
                        navigate('/dashboard', { state: { "error": error.statusMessage } });
                    }
                })
            }
        }
    }, 2000); 
    
    }, []);

    return (
        <>  
            <div className='container text-center'>
               <div className='consent_area'>
                <img src={ConsentLogo} className='img-fluid pb-4'/>
                <div className="progress">
                        <div className="progress-bar"  role="progressbar" style={{width: completed+"%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p className="consent-text">Sit back. We are preparing your dashboard</p>
                </div>
            </div> 
        </>
    );
}
export default Consent_Verify;