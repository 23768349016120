import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../../../App.css';
import { fetchCreditDetailStage } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import '../createanaccount/createanaccount.css';
import InputMask from 'react-input-mask';
import UseScriptCode from '../ssn-page/use-script';
const Logo = require("../../../images/assets/Logo.png");

function SSNPage() {
    const [ssn, setSSN] = useState('');
    const [dob, setDOB] = useState('');
    const [formattedDOB, setFormattedDOB] = useState('');

    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [fillupdetails, setFillupdetails] = useState();
    const navigate = useNavigate();
    const dateInputRef = useRef(null);
    const { user } = useSelector(state => {
        return state.authReducer;
    });

    // UseScriptCode('https://embed.array.io/cms/array-web-component.js?appKey=3F03D20E-5311-43D8-8A76-E4B5D77793BD');
    // UseScriptCode('https://embed.array.io/cms/array-account-enroll.js?appKey=3F03D20E-5311-43D8-8A76-E4B5D77793BD');

    // useEffect(() => {


    //     window.addEventListener('array-event', function arrayEvent(arrayEvent) {
    //         const {
    //             tagName, // the name of the component that emitted the event
    //             event, // the name of the user's action
    //             metadata = {}, // Component-specific data
    //         } = arrayEvent.detail;
    //         console.log(tagName === 'authentication-kba' && event === 'success');
    //         console.log(tagName === 'array-account-enroll' && event === 'set-user-id');
    //         if (tagName === 'array-account-enroll' && event === 'set-user-id') {
    //             navigate('/dashboard')
    //         }
    //         console.log(metadata)
    //         console.log("component: " + tagName + "; user action: " + event);
    //         UseCustomStyles()
    //     });

    //     UseCustomStyles()
    // }, []);


    function applyCustomStyles() {

        // const shadowRoot = document.querySelector('array-authentication-kba').shadowRoot;
        const shadowRoot = document.querySelector('array-account-enroll').shadowRoot;
        const style = document.createElement('style');
        style.textContent = `
             .arr-container {
                padding: 0px !important;
                margin: 0px !important;
                display: flex !important;
                justify-content: center;
                align-items: center;
                align-content: center !important;
                justify-center: center !important;
            }

            .arr-container.svelte-1v5bwr5.arr-container-legacy-sizing {
                padding: 0px !important;
                margin: 0px !important;
            }

            .arr-container-legacy-sizing.svelte-1v5bwr5 .container.svelte-1v5bwr5 {
                padding: 0px !important;
                margin: 0px !important;
            }

            .container.svelte-1hlk85m {
                padding: 18px 18px !important;
            }
          
            .container.svelte-zgropl {
                padding: 0px !important;
                margin: 0px !important;
            }
            .arr-container.arr-container-legacy-sizing{
               padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            }

              .arr-container .arr-container-legacy-sizing{
               padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            }

            .arr-container-legacy-sizing.svelte-1mx24w9 .container.svelte-1mx24w9 {
                padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            }
            .arr-container .svelte-1mx24w9 .arr-container-legacy-sizing{
                padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            }
            .arr-container-legacy-sizing{
                 padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
             }
            .arr-card.svelte-1mx24w9.svelte-1mx24w9:not(.arr-card-on-banner) {
                padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            }
            .svelte-1mx24w9{
                 padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            }
            .arr-card {
                padding: 0px !important;
                margin: 0px !important;
                background-color:red !important;
            } `;

        console.log("shadowRoot  " + shadowRoot)


        const legacySizeElement = shadowRoot.querySelector('array-authentication-kba');

        if (legacySizeElement) {
            legacySizeElement.appendChild(style);
            console.log('arr-container-legacy-sizing is available in the shadowRoot.');
        } else {
            console.log('arr-container-legacy-sizing is NOT available in the shadowRoot.');
        }
        if (shadowRoot != null) {
            shadowRoot.appendChild(style);
        }
    }

    function UseCustomStyles() {
        const timer = setTimeout(() => {
            applyCustomStyles();
        }, 1000);

        // Cleanup the timeout on unmount
        return () => clearTimeout(timer);
    }

    useEffect(() => {

        const dateInput = dateInputRef.current;
        if (dateInput) {
            dateInput.addEventListener('click', function () {
                this.showPicker();
            });
        }
        // Cleanup the event listener
        return () => {
            if (dateInput) {
                dateInput.removeEventListener('click', function () {
                    this.showPicker();
                });
            }
        };
    }, [navigate]);




    const save = () => {
        setUserExists('');
        setFillupdetails('');
        setShowError(false);
        if (ssn === '' || dob === '' || !validateSSN(ssn)) {
            setShowError(true)
        }
        else {
            const param = {
                "ssn": ssn,
                "dateOfBirth": dob,
            };
            setLoader(true)
            fetchCreditDetailStage(param, 'stage1').then(resp => {
                setLoader(false);
                if (resp.statusCode === 'SUCCESS') {
                    navigate('/address-page')
                } else {
                    setUserExists(resp.statusMessage)
                }

            }).catch(error => {
                setLoader(false);
                console.log(error)
                setUserExists(error?.response.data.errorMessage)

            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            save()
        }
    };
    function convertToSSN(number) {
        // Remove all non-numeric characters
        const cleaned = number.replace(/\D/g, '');
        // Apply the format XXX-XX-XXXX
        const formatted = cleaned.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');

        return formatted;
    }
    function validateSSN(ssn) {
        // Pattern to check if SSN follows the format "AAA-GG-SSSS"
        const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
        return ssn !== '' && ssnPattern.test(ssn);
    }

    const handleDOBChange = (e) => {
        const value = e.target.value;
        setDOB(formatDateForDisplay(value));
        // console.log(formatDateForDisplay(value))
    };

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };

    const btnEnableDisable = {
        opacity: ssn == '' || !validateSSN(ssn)
            || dob == '' ? 0.5 : 1.0,
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-0 mt-md-0'>

                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0  form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-8 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Share your SSN details
                                    </h1>
                                    <p className='fw-semibold mt-md-2'>
                                        As per your government ID proof to help us fetch your Credit Report
                                    </p>


                                    <div className='form-input-box mt-md-4'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>SSN</label>
                                                </div>
                                                <div>
                                                    {/* <input type="text" name='ssn' id='ssn' className='input-box form-control' maxLength={9} value={ssn} onChange={(e) => { setSSN(convertToSSN(e.target.value)) }} onKeyDown={handleKeyDown} />
                                                    */}

                                                <InputMask
                                                        mask="999-99-9999"
                                                        maskChar=''
                                                        className='input-box form-control'
                                                        value={ssn}
                                                        onChange={(e) => {
                                                            setSSN(convertToSSN(e.target.value))
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                        autoComplete='off'
                                                        placeholder='XXX-XX-XXXX'
                                                ></InputMask>

                                            {(showError && ssn === '') && <span className="form-text demo-tetx">Please enter your SSN</span>}
                                            {(ssn !== '' && !validateSSN(ssn)) && <span className="form-text demo-tetx">Please enter your valid SSN</span>}
                                                </div>
                                            </div>


                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>Date of Birth</label>
                                                </div>
                                                <section >
                                                    <input
                                                        ref={dateInputRef}
                                                        type="date" name='dob' id='dob'
                                                        className='input-box form-control input-date'
                                                        pattern="\d{4}/\d{2}/\d{2}"
                                                        placeholder="MM/DD/YYYY"
                                                        onChange={handleDOBChange}
                                                        onKeyDown={handleKeyDown} />

                                                    {(showError && dob === '') && (
                                                        <span className="demo-tetx py-0 my-0">
                                                            Please choose date of birth
                                                        </span>
                                                    )}
                                                    {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                                </section>
                                            </div>

                                            <div className='form-inner-box'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-sm-12 col-md-6 order-2 order-sm-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>

                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6 order-1 order-sm-2'>
                                                        <div className='text-center text-sm-center text-md-end'>
                                                            <button type='button' className='next-button'
                                                                style={btnEnableDisable}
                                                                onClick={() => {
                                                                    save()
                                                                }} >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>



                                </div>


                            </div>

                           


                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer isChatIconShow={false} />
            </div>
        </>
    );
}

export default SSNPage;