import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../App.css';
import { authCode, emailVerification } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import './email.css'
import Footer from '../../landing-page/Footer/Footer'

const Logo = require("../../../images/assets/Logo.png");

function Email() {
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (window.location.search && window.location.search !== "" && window.location.search.includes("state")) {
    //         if (!isVerified) {
    //             setIsVerified(true)
    //             var data = window.location.search.split("&");
    //             var state = data[0].split("=")[1];
    //             var code = data[1].split("=")[1];
    //             var param = {
    //                 "oauthCode": code
    //             }
    //             setLoader(true);
    //             authCode(param).then(resp => {
    //                 setLoader(false);
    //                 if (resp.statusCode === "ERROR") {
    //                     navigate('/dashboard', { state: { "error": resp.statusMessage } });
    //                 } else {
    //                     navigate('/dashboard', { state: { "success": resp.statusMessage } })
    //                 }
    //             }).catch(error => {
    //                 setLoader(false);
    //                 if (error.statusCode === "ERROR") {
    //                     navigate('/dashboard', { state: { "error": error.statusMessage } });
    //                 }
    //             })
    //         }
    //     }
    // }, []);

    const verifyEmail = async () => {
        var param = {
            "email": email,
            "isBankStatement": true,
            "isCreditReport": true,
            "isBankStatementAsync": true
        }
        setLoader(true);
        emailVerification(param).then(resp => {
            setLoader(false);
            if (resp.statusCode === "SUCCESS") {
                window.location = resp.url;
            }
        }).catch(error => {
            setLoader(false);
            if (error.statusCode === "ERROR") {
                navigate('/dashboard', { state: error.statusMessage });
            }
        })

        // setUserExists('')
        // let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // setShowError(false)
        // if (!email || email === '') {
        //     setShowError(true)
        // } else if (!rex.test(email)) {
        //     setUserExists('Please enter valid email address');
        // } else {
        //     var param = {
        //         "email": email,
        //         "isBankStatement": true,
        //         "isCreditReport": true,
        //         "isBankStatementAsync": true
        //     }
        //     setLoader(true);
        //     emailVerification(param).then(resp => {
        //         setLoader(false);
        //         if (resp.statusCode === "SUCCESS") {
        //             window.location = resp.url;
        //         }
        //     }).catch(error => {
        //         setLoader(false);
        //         if (error.statusCode === "ERROR") {
        //             navigate('/dashboard', { state: error.statusMessage });
        //         }
        //     })
        // }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            verifyEmail()
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-image logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-sm-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 >
                                        Email Verification
                                    </h1>
                                    <p>
                                        Provide email authorization for us to analyze your credit report!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 form-main-box'>
                            <div className='p-1'>
                                <div className='form-box row'>
                                    <div className='col-md-8 offset-md-2 pt-4 email-pl'>
                                        <h1 className='text-start'>
                                            Email Verification
                                        </h1>
                                        <p className='mt-md-3'>
                                            Fetch credit reports, statements and bills. Stay on top of your Credit Score
                                        </p>
                                    </div>
                                    <div className='col-md-8 offset-md-2'>

                                        <ul className="email_list_point">
                                            <li>Insights on Credit Score</li>
                                            <li>Boost your Credit Score</li>
                                            <li>Dispute in real-time</li>
                                            <li>Save and grow with us</li>
                                        </ul>

                                        {/* <div className='form-inner-box my-1 my-md-5'>
                                            <div className='mb-1 mb-md-2'>
                                                <label className='label-label'>Email id </label>
                                            </div>
                                            <div>
                                                <input type="text" className='input-box form-control' value={email} onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {(showError && (!email || email === '')) && <span className="demo-tetx">Please enter your email</span>}
                                                {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                            </div>
                                        </div> */}
                                        <div className='md-5'>
                                            <div className='button-boxes email-button-box'>
                                                <div className='m-3 order-sm-first order-md-last'>
                                                    {/*<button type='button' className='next-button' onClick={() => {
                                                        verifyEmail()
                                                    }}>Continue</button> */}

                                                    <div className="abcRioButton abcRioButtonBlue gl-btn" onClick={() => { verifyEmail() }}>
                                                        <div className="abcRioButtonContentWrapper">
                                                            <div className="abcRioButtonIcon gl-padding">
                                                                <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18 gl-svg-btn">
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                                                                    </path>
                                                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                                                    </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <span className="abcRioButtonContents gl-btn-text">
                                                                <span id="gl-btn">Sign in with Google</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default Email;