import { ToastContainer } from 'react-toastify';
import './App.css';
import LandingPage from './pages/landing-page/landing-page';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import SignPage from './pages/Sign Up/sign-page';
import OTP from './pages/Sign Up/otp/otp';
import Email from './pages/Sign Up/emailpage/email';
import DashBoard from './pages/Dashboard/dashboard';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import Login from './pages/Sign Up/loginpage/login';
import Terms from './pages/Sign Up/terms/terms';
import Privacypolicy from './pages/Sign Up/privacy_policy/privacy_policy';
import Boostscore from './pages/boost-score/boost-score';
import Banking from './pages/Dashboard/NeoBanking/neobanking';
import NavigateSetter from './config/navigation_service';
import Googleverify from './pages/Sign Up/Verify/consent_verify';
import Dispute from './pages/Dispute/dispute';
import CreateDispute from './pages/Dispute/createdispute';
import DisputeHistory from './pages/Dispute/disputehistory';
import DisputeDetails from './pages/Dispute/disputedetails';
import Profile from './pages/profile/profile';
import ChatAI from './pages/Boost-AI/Chat/chatai';
import ConntectQuickBook from './pages/Quick-Books/connect-quickbook';
import QBLoaderScreen from './pages/Quick-Books/qb-loader-screen/qb-loader-screen';
import QBDashboard from './pages/Quick-Books/qb-dashboard/qb-dashboard';
import QBIntegration from './pages/Quick-Books/qb-integration/qb-integration';
import SSNPage from './pages/Sign Up/ssn-page/ssn-page';
import AddressPage from './pages/Sign Up/address/address-page';
import CreditVerifyQA from './pages/Sign Up/credit-verify-qa/credit-verify-qa';
function App() {

  return (

    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter >
            <NavigateSetter />
            <Routes>
              <Route path='/' element={<LandingPage />}></Route>
              <Route path='/signup' element={<SignPage />}></Route>
              <Route path='/dashboard' element={<DashBoard />}></Route>
              <Route path='/otp' element={<OTP />}></Route>
              <Route path='/emailverification' element={<Email />}></Route>
              <Route path='/login' element={<Login />}></Route>
              <Route path='/terms' element={<Terms />}></Route>
              <Route path='/privacypolicy' element={<Privacypolicy />}></Route>
              <Route path='/boostscore' element={<Boostscore />}></Route>
              <Route path='/banking' element={<Banking />}></Route>
              <Route path='/googleverification' element={<Googleverify />}></Route>
              <Route path='/disputelist' element={<Dispute />}></Route>
              <Route path='/createdispute' element={<CreateDispute />}></Route>
              <Route path='/disputehistory' element={<DisputeHistory />}></Route>
              <Route path='/disputedetails' element={<DisputeDetails />}></Route>
              <Route path='/profile' element={<Profile />}></Route>
              <Route path='/message' element={<ChatAI />}></Route>
              <Route path='/connect-quickbook' element={<ConntectQuickBook />}></Route>
              <Route path='/qb-loader-screen' element={<QBLoaderScreen />}></Route>
              <Route path='/qb-dashboard' element={<QBDashboard />}></Route>
              <Route path='/qb-integration' element={<QBIntegration />}></Route>
              <Route path='/ssn-page' element={<SSNPage />}></Route>
              <Route path='/address-page' element={<AddressPage />}></Route>
              <Route path='/credit-verify-qa' element={<CreditVerifyQA />}></Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
