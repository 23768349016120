import '../../../App.css'
import './menubar.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reportDownload, downloadExcelReport } from '../../../redux/actions/dashboard_action';
import { logOut } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import getUserData from '../../../Common/helper';
import ProfileDropdown from '../../profile/profile_component/profile_dropdown';
import DownloadReportBtn from '../../Component/download-report-btn/download-report-btn';
const Logo = require("../../../images/assets/MobileViewLogo.png");
const smLogo = require("../../../images/assets/smlogo.png");
const INTUITQB = require('../../../images/assets/intuit.png');
function MenuBar() {

    const [currentRoute, setCurrentRoute] = useState('/');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [showCredit, setShowCredit] = useState(false);
    const [showBoost, setShowBoost] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showQBGenAI, setQBGenAI] = useState(false);
    const [showIntegration, setIntegration] = useState(false);
    const [userData, setUserData] = useState();
    const [showConnectView, setShowConnectView] = useState(false);
    useEffect(() => {
        setCurrentRoute(window.location.pathname);

        setShowBoost(false);
        setShowCredit(false);
        setQBGenAI(false);
        setIntegration(false);
        const isShowBoost = window.location.pathname === '/boostscore';
        if (isShowBoost) {
            setShowBoost(true)
        }
        const isShowCredit = window.location.pathname === '/dashboard';
        if (isShowCredit) {
            setShowCredit(true)
        }
        const data = getUserData();

        setUserData(data);
        fetchCompanyName();
    }, []);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.profile-click')) {
            if (!event.target.closest('.pro-dropdown-avatar')) {
                setShowProfile(false); // Close the profile dropdown if clicked outside
            }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // T

    const fetchCompanyName = async () => {
        try {
            const value = localStorage.getItem('companyName');
            const relalmID = localStorage.getItem('realmId');
            if (value != 'undefined' && value != null) {
                setShowConnectView(true)
            } else {
                setShowConnectView(false)
            }
        } catch (error) {
            console.log("error")
        }
    };

    const ScoreBoost = () => {
        navigate('/boostscore')
    }
    const ScoreCredit = () => {
        navigate('/dashboard')
    }
    const Dispute = () => {
        navigate('/disputelist')
    }
    const logout = async () => {
        var result = await dispatch(logOut());
        if (result) {
            navigate('/login')
        }
    }

    const QBIntegrationScreen = () => {
        navigate('/qb-integration')
    }

    const QBGenAI = () => {
        navigate('/qb-dashboard')
    }

    const navToggleMenu = () => {
        var nabbarCollapse = document.getElementById('navbarNavCollapseButton');
        nabbarCollapse.classList.add("collapsed");
        var navbarSubMenuList = document.getElementById('navCollapseExample');
        navbarSubMenuList.classList.remove('show')
    }

    const closeMenuBar = () => {
        var menubarClose = document.getElementById('offcanvasScrolling');
        menubarClose.classList.remove('show')
        menubarClose.classList.remove('hiding')
    }

    const downloadReport = () => {
        setOpen(false);
        setLoader(true);
        reportDownload()
            .then(response => {

                //const file = new Blob([response.value], {type: 'application/pdf'});		
                // Create a temporary URL for the downloaded file
                //const fileURL = URL.createObjectURL(file);
                //console.log("blob type" + typeof response);
                //console.log("blob respopnse type" + typeof response.value);		
                //console.log("blob" + response.value);

                const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                //window.open(fileURL); 
                //console.log("Url:" + url);


                // Create a temporary <a> element to initiate the download
                const a = document.createElement('a');
                a.href = url;
                a.download = 'boostreport.pdf';
                document.body.appendChild(a);
                a.click();
                // Cleanup the temporary URL and <a> element
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                console.error('Error downloading the PDF file:', error);
            })

        // setLoader(true);
        // fetch('boostreport.pdf')
        //     .then(response => response.blob())
        //     .then(blob => {
        //         // Create a temporary URL for the downloaded file
        //         const url = window.URL.createObjectURL(blob);

        //         // Create a temporary <a> element to initiate the download
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = 'boostreport.pdf';
        //         document.body.appendChild(a);
        //         a.click();
        //         setLoader(false);

        //         // Cleanup the temporary URL and <a> element
        //         a.remove();
        //         window.URL.revokeObjectURL(url);
        //     })
        //     .catch(error => {
        //         console.error('Error downloading the PDF file:', error);
        //     });
    }


    const downloadExcel = () => {
        setOpen(false);
        setLoader(true);
        downloadExcelReport()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'boost_transaction_report.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                console.error('Error downloading the Excel file:', error);
            })
    }

    const handleOpen = () => {
        setOpen(!open);
    };
    const profileLink = () => {
        if (showProfile) {
            setShowProfile(false)
        } else {
            setShowProfile(true)
        }
    }
    const dropdownStatus = open ? "show" : "";

    return (
        <>
            {loader && <Loader />}
            <nav className="navbar fixed-top navbar-expand-lg navbar-light pt-4" aria-label="Light offcanvas navbar">
                <div className="container-fluid">
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div>
                            <div className='d-flex align-items-center'>
                                <button className="navbar-toggler mx-2 " onClick={() => { navToggleMenu() }} type="button" data-bs-toggle="offcanvas" href="#offcanvasScrolling" role="button" aria-controls="offcanvasScrolling">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className='ms-2 '>
                                    <a className='navbar-brand img-logo-box logo-images-boxes' href='/'>
                                        <img src={Logo} className='head-img-logo' />
                                        <img src={smLogo} className='img-fluid sm-logo' />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-right-center ps-2'>
                            <div className='d-none d-lg-inline-flex d-md-inline-flex'>
                                <img src={INTUITQB} className='img-integration-qb-menu'
                                    onClick={() => { QBIntegrationScreen() }} />
                                <div className='me-2'></div>
                                {showConnectView && <span className='dot-green-online'></span>}
                                <div className='me-2'></div>
                            </div>
                            <div className='me-3'></div>
                            <DownloadReportBtn
                                btnLabel='Download Reports '
                                clickOnDownload={(langCode) => {
                                    downloadReport(langCode)
                                    /// event for click
                                }}
                                clickOnExcel={() => {
                                    downloadExcel()
                                    /// event for click
                                }}
                            ></DownloadReportBtn>
                            <div className='ms-2'></div>
                            {/* <Link className='profile-txt' onClick={() => { profileLink() }}> Profile</Link> */}
                            <div className="pro-dropdown-circular me-3" onClick={() => { profileLink() }}>
                                {userData && <div className="pro-dropdown-avatar">{userData && userData?.name != undefined ? userData?.name.charAt(0).toUpperCase() : ''}</div>}
                            </div>

                            {showProfile &&
                                <div className='profile-click'><ProfileDropdown setShowProfile={setShowProfile}></ProfileDropdown></div>}
                        </div>
                    </div>
                    <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" >
                        <div className="offcanvas-header">
                            <button type="button" className="btn text-light nav_icon" onClick={() => { navToggleMenu() }} data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                            <h5 className="offcanvas-title" id="offcanvasScrollingLabel"></h5>
                        </div>
                        <div className="offcanvas-body align-self-end canvas-body-box">
                            <ul className="navbar-nav mt-5">
                                <div className='menu-box my-3 mt-3'>
                                    <ul>
                                        <li>
                                            <a className={currentRoute === "/dashboard" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { ScoreCredit() }} id='navbarNavCollapseButton' data-bs-toggle="collapse" href="#navCollapseExample" role="button" aria-controls="navCollapseExample">
                                                <i className="bi bi-file-bar-graph nav_icon"></i><span className="nav_name">Credit Report <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                            </a>
                                            <div className={`collapse ${showCredit ? 'show' : ''}`} id="navCollapseExample">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="#creditscore" onClick={() => { closeMenuBar() }}>Credit Score</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#insights" onClick={() => { closeMenuBar() }}>Insights</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#summatyaccount" onClick={() => { closeMenuBar() }}>Account Summary</a>
                                                        </li>
                                                        {/* <li>
                                                            <a className="nav_links" href="#accountinfo" onClick={() => { closeMenuBar() }}>Upload Credit Report</a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a className={currentRoute === "/boostscore" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { ScoreBoost() }} data-bs-toggle="collapse" href="#collapseExampleTwo" role="button" aria-controls="collapseExampleTwo">
                                                <i className="bi bi-lightning-charge nav_icon"></i><span className="nav_name">Boost Score <i className="bi bi-chevron-down nav_icon nav-icons ps-2"></i></span>
                                            </a>
                                            <div className={`collapse ${showBoost ? 'show' : ''}`} id="collapseExampleTwo">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="#boostscore">Boost score</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#insights">Insights</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#analysis">Analysis</a>
                                                        </li>
                                                        <li>
                                                            <a className="nav_links" href="#bankaccount">Add bank account</a>
                                                        </li>
                                                        {/* <li>
                                                            <a className="nav_links" href="#uploadbankstatement">Add bank statement</a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a className={currentRoute === "/disputelist" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { Dispute() }} data-bs-toggle="collapse" href="#collapseExampleThree" role="button" aria-controls="collapseExampleThree">
                                                <i className="bi bi-shield-exclamation nav_icon"></i><span className="nav_name">Credit Report Dispute</span>
                                                <div className="collapse" id="collapseExampleThree">
                                                    <div className="card card-body">
                                                    </div>
                                                </div>

                                                {/* <br />
                                                <span className='cooming-soon-text'>(Coming soon)</span> */}
                                            </a>
                                        </li>
                                        <li>
                                            <a className={currentRoute === "/qb-dashboard" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { QBGenAI() }} id="sliderCollapse" data-bs-toggle="collapse" href="#collapseExampleFour" role="button" aria-controls="collapseExampleFour">
                                                <i className="bi bi-file-bar-graph nav_icon"></i><span className="nav_name">Generative AI <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                            </a>
                                            <div className={`collapse `} id="collapseExampleFour">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="#qb-chat">Boost AI</a>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a className={currentRoute === "/qb-integration" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { QBIntegrationScreen() }} data-bs-toggle="collapse" href="#collapseExampleFive" role="button" aria-expanded="false" aria-controls="collapseExampleFive">
                                                <i className="bi bi-lightning-charge nav_icon"></i><span className="nav_name">Integrations &nbsp;<i className="bi bi-chevron-down nav_icon nav-icons ps-2"></i></span>
                                            </a>
                                            <div className={`collapse`} id="collapseExampleFive">
                                                <div className="card card-body">
                                                    {/* <ul className="ms-5">
                                                        <li>
                                                            <a className={window.location.hash === "#intuitQB" ?
                                                                "nav_links active" : "nav_links"} href="#intuitQB">Intuit QuickBooks</a>
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </li>

                                        {/* <li>
                                            <a className="nav_link" rel="noopener">
                                                <i className="bi bi-people nav_icon"></i><span className="nav_name">Money Boosters</span>
                                                <br />
                                                <span className='cooming-soon-text'>(Coming soon)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="nav_link" rel="noopener">
                                                <i className="bi bi-credit-card nav_icon"></i><span className="nav_name">Boost Credit Card</span>
                                                <br />
                                                <span className='cooming-soon-text'>(Coming soon)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="nav_link" rel="noopener">
                                                <i className="bi bi-bank nav_icon"></i><span className="nav_name">Boost Savings account</span>
                                                <br />
                                                <span className='cooming-soon-text'>(Coming soon)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="nav_link" rel="noopener">
                                                <i className="bi bi-gear nav_icon"></i><span className="nav_name">Account settings</span>
                                                <br />
                                                <span className='cooming-soon-text'>(Coming soon)</span>
                                            </a>
                                        </li> */}
                                        {/* <li>
                                            <a className={currentRoute === "/login" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { logout() }}>
                                                <i className="bi bi-box-arrow-right nav_icon"></i><span className="nav_name" >Logout</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default MenuBar;
