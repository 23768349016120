import httpClient, { setAuthHeader } from "../../config/api";
import { LOGOUT, } from "../constants";

const loginAction = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/login',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const otpVerification = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/otp/verify',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error);
      });
  });

const emailVerification = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/google/oauth/url',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response);
      });
  });

const authCode = data =>
  new Promise((resolve, reject) => {

    httpClient({
      method: 'POST',
      url: 'api/v1/user/google/oauth/code',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const emailParseData = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/email/parse/data',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error.response);
    });
  });

const logOut = () => dispatch => {
  return new Promise(async (resolve, rejects) => {
    try {
      dispatch({
        type: LOGOUT,
        subtype: 'SUCCESS'
      });
      await localStorage.removeItem('token');
      setAuthHeader()
      resolve(true);
    } catch (error) {
      rejects(false);
    }
  });
};

const registerUser = userInfo =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/register',
      data: userInfo,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const fetchCreditDetailStage = (userInfo, stageType) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/credit/fetch/details?stage=' + stageType,
      data: userInfo,
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });


const initOnboarUserArrayApi = () =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/array/user/onboard',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  })

const submitUserArrayTokenApi = (params) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      data: params,
      url: 'api/v1/array/user/token',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  })

const initQuestions = () =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/user/credit/report/init',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const submitArrayAnswer = (requestBody, sessionId) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/array/answers',
      data: requestBody,
      headers: {
        'sessionId': sessionId,
      }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });
///

const fetchCreditReportSubmitAnswer = (requestBody,) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/credit/report/fetch',
      data: requestBody,
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const connectQBO = () =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/connectToQuickbooks',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const QBOCallback = (queryParams) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/oauth2redirect?code=' + queryParams.code + '&state=' + queryParams.state +
        '&realmId=' + queryParams.realmId,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });


const QBDisconnect = (queryParams) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/QBO/disconnect?realmId=' + queryParams
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const resendOTPAction = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/resend/otp',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });


export {
  loginAction,
  logOut,
  registerUser,
  otpVerification,
  emailVerification,
  emailParseData,
  authCode,
  connectQBO,
  QBOCallback,
  QBDisconnect,
  fetchCreditDetailStage,
  submitArrayAnswer,
  initQuestions,
  fetchCreditReportSubmitAnswer,
  initOnboarUserArrayApi,
  submitUserArrayTokenApi,
  resendOTPAction
}