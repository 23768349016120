import '../../../../App.css'
import './qb-menubar.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


import Loader from '../../../loader/loader';
import getUserData from '../../../../Common/helper';

import QBProfileDropdown from '../../qb-component/qb-profile-dropdown';
const Logo = require("../../../../images/assets/MobileViewLogo.png");
const smLogo = require("../../../../images/assets/smlogo.png");
const INTUITQB = require('../../../../images/assets/intuit.png');
function QBMenuBar() {

    const [currentRoute, setCurrentRoute] = useState('/');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);

    const [showQBGenAI, setQBGenAI] = useState(false);
    const [showIntegration, setIntegration] = useState(false);


    const QBIntegrationScreen = () => {
        navigate('/qb-integration')
    }

    const QBGenAI = () => {
        navigate('/qb-dashboard')
    }
    const [showProfile, setShowProfile] = useState(false);
    const [userData, setUserData] = useState();

    useEffect(() => {
        setCurrentRoute(window.location.pathname);
        setIntegration(false);
        setQBGenAI(false);
        const showIntegrationGenAI = window.location.pathname === '/qb-integration';
        if (showIntegrationGenAI) {
            setIntegration(true)
        }
        const showQBGenAI = window.location.pathname === '/qb-dashboard';
        if (showQBGenAI) {
            setQBGenAI(true)
        }
        // const data = getUserData();
        // setUserData(data);
    }, []);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.profile-click')) {
            if (!event.target.closest('.pro-dropdown-avatar')) {
                setShowProfile(false); // Close the profile dropdown if clicked outside
            }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // T

    const ScoreBoost = () => {
        navigate('/boostscore')
    }
    const ScoreCredit = () => {
        navigate('/dashboard')
    }
    const Dispute = () => {
        navigate('/disputelist')
    }
    const logout = async () => {
        // var result = await dispatch(logOut());
        // if (result) {
        //     navigate('/login')
        // }
    }

    const navToggleMenu = () => {
        var nabbarCollapse = document.getElementById('navbarNavCollapseButton');
        nabbarCollapse.classList.add("collapsed");
        var navbarSubMenuList = document.getElementById('navCollapseExample');
        navbarSubMenuList.classList.remove('show')
    }

    const closeMenuBar = () => {
        var menubarClose = document.getElementById('offcanvasScrolling');
        menubarClose.classList.remove('show')
        menubarClose.classList.remove('hiding')
    }




    const handleOpen = () => {
        setOpen(!open);
    };
    const profileLink = () => {
        if (showProfile) {
            setShowProfile(false)
        } else {
            setShowProfile(true)
        }
    }
    const dropdownStatus = open ? "show" : "";

    return (
        <>
            {loader && <Loader />}
            <nav className="navbar fixed-top navbar-expand-lg navbar-light pt-4" aria-label="Light offcanvas navbar">
                <div className="container-fluid">
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div>
                            <div className='d-flex align-items-centefr'>
                                <button className="navbar-toggler mx-2 " onClick={() => { navToggleMenu() }} type="button" data-bs-toggle="offcanvas" href="#offcanvasScrolling" role="button" aria-controls="offcanvasScrolling">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className='ms-2 '>
                                    <a className='navbar-brand img-logo-box logo-images-boxes' href='/qb-dashboard'>
                                        <img src={Logo} className='head-img-logo' />
                                        <img src={smLogo} className='img-fluid sm-logo' />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-right-center ps-2'>
                            <div className='ms-2'></div>
                            <div className='d-inline-flex'>
                                <img src={INTUITQB} className='img-integration-qb img-fluid'
                                    onClick={() => { QBIntegrationScreen() }} />
                                <div className='me-2'></div>
                                <span className='dot-green-online'></span>
                                <div className='me-2'></div>
                            </div>
                            <div className='me-3'></div>
                            {/* <Link className='profile-txt' onClick={() => { profileLink() }}> Profile</Link> */}
                            <div className="pro-dropdown-circular me-3" onClick={() => { profileLink() }}>
                                {userData && <div className="pro-dropdown-avatar">{userData && userData?.name != undefined ? userData?.name.charAt(0).toUpperCase() : ''}</div>}
                            </div>

                            {showProfile &&
                                <div className='profile-click'><QBProfileDropdown setShowProfile={setShowProfile}></QBProfileDropdown></div>}
                        </div>
                    </div>
                    <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" >
                        <div className="offcanvas-header">
                            <button type="button" className="btn text-light nav_icon" onClick={() => { navToggleMenu() }} data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                            <h5 className="offcanvas-title" id="offcanvasScrollingLabel"></h5>
                        </div>
                        <div className="offcanvas-body align-self-end canvas-body-box">
                            <ul className="navbar-nav mt-5">
                                <div className='menu-box my-3 mt-3'>
                                    <ul>
                                        <li>
                                            <a className={currentRoute === "/qb-dashboard" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { QBGenAI() }} id="sliderCollapse" data-bs-toggle="collapse" href="#collapseExampleSidebar" role="button" aria-controls="collapseExampleSidebar">
                                                <i className="bi bi-file-bar-graph nav_icon"></i><span className="nav_name">Generative AI <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                            </a>
                                            <div className={`collapse ${showQBGenAI ? 'show' : ''}`} id="collapseExampleSidebar">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className="nav_links" href="#qb-chat">Boost AI</a>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a className={currentRoute === "/qb-integration" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { QBIntegrationScreen() }} data-bs-toggle="collapse" href="#collapseExampleTwo" role="button" aria-expanded="false" aria-controls="collapseExampleTwo">
                                                <i className="bi bi-lightning-charge nav_icon"></i><span className="nav_name">Integrations &nbsp;<i className="bi bi-chevron-down nav_icon nav-icons ps-2"></i></span>
                                            </a>
                                            <div className={`collapse ${showIntegration ? 'show' : ''}`} id="collapseExampleTwo">
                                                <div className="card card-body">
                                                    <ul className="ms-5">
                                                        <li>
                                                            <a className={window.location.hash === "#intuitQB" ?
                                                                "nav_links active" : "nav_links"} href="#intuitQB">Intuit QuickBooks</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default QBMenuBar;
