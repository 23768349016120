// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-connect-qb{
    width: auto;
    cursor: pointer;
    height: 40px;
    margin: auto;
}
.loading-qb{
    width: auto;
    cursor: pointer;
    height: 60px;
    margin: auto;
}
.img-disconnect-qb{
    width: auto;
    cursor: pointer;
    height: 80px;
    margin: auto;
}
.cq-ul {
 list-style-type: disc;
 margin: 10px 0px;
 padding: 0;


}
.cq-li {
   font-size: 13px;
   margin: 5px 5px;
}
.dot-blue-ic{
    width: 5px;
    height: 5px;
    margin: 0px 4px;
}

.img-integration-qb{
    height: 40px;
    margin: auto;
    width: auto;
    cursor: pointer;
}

.btn-qb-disconnect {
  position: relative;
  background-color: #B80000;
  border: none;
  font-size: 15px;
  color: #FFFFFF;
  padding: 8px 12px;
  width: 140px;
  font-family: 'Roboto';
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.btn-qb-discard{
    background-color: white;
    border: 1px solid gray;
    color: #343A40;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Quick-Books/quickbook.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;AACA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;AACA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;AACA;CACC,qBAAqB;CACrB,gBAAgB;CAChB,UAAU;;;AAGX;AACA;GACG,eAAe;GACf,eAAe;AAClB;AACA;IACI,UAAU;IACV,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".img-connect-qb{\n    width: auto;\n    cursor: pointer;\n    height: 40px;\n    margin: auto;\n}\n.loading-qb{\n    width: auto;\n    cursor: pointer;\n    height: 60px;\n    margin: auto;\n}\n.img-disconnect-qb{\n    width: auto;\n    cursor: pointer;\n    height: 80px;\n    margin: auto;\n}\n.cq-ul {\n list-style-type: disc;\n margin: 10px 0px;\n padding: 0;\n\n\n}\n.cq-li {\n   font-size: 13px;\n   margin: 5px 5px;\n}\n.dot-blue-ic{\n    width: 5px;\n    height: 5px;\n    margin: 0px 4px;\n}\n\n.img-integration-qb{\n    height: 40px;\n    margin: auto;\n    width: auto;\n    cursor: pointer;\n}\n\n.btn-qb-disconnect {\n  position: relative;\n  background-color: #B80000;\n  border: none;\n  font-size: 15px;\n  color: #FFFFFF;\n  padding: 8px 12px;\n  width: 140px;\n  font-family: 'Roboto';\n  text-align: center;\n  transition-duration: 0.4s;\n  text-decoration: none;\n  overflow: hidden;\n  border-radius: 8px;\n  cursor: pointer;\n}\n\n.btn-qb-discard{\n    background-color: white;\n    border: 1px solid gray;\n    color: #343A40;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
