import {
    LOGOUT, SIGN_IN_ERROR, SIGN_IN_SUCCESS, SELECTED_BUREAU,
} from '../constants';

const initialState = {
    user: {},
    // bureau: '',
    signinError: {},
    remember_me: undefined
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        // case SELECTED_BUREAU:
        //     return {
        //         ...state,
        //         bureau: action.payload,
        //     };
        case SIGN_IN_ERROR:
            return {
                ...state,
                signinError: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                user:
                    action.subtype === 'SUCCESS' ? {} : state.user,
            };
        default:
            return {
                ...state,
            };
    }
};
export default authReducer;
