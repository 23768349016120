// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0px solid #d0dae2;
    background-color: #191D23;
    z-index: -1 !important;
    cursor: pointer;
}

.copyright a {
    text-decoration: none;
}

.copyright .list-unstyled,
.copyright .statement {
    opacity: 0.7;
    color: white !important;
}

.copyright-state{
   text-align: right;
}

.privacy-block{
    display: flex;
}

@media (max-width:576px) {
    .privacy-block{
        display: block;
        
    }
    .copyright-state{
        text-align: center;
     }
     .term{
        text-align: center;
        padding-bottom: 5px;
     }
}
.bm-white-logo{
    width: 150px;
    height: auto;
}
.footer-text{
    color: #DADADA;
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    cursor: pointer;
    font-weight: 700;
    line-height: 19px; /* 95% */
}
.footer-sub-text{
    color: #DADADA;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    line-height: 19px; /* 95% */
}
.footer-line{
    width: 100%;
    height: 0.20px;
    background: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/pages/landing-page/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,6BAA6B;IAC7B,yBAAyB;IACzB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;;IAEI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;GACG,iBAAiB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;;IAElB;IACA;QACI,kBAAkB;KACrB;KACA;QACG,kBAAkB;QAClB,mBAAmB;KACtB;AACL;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAE,QAAQ;AAC/B;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAE,QAAQ;AAC/B;AACA;IACI,WAAW;IACX,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".copyright {\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n    border-top: 0px solid #d0dae2;\n    background-color: #191D23;\n    z-index: -1 !important;\n    cursor: pointer;\n}\n\n.copyright a {\n    text-decoration: none;\n}\n\n.copyright .list-unstyled,\n.copyright .statement {\n    opacity: 0.7;\n    color: white !important;\n}\n\n.copyright-state{\n   text-align: right;\n}\n\n.privacy-block{\n    display: flex;\n}\n\n@media (max-width:576px) {\n    .privacy-block{\n        display: block;\n        \n    }\n    .copyright-state{\n        text-align: center;\n     }\n     .term{\n        text-align: center;\n        padding-bottom: 5px;\n     }\n}\n.bm-white-logo{\n    width: 150px;\n    height: auto;\n}\n.footer-text{\n    color: #DADADA;\n    font-family: 'Roboto';\n    font-size: 18px;\n    font-style: normal;\n    cursor: pointer;\n    font-weight: 700;\n    line-height: 19px; /* 95% */\n}\n.footer-sub-text{\n    color: #DADADA;\n    font-family: 'Roboto';\n    font-size: 14px;\n    font-style: normal;\n    cursor: pointer;\n    font-weight: 400;\n    line-height: 19px; /* 95% */\n}\n.footer-line{\n    width: 100%;\n    height: 0.20px;\n    background: #FFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
