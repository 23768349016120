import React from 'react';
import './insights-dilog.css'
import '../../../../../App.css'
import 'react-dropdown/style.css';
import { useState } from 'react';
import { useEffect } from 'react';

const Insights = (props) => {


    const [analysisReportData, setAnalysisReportData] = useState(props.analysisReportData);
    const [analysisName, setAnalysisName] = useState(props.selectedAnalysisName);


    useEffect(() => {
    }, [])

    return (
        <>
            <div className='container px-0'>
                <div className='row insights-container-heding-box'>
                    <div className='col-6'>
                        <p className='onscore-text'>{analysisReportData.impactOnScore}</p>
                    </div>
                    <div className='col-6'>
                        <p className={(analysisReportData.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData.impactStatus ?? '').toLowerCase() === 'excellent' ? 'status-box good-btn-box' : (analysisReportData.impactStatus ?? '').toLowerCase() === 'bad' ? 'status-box bad-btn-box' : (analysisReportData.impactStatus ?? '').toLowerCase() === 'fair' ? 'status-box fair-btn-box' : ''}>{analysisReportData.impactStatus}</p>
                    </div>
                </div>
                <div className='border-top border-bottom row my-3'>
                    <div className='col-6 total-payments-numbers'>
                        <div className='p-2 py-3 border-end'>
                            <div>
                                {analysisName === 'accounts' && <h3 className='payments-tetx'>  {analysisReportData.noOfActiveAccounts}</h3>}
                                {analysisName === 'creditHistory' && <h3 className='payments-tetx'>  {analysisReportData.noOfActiveAccounts}</h3>}
                                {analysisName === 'creditUtilization' && <h3 className='payments-tetx'>  {analysisReportData.totalCreditLimit}</h3>}
                                {analysisName === 'enquiries' && <h3 className='payments-tetx'>  {analysisReportData.totalNoOfEnquiry}</h3>}
                                {analysisName === 'paymentHistory' && <h3 className='payments-tetx'>  {analysisReportData.noOfLatePayments}</h3>}
                            </div>
                            <div>
                                {analysisName === 'accounts' && <p className='total-acc-text'>  Active Accounts</p>}
                                {analysisName === 'creditHistory' && <p className='total-acc-text'>  Active Accounts</p>}
                                {analysisName === 'creditUtilization' && <p className='total-acc-text'>  Total Credit limit</p>}
                                {analysisName === 'enquiries' && <p className='total-acc-text'>  Enquiries for Loans</p>}
                                {analysisName === 'paymentHistory' && <p className='total-acc-text'>  Late Payments</p>}
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='p-2 py-3'>
                            <div>
                                {analysisName === 'accounts' && <h3 className='payments-tetx'>  {analysisReportData.noOfClosedAccounts}</h3>}
                                {analysisName === 'creditHistory' && <h3 className='payments-tetx'>  {analysisReportData.ageOfAccounts}</h3>}
                                {analysisName === 'creditUtilization' && <h3 className='payments-tetx'>  {analysisReportData.ratio}</h3>}
                                {analysisName === 'enquiries' && <h3 className='payments-tetx'>  {analysisReportData.noOfCardEnquiry}</h3>}
                                {analysisName === 'paymentHistory' && <h3 className='payments-tetx'>  {analysisReportData.ratio}%</h3>}
                            </div>
                            <div>
                                {analysisName === 'accounts' && <p className='total-acc-text'> Closed Accounts</p>}
                                {analysisName === 'creditHistory' && <p className='total-acc-text'> Age of Accounts</p>}
                                {analysisName === 'creditUtilization' && <p className='total-acc-text'> Credit Utilization</p>}
                                {analysisName === 'enquiries' && <p className='total-acc-text'> Enquiries for Cards</p>}
                                {analysisName === 'paymentHistory' && <p className='total-acc-text'> Payments on Time</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-4 label-title-icon-box'>
                    <div className={(analysisReportData.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData.impactStatus ?? '').toLowerCase() === 'excellent' ? 'row title-sore-box good-btn-box' : (analysisReportData.impactStatus ?? '').toLowerCase() === 'bad' ? 'row title-sore-box bad-btn-box' : (analysisReportData.impactStatus ?? '').toLowerCase() === 'fair' ? 'row title-sore-box fair-btn-box' : ''}>
                        <div className='col-12 row align-items-center'>
                            <div className='col-2'>
                                <div>
                                    {((analysisReportData.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData.impactStatus ?? '').toLowerCase() === 'excellent') && <i className="bi bi-hand-thumbs-up thump-icon-box"></i>}
                                    {(analysisReportData.impactStatus ?? '').toLowerCase() === 'bad' && <i className="bi bi-exclamation-triangle bad-icon-box"></i>}
                                    {(analysisReportData.impactStatus ?? '').toLowerCase() === 'fair' && <i className="bi bi-exclamation-triangle fair-icon-box"></i>}
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='ms-2 ms-sm-0'>
                                    <p className={(analysisReportData.impactStatus ?? '').toLowerCase() === 'good' || (analysisReportData.impactStatus ?? '').toLowerCase() === 'excellent' ? 'mb-0 tinfi-title-text good-btn-box' : (analysisReportData.impactStatus ?? '').toLowerCase() === 'bad' ? 'mb-0 tinfi-title-text bad-btn-box' : (analysisReportData.impactStatus ?? '').toLowerCase() === 'fair' ? 'mb-0 tinfi-title-text fair-btn-box' : ''}>
                                        {analysisReportData?.information?.title}
                                    </p>
                                    <p className='mb-0 title-message-text'>
                                        {analysisReportData?.information?.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row text-center my-2'>
                    <div className='col-12'>
                        <button className='btn gotit-buttons' onClick={() => {
                            props.closeDialog()
                        }}>OK, Got it.</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Insights;