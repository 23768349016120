import { useEffect, useState } from 'react';
import Loader from "../../loader/loader";
import { QBDisconnect } from '../../../redux/actions/auth_action';
import '../quickbook.css'
import DisconnectModal from '../qb-component/disconnect-dialog/disconnect-modal';
import MenuBar from '../../Dashboard/Menubar/menubar';
import Sidebar from '../../Dashboard/SideBar/sidebar';

import { useSelector, } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import getUserData from '../../../Common/helper';
const INTUITQB = require('../../../images/assets/intuit.png');
const SIGNZY = require('../../../images/assets/signzy.png');
const INTIC = require('../../../images/assets/integration_ic.png');
const DotBlue = require("../../../images/assets/dot-blue.png");
const DisconnetQB = require("../../../images/assets/disconnet-qb.png");
const CQBTN = require("../../../images/assets/connect-qb-btn.png");

function QBIntegration(props) {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [showConnectView, setShowConnectView] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [realmID, setRealmID] = useState('');
    const [showModalPayload, setShowModalPayload] = useState({
        showModel: false,
        msgErrorSuccess: ''
    });

    const [userData, setUserData] = useState();
    const handleOpenModal = () =>
        setShowModalPayload({
            showModel: true,
            msgErrorSuccess: ''
        });
    const handleCloseModal = () => setShowModalPayload({
        showModel: false,
        msgErrorSuccess: ''
    });
    const { user } = useSelector(state => {
        return state.authReducer;
    });
    useEffect(() => {
        if (!user?.access_token) {
            navigate('/')
        }

        const data = getUserData()
        setUserData(data)
        fetchCompanyName();
        setErrorMessage('')
    }, [])


    const connectQB = async () => {
        localStorage.removeItem('companyName');
        localStorage.removeItem('realmId');// localStorage
        navigate('/connect-quickbook')
    }
    const fetchCompanyName = async () => {
        try {
            const value = localStorage.getItem('companyName');
            const relalmID = localStorage.getItem('realmId');

            // console.log(value)
            // console.log(relalmID)
            if (value != 'undefined' && value != null) {
                setCompanyName(value);
                setRealmID(relalmID)
                setShowConnectView(false)
            } else {
                setRealmID('')
                setCompanyName('');
                setShowConnectView(true)
            }
        } catch (error) {
            console.log("error")
        }
    };
    const DisconnectView = () => {
        return (<>
            <button className="btn-qb-disconnect" onClick={handleOpenModal}>
                <span className='m-0 p-0'>Disconnect </span></button>

            <p className='cq-li pt-0 m-0 pt-3 fw-normal'>
                Disconnect to remove integration with QuickBooks</p>
        </>);
    }

    const ConnectQBView = () => {
        return (<>
            <img src={CQBTN} alt='cq-btn'
                onClick={connectQB}
                className='img-connect-qb' />

            <p className='cq-li pt-0 m-0 pt-3 fw-normal'>
                Connect to QuickBooks to get your business transactions, invoices & cashflow</p>
        </>);
    }


    const ApiCallDisconnectQB = () => {
        setErrorMessage('')
        QBDisconnect(realmID).then(resp => {
            
            if (resp.statusCode == 'ERROR') {
                setErrorMessage(resp.statusMessage)
                setShowConnectView(false)
            } else {
                localStorage.removeItem('companyName');
                localStorage.removeItem('realmId');// localStorage
                setShowConnectView(true)
            }


        }).catch(error => {
            setErrorMessage('Somethig went wrong!')
            setLoader(false)
        });
    }

    const IntegrationComponent = () => {
        return (<section className='px-3 pb-5'>
            <div className="accordion" id="accordionPanelsStayTwoExample">
                <div className="accordion-item">
                    <h2 className="accordion-header mb-0">
                        <div className='d-flex flex-row justify-content-between py-1'>
                            <div id='personalInformationCollapseButton'
                                className={`accordion-button acc-info-text m-0 `} type="button" data-bs-target="#panelsStayOpen-collapseTwo" aria-controls="panelsStayOpen-collapseTwo">
                                Intuit QuickBooks
                            </div>

                        </div>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className={`accordion-collapse collapse show`} data-bs-parent="#accordionPanelsStayTwoExample">
                        <div className="accordion-body">
                            <div className='d-inline-flex'>
                                <img src={INTUITQB} className='img-integration-qb img-fluid' />
                                <div className='me-3'></div>
                                <img src={INTIC} className='img-integration-qb img-fluid' />
                                <div className='me-3'></div>
                                <p className='p-0 m-0'>{companyName}</p>
                                {/* <img src={SIGNZY} className='img-integration-qb img-fluid' /> */}
                            </div>


                            <div className='m-0 p-0 form-inner-box py-2'>
                                <ul className='cq-ul'>
                                    <li className='cq-li py-2 fw-semibold'><span><img src={DotBlue} className='dot-blue-ic' /></span>
                                        Boost.Money can connect with QuickBooks to provide small businesses Insights & Recommendations based on their business data.</li>
                                    <li className='cq-li py-2 fw-semibold'><span><img src={DotBlue} className='dot-blue-ic' /></span>
                                        The businesses can get details of their Invoices including payment status, invoice amount, invoice date & so on.</li>
                                    <li className='cq-li py-2 fw-semibold'><span><img src={DotBlue} className='dot-blue-ic' /></span>
                                        Businesses can also track their categorised transactions that is generated on our platform using AI.</li>
                                    <li className='cq-li py-2 fw-semibold'><span><img src={DotBlue} className='dot-blue-ic' /></span>
                                        Talk to you business data in real time using our Boost AI.</li>
                                </ul>
                            </div>

                            {!showConnectView && <DisconnectView></DisconnectView>}


                            {showModalPayload.showModel &&
                                <DisconnectModal onClickDisconnect={() => {
                                    handleCloseModal()
                                    ApiCallDisconnectQB();
                                }} onClose={handleCloseModal}
                                />
                            }

                            {showConnectView && <ConnectQBView></ConnectQBView>}


                            {errorMessage.length > 0 && <>
                                <p className='p-0 m-0 text-danger'>{errorMessage}</p>
                            </>}

                        </div>
                    </div>
                </div>
            </div>
        </section>);
    };

    return (
        <>


            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    <div className='mt-4 mt-sm-2'>

                        <section className='' id='qb-chat'>

                            <div className='container-lg container-fluid mt-3 ps-lg-3'>
                                <h1 className='header-title mb-2 mb-sm-3'>Integration</h1>
                            </div>


                            <IntegrationComponent></IntegrationComponent>

                        </section>

                    </div >
                </div>

            </div>


        </>
    );
}

export default QBIntegration;