import { useState } from 'react';
import '../../../App.css'
import './Invitation.css'
import { addDocs, firestores, collections } from '../../../script'

function Invitation() {

    const [email, SetEmail] = useState('');
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();

    const submit = async () => {
        setUserExists('')
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setShowError(false);
        if (email === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setUserExists('Please enter valid email address');
        } else {
            const docRef = await addDocs(collections(firestores, "emails"), { email });
            SetEmail('');
            setUserExists('We have received your request. Thank you')
        }
    }

    return (
        <>
            <div className="geadint-box">
                <div className="container-box">
                    <div className="container ">
                        <div id="contact" className="basic-3 py-5 mt-5">
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <div className="text-container text-center">
                                        {/* <h2>Join our waiting list!</h2> */}
                                        <p className="p-large">Start your Boost Journey with us!</p>
                                        <div className="email-box">
                                            <form name="gform" id="gform">
                                                <div className="form-group">
                                                    <input type="text" name="emailAddress" className="form-control emailaccess"
                                                        id="emailaccess" placeholder="Your email address" value={email} onChange={(event) => { SetEmail(event.target.value) }} />
                                                    <label className="errormsg"></label>
                                                    {(showError && email === '') && <label className="demo-tetx text-light">Please enter email address</label>}
                                                    {userExists !== '' && <p className="demo-tetx mt-2 text-light">{userExists}</p>}
                                                </div>
                                                <input id="submitBtn" type="button" className="btn-solid-lg" onClick={() => { submit() }}
                                                    value="Submit" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Invitation;