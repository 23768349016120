import '../../../App.css';
import './Testimonial.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const CreditScore = require("../../../images/icon1.png");
const ComBooScore = require("../../../images/icon2.png");
const BankBoostMoney = require("../../../images/icon3.png");
const ActionableInsights = require("../../../images/icon4.png");
const BoostSavingCard = require("../../../images/card.png");
const ActionableInsightsCard = require("../../../images/pie.png");

function Testimonial() {

  const items = [
    <div className="item" data-value="1">
      <div className="mx-md-4 box-card boost-saving-main-box text-center">
        <div className="card-body">
          <div className="text-center padding-box">
            <p className="testimonial-texts pb-5">Boost Savings Card</p>
            <img className="img-fluid image-y-padding" src={BoostSavingCard} alt="alternative" />
          </div>
        </div>
      </div>
    </div>,
    <div className="item" data-value="2">
      <div className="mx-md-4 box-card boost-saving-main-box text-center">
        <div className="card-body ">
          <div className="text-center padding-box">
            <p className="testimonial-texts">Actionable Insights</p>
            <img className='image-y-padding img-fluid' src={ActionableInsightsCard} alt="alternative" />
          </div>
        </div>
      </div>
    </div>
  ];

  const responsive = {
    0: {
      items: 1
    },
    767: {
      items: 1
    },
    1024: {
      items: 2,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
  };


  return (
    <>
      <div className="bg-gray">
        <div className="container">
          <div id="works" className="cards-2">
            <div className="row">
              <div className="col-lg=12">
                <div className="card card-2">
                  <div className="card-body align-items-center">
                    <div className="icon-body">
                      <img src={CreditScore} alt="alternative" />
                    </div>
                    <div className="details-txt">
                      <div className="testimonial-author">Credit Score</div>
                      <p className="testimonial-text">Actionable insights from your current credit report</p>
                    </div>
                  </div>
                </div>

                <div className="card card-2">
                  <div className="card-body align-items-center">
                    <div className="icon-body">
                      <img src={ComBooScore} alt="alternative" />
                    </div>
                    <div className="details-txt">
                      <div className="testimonial-author">Compute Boost Score</div>
                      <p className="testimonial-text">Our Data Science helps you get a Boost Score</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card card-2">
                  <div className="card-body align-items-center">
                    <div className="icon-body">
                      <img src={BankBoostMoney} alt="alternative" />
                    </div>
                    <div className="details-txt">
                      <div className="testimonial-author">Bank with Boost.Money</div>
                      <p className="testimonial-text">Get a Boost Savings Card and start saving
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card card-2">
                  <div className="card-body align-items-center">
                    <div className="icon-body">
                      <img src={ActionableInsights} alt="alternative" />
                    </div>
                    <div className="details-txt">
                      <div className="testimonial-author">Actionable Insights</div>
                      <p className="testimonial-text">Stay on top of your Finances with our insights
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className='row'>
            <div className='col-12 px-0 px-md-5'>
              <div id='lamdingPageSlider'>
                <AliceCarousel
                  items={items}
                  responsive={responsive} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Testimonial;