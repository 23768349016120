import React from 'react';
import '../../App.css';
import Boostscorecredit from './boost-score-credit/boost-score-credit';
import './boost-score.css'
import { useEffect, useState } from 'react';
import '../../App.css';
import '../Dashboard/dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../loader/loader';
import MenuBar from '../Dashboard/Menubar/menubar';
import Sidebar from '../Dashboard/SideBar/sidebar';

const Boostscore = () => {

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        } else {
        }
    }, [])

    const scrollToTop = () => {
        var d = document.getElementById("boostScore");
        d.scrollTo({ top: 0, behavior: 'smooth' })
    };

    return (
        <>
            {loader && <Loader />}
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='boostScore' className="pt-1 sidebat-inner-box sidnav-inner-box">
                    <Boostscorecredit scrollToTop={scrollToTop} />
                </div>
            </div>
        </>
    )
}

export default Boostscore