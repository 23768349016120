import '../../../App.css'
import './Scroe-card.css'
import { useEffect, useState } from 'react';
import { creditReport, refreshToken, initCreditRequest, fetchCreditReport, checkStageStatus } from '../../../redux/actions/dashboard_action';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getUserData from '../../../Common/helper';
import Footer from '../../landing-page/Footer/Footer';
import BankStatment from '../Bank/bankstatement';
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactDialogBox } from 'react-js-dialog-box';
import { authCode, emailVerification, emailParseData } from '../../../redux/actions/auth_action';
import Insights from './components/insihght/insights-dilog';
import ScoreDetails from './components/score-details/score-range-dailog';
import ErrorMessage from '../error-message/error-message';
import { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { dispatchAction } from '../../../redux/actions/common_action';
import { SELECTED_BUREAU } from '../../../redux/constants';
import Loader from '../../loader/loader';
import Select, { components } from 'react-select';
import { customStyles } from './components/custom-style-component-bureau'
import UseScriptCode from '../../Sign Up/ssn-page/use-script';
import { initOnboarUserArrayApi, loginAction, otpVerification, submitUserArrayTokenApi } from '../../../redux/actions/auth_action';
import FullScreenModal from '../../Sign Up/otp/full_screen_modal';
import { v4 as uuidv4 } from 'uuid';
const AxisBank = require("../../../images/Bank/Bank.png");
const Score = require("../../../images/assets/Chart.png");
const PaymentsIcon = require("../../../images/assets/Time Machine.png");
const LimitIcon = require("../../../images/assets/Money Box.png");
const AgeIcon = require("../../../images/assets/Schedule.png");
const AccountIcon = require("../../../images/assets/Merchant Account.png");
const EnquiriesIcon = require("../../../images/assets/Query.png");
const ICArrowUp = require("../../../images/assets/ic-arrow-up.png");
const ExperianLogo = require("../../../images/Bank/experian.png");
const CibillLogo = require("../../../images/Bank/cibil.png");
const CrifLogo = require("../../../images/Bank/crif.png");
const EquifaxLogo = require("../../../images/Bank/equifax.png");
const InfoIC = require("../../../images/assets/info-ic.png");



function ScoreCards(props) {

    const [creditReportData, setCreditReportData] = useState();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [userData, setUserData] = useState();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenDialogS, setIsOpenDialogS] = useState(false);
    const [dialogHeight, setDialogHeight] = useState('auto')
    const [selectedBureau, setSelectedBureau] = useState('');
    const [bureauList, setBureauList] = useState([]);
    const [showError, setShowError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [analysisReportData, setAnalysisReportData] = useState();
    const [insightsDialog, setInsightsDialog] = useState(false);
    const [selectedAnalysisName, setSelectedAnalysisName] = useState();
    const [emailErrorMessage, setEmailErrorMessage] = useState();
    const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
    const [message, setMessage] = useState();
    const [isViewAll, setIsViewAll] = useState(true);
    const [insightsItem, setInsightsItem] = useState([])
    const { state } = useLocation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [loader, setLoader] = useState(false);
    const [getRefreshTokenStatus, setRefreshTokenData] = useState();
    const [isCollapsedCreditInfo, setIsCreditInfoCollapse] = useState(true);
    const [isCollapsedPersonalInfo, setIsPersonalCollapse] = useState(false);
    const [collapsedBankItems, setCollapsedBankItems] = useState(Array(0).fill(true));
    const [checkInitStageObj, setCheckInitStageObj] = useState({
        "statusCode": "",
        "statusMessage": "",
        "stage1": false,
        "stage2": false
    })
    // ////////////////////`  array stuff start     // //////////////////// 
    // UseScriptCode('https://embed.array.io/cms/array-web-component.js?appKey=3F03D20E-5311-43D8-8A76-E4B5D77793BD');
    // UseScriptCode('https://embed.array.io/cms/array-account-enroll.js?appKey=3F03D20E-5311-43D8-8A76-E4B5D77793BD');
    var isCallOnce = true;
    const [initOnboarArrayObj, setInitOnboarArrayObj] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => setModalOpen(false);
    useEffect(() => {
        // console.log("isCallOnce " + isCallOnce);
        //  window.addEventListener('array-event', arrayEventHandler);
    }, []);


    function arrayEventHandler(arrayEvent) {
        const {
            tagName, // the name of the component that emitted the event
            event, // the name of the user's action
            metadata = {}, // Component-specific data
        } = arrayEvent.detail;

        applyCSSForKBA();

        if (tagName === 'array-account-enroll' && event === 'signup' && metadata && metadata.userToken != null
            && metadata.userId != null && metadata.userToken != undefined && metadata.userId != undefined
        ) {
            if (isCallOnce === true) {
                setTimeout(() => {

                    submitUserArrayToken(metadata.userId, metadata.userToken);
                }, 500);
            }
            window.removeEventListener('array-event', arrayEventHandler);
        }
        console.log(metadata);
        console.log("component: " + tagName + "; user action: " + event);
    }

    const applyCSSForKBA = () => {
        const accountEnroll = document.querySelector('array-account-enroll').shadowRoot;
        if (accountEnroll != null) {
            // Then, find the `array-authentication-kba` element within `array-account-enroll`
            const authKba = accountEnroll.querySelector('array-authentication-kba');
            if (authKba != null) {
                // console.log("authKba  " + authKba)
                const shadowRoot = authKba.shadowRoot;
                if (shadowRoot != null) {
                    const style = document.createElement('style');
                    style.textContent = `
                    .arr-container-legacy-sizing.svelte-1mx24w9 .arr-card.svelte-1mx24w9:not(.arr-card-on-banner){
                        background:white !important;
                    }
                `;
                    shadowRoot.appendChild(style);
                }
            }
        }
    }
    ////////////////////  array stuff end     // ////////////////////



    const toggleBankCollapse = (index) => {
        const newCollapsedItems = [...collapsedBankItems];
        newCollapsedItems[index] = !newCollapsedItems[index];
        setCollapsedBankItems(newCollapsedItems);
    };
    const toggleCollapseCreditInfo = () => {
        setIsCreditInfoCollapse(!isCollapsedCreditInfo);
    };
    const toggleCollapsedPersonalInfo = () => {
        setIsPersonalCollapse(!isCollapsedPersonalInfo);
    };

    useEffect(() => {
        setMessage("")
        if (!user?.access_token) {
            navigate('/');
        } else {
            initialCheckStages();
            // getCreditScoreApi();
        }
    }, []);

    const getCreditScoreApi = () => {
        const data = getUserData()
        if (bureau) {
            setSelectedBureau(bureau);
            getCreditReport(bureau);
            //getRefreshTokenData();
        } else {
            getCreditReport();
            //getRefreshTokenData();
        }
        setUserData(data)
        if (state?.success) {
            setMessage({ success: state?.success.statusMessage });
        } else if (state?.error) {
            setMessage({ error: state?.error.statusMessage });
        }
    }

    const openInsights = (name) => {
        setSelectedAnalysisName(name);
        setInsightsDialog(true)
        setDialogHeight('auto')
    }

    const openScoreDetails = (name) => {
        setScoreDitailsDialog(true)
        setDialogHeight('auto')
    }

    const openDialog = () => {
        setIsOpenDialogS(true)
    }

    const openDialogs = () => {
        setIsOpenDialogS(true)
    }

    const closeinsght = () => {
        setInsightsDialog(false)
        setDialogHeight('auto')
    }

    const closeScoreDetails = () => {
        setScoreDitailsDialog(false)
        setDialogHeight('auto')
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setDialogHeight('auto')
    }

    const closeDialogS = () => {
        setIsOpenDialogS(false)
        setDialogHeight('auto')
    }

    const refreshTokenBtn = () => {
        setLoader(true);
        var param = {
            "isCreditReport": true
        }
        setMessage("")
        emailParseData(param).then(resp => {
            setLoader(false);
            if (resp.statusCode && resp.statusCode === "SUCCESS") {
                setMessage({ success: resp.statusMessage });
                navigate('/dashboard', { state: { "success": resp.statusMessage } });
                getCreditReport();
            } else {
                setMessage({ error: resp.statusMessage });
                navigate('/dashboard', { state: { "error": resp.statusMessage } });
            }
        }).catch(error => {
            setLoader(false);
            if (error.statusCode === "ERROR") {
                setMessage({ error: error.statusMessage });
                navigate('/dashboard', { state: { "error": error.statusMessage } });
            }
        })
    }

    const initialCheckStages = () => {
        checkStageStatus().then(resp => {
            setLoader(false);
            if (resp) {
                if (resp.statusCode === "SUCCESS") {
                    setCheckInitStageObj(resp);
                }
            }
            getCreditScoreApi();
            // console.log(resp)
        }).catch(error => {
            setLoader(false);
        });
    }

    const navigateToStagesScreen = () => {

        //   initOnboarUserArray();
        if (checkInitStageObj.stage1 === false) {
            navigate('/ssn-page')
            return;
        }
        if (checkInitStageObj.stage2 === false) {
            navigate('/address-page')
            return;
        }
    }

    // Get Credit Report Data
    const getCreditScore = () => {
        navigate('/credit-verify-qa')
    }

    const submit = async () => {
        setMessage("")
        var param = {
            "email": email,
            "isCreditReport": true,
            "isBankStatement": false,
            "isBankStatementAsync": false
        }
        setLoader(true);
        emailVerification(param).then(resp => {
            setLoader(false);
            if (resp.statusCode === "SUCCESS") {
                window.location = resp.url;
            }
        }).catch(error => {
            setLoader(false);
            if (error.statusCode === "ERROR") {
                setMessage({ error: error.statusMessage });
                navigate('/dashboard', { state: { "error": error.statusMessage } });
            }
        })
    }

    const { user, bureau } = useSelector(state => {
        return state.authReducer;
    });

    const getRefreshTokenData = () => {
        refreshToken().then(resp => {
            setRefreshTokenData(resp);
        }).catch(error => {
            setRefreshTokenData(error);
        })
    }

    const getCreditReport = (bureau = '') => {
        setLoader(true)
        creditReport(bureau).then((resp) => {

            setCreditReportData(resp.data);
            insightsAnalysisReports(resp.analysis)
            bureauDataGenerate(resp?.bureauName ?? '', resp?.availableOtherBureauData ?? []);
            setLoader(false)
            // if (resp.data == null) {
            //     const stageData = {
            //         "statusCode": "SUCCESS",
            //         "statusMessage": "",
            //         "stage1": false,
            //         "stage2": false
            //     };
            //     setCheckInitStageObj(stageData)
            // }
        }).catch((error) => {
            setLoader(false)
        })
    }

    const insightsAnalysisReports = (analysis) => {
        setAnalysisReportData(analysis)
        var i = [];
        if (analysis && analysis.paymentHistory) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={PaymentsIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.label && analysis?.paymentHistory?.label != null) ? analysis.paymentHistory.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.impactOnScore && analysis?.paymentHistory?.impactOnScore !== null) ? analysis.paymentHistory.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('paymentHistory') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.information?.title && analysis?.paymentHistory?.information?.title !== null) ? analysis.paymentHistory.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(analysis && analysis !== null) ? (analysis?.paymentHistory?.ratio && analysis?.paymentHistory?.ratio !== null) ? (analysis.paymentHistory.ratio + '%') : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.accounts) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={AccountIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.accounts?.label && analysis?.accounts?.label !== null) ? analysis.accounts.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.accounts?.impactOnScore && analysis?.accounts?.impactOnScore !== null) ? analysis.accounts.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('accounts') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.accounts?.information?.title && analysis?.accounts?.information?.title !== null) ? analysis.accounts.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        {analysis?.accounts?.noOfActiveAccounts && <h5 className={(analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.accounts?.noOfActiveAccounts && analysis?.accounts?.noOfActiveAccounts !== null) ? analysis.accounts.noOfActiveAccounts : '' : ''} Active</h5>}
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.creditHistory) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={AgeIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.creditHistory?.label && analysis?.creditHistory?.label !== null) ? analysis.creditHistory.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.creditHistory?.impactOnScore && analysis?.creditHistory?.impactOnScore !== null) ? analysis.creditHistory.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditHistory') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.creditHistory?.information?.title && analysis?.creditHistory?.information?.title !== null) ? analysis.creditHistory.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.creditHistory?.ageOfAccounts && analysis?.creditHistory?.ageOfAccounts !== null) ? analysis.creditHistory.ageOfAccounts : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.creditUtilization) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={LimitIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.label && analysis?.creditUtilization?.label !== null) ? analysis.creditUtilization.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.impactOnScore && analysis?.creditUtilization?.impactOnScore !== null) ? analysis.creditUtilization.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditUtilization') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.information?.title && analysis?.creditUtilization?.information?.title !== null) ? analysis.creditUtilization.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.creditUtilization?.ratio && analysis?.creditUtilization?.ratio !== null) ? (analysis.creditUtilization.ratio + '%') : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.enquiries) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={EnquiriesIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(analysis && analysis !== null) ? (analysis?.enquiries?.label && analysis?.enquiries?.label !== null) ? analysis.enquiries.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title">{(analysis && analysis !== null) ? (analysis?.enquiries?.impactOnScore && analysis?.enquiries?.impactOnScore !== null) ? analysis.enquiries.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('enquiries') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.enquiries?.information?.title && analysis?.enquiries?.information?.title !== null) ? analysis.enquiries.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.enquiries?.totalNoOfEnquiry && analysis?.enquiries?.totalNoOfEnquiry !== null) ? analysis.enquiries.totalNoOfEnquiry : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        setInsightsItem(i)
    }

    const responsives = {
        // 0: {
        //     items: 2
        // },
        // 767: {
        //     items: 2
        // },
        // 1024: {
        //     items: 5,
        //     itemsFit: 'contain',
        //     disableButtonsControls: 'true',
        //     infinite: 'true'
        // },
        0: {
            items: 1.8
        },
        360: {
            items: 2
        },
        375: {
            items: 2
        },
        390: {
            items: 2.1
        },
        412: {
            items: 2.2
        },
        414: {
            items: 2.3
        },
        430: {
            items: 2.3
        },
        540: {
            items: 3,
        },
        576: {
            items: 3
        },
        768: {
            items: 4,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
        820: {
            items: 4.2
        },
        1024: {
            items: 4,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
        1200: {
            items: 5,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
    };

    const bureauDataGenerate = (bureauName, bureaudata) => {
        setSelectedBureau(bureauName);
        var bureauListData = bureauList;
        if (!bureauListData.includes(bureauName)) {
            bureauListData.push(bureauName)
        }
        bureaudata.forEach(element => {
            if (!bureauListData.includes(element)) {
                bureauListData.push(element)
            }
        });
        setBureauList(bureauListData);
    }

    const addressCreate = (address) => {
        var addresses = [];
        for (let index = 0; index < address.length; index++) {
            addresses.push(
                <div className='row' key={index + "Address"}>
                    <div className='col-4 my-2'>
                        <p className='report-main-text text-start'>{address[index].reportedDate ?? ''}</p>
                    </div>
                    <div className='col-8 my-2'>
                        <p className='report-sub-text text-break text-start'>
                            {address[index].address ?? ''}
                        </p>
                    </div>
                    <div className='border'></div>
                </div>
            )
        }
        return addresses;
    }

    const eamilAddress = (emails) => {
        var emailAddressVariationss = [];
        for (let index = 0; index < emails.length; index++) {
            emailAddressVariationss.push(
                <div key={index + "Emails"}>
                    <p className='report-sub-text text-break text-lowercase'>{emails[index] ?? ''}</p>
                </div>
            )
        }
        return emailAddressVariationss;
    }

    const mobileNumbers = (phoneNumbers) => {
        var mobileNumberVariationss = [];
        for (let index = 0; index < phoneNumbers.length; index++) {
            mobileNumberVariationss.push(
                <div key={index + "Emails"}>
                    <p className='report-sub-text text-break text-lowercase'>{phoneNumbers[index] ?? ''}</p>
                </div>
            )
        }
        return mobileNumberVariationss;
    }

    // const accountDetails = (accountess) => {
    //     var accounts = [];
    //     for (let index = 0; index < accountess.length; index++) {
    //         accounts.push(
    //             <div className="accordion-item my-3" key={index + "Account"}>
    //                 <div className="accordion-header accordion-button align-items-start pb-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="false" aria-controls={"collapseOne" + index}>
    //                     <div className='row w-100 align-items-start g-2 g-sm-0'>
    //                         <div className='col-12 col-sm-4'>
    //                             <div className='d-flex align-items-start'>
    //                                 <div className='bank-image-box'>
    //                                     <img src={AxisBank} width={100}></img>
    //                                 </div>
    //                                 <div className='text-start ms-3'>
    //                                     <div className='row d-flex align-items-center'>
    //                                         <div className='col-12 mb-1'>
    //                                             <h1 className='bank-name-text mb-0'>{accountess[index].creditAccountInformation.lender ?? "-"}</h1>
    //                                         </div>
    //                                     </div>
    //                                     <div className='row d-flex align-items-center'>
    //                                         <div className='col-12 mt-1'>
    //                                             <p className='reported-date'>{accountess[index].creditAccountInformation.accountType ?? "-"}</p>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         {/* <div className='col-12 col-sm-5'>
    //                             <span className='mb-1 progress-text'>50% Payment done</span>
    //                             <div className='progresssbars'>
    //                                 <ProgressBar completed={50} labelClassName="labels" barContainerClassName="barcompleted" />
    //                             </div>
    //                         </div> */}
    //                         <div className='col-12 col-sm-3'>
    //                             <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
    //                                 {accountess[index].creditAccountDetails.creditLimitAmount !== '' && accountess[index].creditAccountDetails.creditLimitAmount !== '-' && <h1 className='text-start text-sm-end dolars-rs-text mb-0'>{accountess[index].creditAccountDetails.creditLimitAmount ? "$" + accountess[index].creditAccountDetails.creditLimitAmount : ""}</h1>}
    //                                 {/* <div className='credit-report-amount-box float-sm-end float-start'>
    //                                     <div className='good-stand-box'>Good Standing</div>
    //                                 </div> */}
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div id={"collapseOne" + index} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
    //                     <div className="accordion-body">
    //                         <div className='row g-3'>
    //                             <div className='col-lg-6'>
    //                                 <div className='account-details-box'>
    //                                     <p className='account-details-text'>Account details</p>
    //                                     <div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Account number</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text text-break'>{accountess[index].creditAccountTerms.accountNumber ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Type</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountInformation.accountType ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Status</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.accountStatus ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Activity Reported</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.dateReported ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Date Opened</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Current balance</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.currentBalance ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Amount overdue</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.amountOverdue ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Payment Amount</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.actualPaymentAmount ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Cash Limit</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.cashLimit ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Highest Credit</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.highestCredit ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Last Payment Date</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.lastPaymentDate ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Loan Type</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.loanType ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Suitfiled</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDescription.suitFiled ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Credit Limit Amount</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.creditLimitAmount ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Emi Amount</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.emiAmount ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className='col-lg-6'>
    //                                 <div className='account-details-box'>
    //                                     <div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Principal Write Off</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.principalWriteOff ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Repayment Tenure</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.repaymentTenure ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Settlement Amount</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.settlementAmount ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Total Write Off Amount</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.totalWriteOffAmount ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Written Off Settle Status</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountDetails.writtenOffSettleStatus ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Account Holder Name</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountInformation.accountHolderName ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Addresses</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text text-break'>{accountess[index].creditAccountInformation.addresses ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Credit Facility Status</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.creditFacilityStatus ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Date Closed</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.dateClosed ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Date Opened</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Ownership</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.ownership ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Payment End Date</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.paymentEndDate ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Payment Frequency</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.paymentFrequency ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Rate Of Interest</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.rateOfInterest ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>SuitFiled</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.suitFiled ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Type Of Collateral</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.typeOfCollateral ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                         <div className='row'>
    //                                             <div className='col-6'>
    //                                                 <div className='text-start'>
    //                                                     <p className='account-details-main-text'>Value Of Collateral</p>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='col-6'>
    //                                                 <div className='text-end'>
    //                                                     <p className='account-details-sub-text'>{accountess[index].creditAccountTerms.valueOfCollateral ?? "-"}</p>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='dividers'></div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }
    //     return accounts;
    // }

    const accountDetails = (accountess, personalInformation) => {
        var accounts = [];
        for (let index = 0; index < accountess.length; index++) {
            accounts.push(
                <div className="accordion-item my-3" key={"accordionItem" + index}>
                    <div className="accordion-header accordion-button align-items-center collapsed" type="button"
                        onClick={() => toggleBankCollapse(index)}
                        // data-bs-toggle="collapse"
                        data-bs-target={"#collapseOne" + index}
                        aria-expanded={!collapsedBankItems[index]}
                        aria-controls={"collapseOne" + index}>
                        <div className='row w-100 align-items-center g-2 g-sm-0'>
                            <div className='col-12 p-0 m-0'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='bank-image-box'>
                                            <img src={AxisBank} width={100}></img>
                                        </div>
                                        <div className='text-start ms-3 p-0 m-0 pt-2'>
                                            <div className='row d-flex align-items-center p-0 m-0'>
                                                <div className='col-12 p-0 m-0'>
                                                    <h1 className='bank-name-text p-0 m-0'>{accountess[index].creditAccountInformation.lender ?? "N/A"}</h1>
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className='col-12 p-0 m-0'>
                                                    <p className='acc-type-text pt-2 m-0'>{accountess[index].creditAccountInformation.accountType + " | " +
                                                        `${accountess[index].creditAccountTerms.accountNumber ?? 'N/A'}` ?? "N/A"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='pe-2'>
                                        <div className='d-flex flex-row justify-content-center align-items-center'>
                                            {(accountess[index].creditAccountDescription.accountStatus && accountess[index].creditAccountDescription.accountStatus != null && accountess[index].creditAccountDescription.accountStatus !== '') &&
                                                (<div className='pe-2'>
                                                    <div className={accountess[index].creditAccountDescription.accountStatus === 'CLOSED'
                                                        || accountess[index].creditAccountDescription.accountStatus == 'CLOSED' ? 'box-active-bank box-close-bank m-0' :
                                                        'box-active-bank m-0'}>
                                                        {accountess[index].creditAccountDescription.accountStatus ?? ''}</div>
                                                </div>)}
                                            <img src={ICArrowUp} className={`arrow_icon ${collapsedBankItems[index] ? 'rotate-180' : ''}`}></img>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className='col-12 col-sm-5'>
                                <span className='mb-1 progress-text'>50% Payment done</span>
                                <div className='progresssbars'>
                                    <ProgressBar completed={50} labelClassName="labels" barContainerClassName="barcompleted" />
                                </div>
                            </div> */}
                            {/* <div className='col-12 col-sm-3'>
                                <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
                                    {accountess[index].creditAccountDetails.creditLimitAmount !== '' && accountess[index].creditAccountDetails.creditLimitAmount !== '-' && <h1 className='text-start text-sm-end dolars-rs-text mb-0'>$  {accountess[index].creditAccountDetails.creditLimitAmount ?? ""}</h1>}
                                    <div className='credit-report-amount-box float-sm-end float-start'>
                                        <div className='good-stand-box'>Good Standing</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div id={"collapseOne" + index}
                        className={"accordion-collapse collapse" + (collapsedBankItems[index] ? "" : " show")}
                        aria-labelledby={"headingOne" + index}>
                        <div className="accordion-body">
                            <div className='row g-2'>
                                <div className='col-lg-4'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text  m-0 py-3'>Account Term</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Account number</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.accountNumber ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Opened</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Closed</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.dateClosed ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Ownership</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.ownership ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Rate Of Interest</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.rateOfInterest ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Value Of Collateral</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.valueOfCollateral ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Type Of Collateral</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.typeOfCollateral ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 ps-lg-2 pt-sm-0 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text m-0 py-3'>Account Description</p>
                                        <div className='dividers'></div>
                                        <div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Reported</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.dateReported ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Loan Type</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.loanType ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Account Status</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.accountStatus ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Highest Credit</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.highestCredit ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Current balance</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.currentBalance ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Amount overdue</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.amountOverdue ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Last Payment Date</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.lastPaymentDate ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 ps-lg-2 pt-sm-0 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text m-0 py-3'>Account Details</p>
                                        <div className='dividers'></div>
                                        <div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Credit Limit Amt</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.creditLimitAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Emi</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.emiAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Repayment Tenure</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.repaymentTenure ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Total Write Off</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.totalWriteOffAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Principal Write Off</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.principalWriteOff ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Settlement Amt</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.settlementAmount ?? "N/A"}</p>
                                                </div>
                                            </div>

                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Write Off Settle</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.writtenOffSettleStatus ?? "N/A"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='row g-2 mt-3'>
                                <div className='col-lg-6 pe-lg-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text  m-0 py-3'>Personal Information</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Name</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.name ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date of Birth</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.dateOfBirth ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Gender</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.gender ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Mobile Number</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.mobileNumber ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Email Address</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.eamilAddress ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Occupation</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.employmentInfo?.occupation ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>PAN</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.pan ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 pt-sm-0 ps-lg-2 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0 '>
                                        <p className='account-details-text m-0 py-3'>Address</p>
                                        <div className='dividers'></div>
                                        <div className={`address-box${!accountess[index].creditAccountInformation?.addresses ||
                                            accountess[index].creditAccountInformation?.addresses.length === 0 ? ' no-scroll-address-box' : ''}`}>
                                            {(accountess[index].creditAccountInformation?.addresses && accountess[index].creditAccountInformation?.addresses.length > 0) ?
                                                accountess[index].creditAccountInformation?.addresses.map((address, idx) => (
                                                    <div key={idx}>
                                                        {idx !== 0 && <div className='dividers'></div>}
                                                        <div className='row p-0 m-0'>
                                                            <div className='col-5 p-0 m-0'>
                                                                <p className='account-details-main-text account-details-main-text-new text-start'>Address {idx + 1}</p>
                                                            </div>
                                                            <div className='col-7 p-0 m-0'>
                                                                <p className='account-details-sub-text account-details-sub-text-new text-end'>{address ?? "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) :
                                                <div className='d-flex h-100 align-items-center justify-content-center '>
                                                    <p className='p-0 m-0 px-1 text-center no-address-line'>There's no address associated with this account.</p>
                                                </div>
                                            }
                                        </div>
                                        {/* <div className='dividers'></div>
                                        <div className='row p-0 m-0'>
                                            <div className='col-6 p-0 m-0'>
                                                <p className='account-details-main-text account-details-main-text-new text-start'>Address 1</p>
                                            </div>
                                            <div className='col-6 p-0 m-0'>
                                                <p className='account-details-sub-text account-details-sub-text-new text-end'>{accountess[index].creditAccountInformation?.addresses[0] ?? "N/A"}</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div >
            )
        }
        return accounts;
    }

    const bureauTabs = () => {
        var bureau = [];
        for (let index = 0; index < bureauList.length; index++) {
            bureau.push(
                <button type="button" key={index} className={selectedBureau === bureauList[index] ? "btn change-report-btn-active userscore-texts me-1 ms-0" : "btn change-report-btn userscore-texts me-1 ms-0"}
                    onClick={() => {
                        setSelectedBureau(bureauList[index])
                        dispatch(dispatchAction(SELECTED_BUREAU, bureauList[index], false));
                        getCreditReport(bureauList[index]);
                        setIsViewAll(true)

                        // personalInformationCollapseBtn

                        var personalInformationCollapseBtn = document.getElementById('personalInformationCollapseButton');
                        personalInformationCollapseBtn.classList.add("collapsed");

                        var panelsStayOpenCollapseTwo = document.getElementById('panelsStayOpen-collapseTwo');
                        panelsStayOpenCollapseTwo.classList.remove('show')

                        // accountInformationButtons

                        var accountInformationButtons = document.getElementById('accountInformationButtons');
                        accountInformationButtons.classList.add("collapsed");

                        var panelsStayOpenCollapseOne = document.getElementById('panelsStayOpen-collapseOne');
                        panelsStayOpenCollapseOne.classList.remove('show')
                    }}>
                    {bureauList[index]}
                </button>
            )
        }
        return bureau;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit()
        }
    };

    const personalInformationAddresViewAll = () => {
        setIsViewAll(false);
        document.getElementById("personalInformation").style.height = "100%";
    }

    const onPersonalInformationExpand = () => {
        if (ref.current != null) {
            document.getElementById("personalInformation").style.height = (ref.current.clientHeight - 19).toString() + "px";
        }
    }


    const Control = ({ children, ...props }) => {
        const [inputValue, setInputValue] = useState('');
        const { selectProps, hasValue } = props;

        return (
            <components.Control {...props}>
                <div style={{ position: 'relative', display: 'flex', }}>
                    {hasValue || inputValue ? (
                        <label
                            style={{
                                position: 'absolute',
                                left: '6px',
                                top: '-24px',
                                backgroundColor: 'white',
                                padding: '0 4px',
                                fontSize: '80%',
                                fontWeight: '400',
                                fontFamily: 'Poppins',
                                color: '#000000',
                            }}
                        >
                            {selectProps.placeholder}
                        </label>
                    ) : null}
                    {children}
                </div>
            </components.Control>
        );
    };

    const CreditStagePrompt = ({ isStageCompleted }) => {

        return (
            <div className='container-lg container-fluid'>
                <div className='row my-2 g-0 align-items-center'>
                    <div className='col-sm-12'>
                        <div className={isStageCompleted ? 'boxes' : 'boxes-stage-white'}>
                            <div className='row g-2 align-items-start'>
                                <div className='col-sm-8 col-md-8'>
                                    <div className='row text-start align-items-center align-items-sm-start'>
                                        <div className='col-12 d-flex'>
                                            <div className='mt-1 boost-score-image-box'>
                                                <img src={isStageCompleted ? Score : InfoIC} className='' width='45' />
                                            </div>
                                            <div className='boost-gl-section'>
                                                <p className={`boost-score-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0 ${isStageCompleted ? '' : 'boxes-stage-orange-color'}`}>
                                                    {isStageCompleted ? 'Get credit reports, statements and bills.' : 'GET LATEST CREDIT REPORT'}</p>
                                                <p className={`boost-score-sub-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0 ${isStageCompleted ? '' : 'boxes-stage-orange-color'}`}>
                                                    {isStageCompleted ? 'Login with Google and Stay on top of your Credit Score' : 'Complete the remaining steps to fetch your latest credit report'} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-4 text-start col-md-4'>
                                    <div className='score-buttton-box box-score-credit-box '>
                                        <div className="abcRioBlackwhite gl-btn" onClick={() => {

                                            if (isStageCompleted) {
                                                getCreditScore()
                                            } else {
                                                navigateToStagesScreen()
                                            }

                                        }}>
                                            <div className={`abcRioButtonContentWrapper ${isStageCompleted ? '' : 'btn-stage-white'}`}>
                                                <span className="abcRioButtonContents gl-btn-text">
                                                    <span id="gl-btn">{isStageCompleted ? 'Get Credit Score' : 'Complete Your Jorney'} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };



    const initOnboarUserArray = () => {

        setLoader(true)

        initOnboarUserArrayApi().then(resp => {

            setLoader(false)
            if (resp != null) {


                // const initObj = {
                //     "userId": resp.userId,
                //     "referenceId": resp.referenceId,
                //     "appKey": resp.appKey,
                //     "firstName": resp.firstName,
                //     "lastName": resp.lastName,
                //     "email": resp.email,
                //     "mobileNumber": resp.mobileNumber,
                // };

                const initObj = {
                    "userId": uuidv4(),
                    "referenceId": resp.referenceId,
                    "appKey": '3F03D20E-5311-43D8-8A76-E4B5D77793BD',
                    "firstName": 'DONALD',
                    "lastName": 'BLAIR',
                    "email": 'donald@boost.money',
                    "mobileNumber": '1111111111',
                };
                setInitOnboarArrayObj(initObj)
                openModal();

            }


        }).catch(error => {
            setLoader(false)
        })
    }

    const submitUserArrayToken = (userId, userToken) => {

        const param = {
            "userId": userId,
            "arrayUserToken": userToken
        };
        setLoader(true)
        isCallOnce = false;
        closeModal(true)
        submitUserArrayTokenApi(param).then(resp => {
            setLoader(false)
            if (resp != null) {
                if (resp.statusCode === "SUCCESS") {
                    getCreditScoreApi()
                }
            }
            console.log(resp)
        }).catch(error => {
            setLoader(false)

        })
    }


    return (
        <>
            {loader && <Loader />}
            <div className='mt-4 mt-sm-2'>
               

                <section className='' id='creditscore'>

                    <div className='container-lg container-fluid mt-3 ps-lg-3'>
                        <h1 className='header-title mb-2 mb-sm-3'>Credit Report</h1>
                    </div>
                    {bureauList.length !== 0 && creditReportData != null && <div className='container-lg container-fluid my-sm-3 pt-2 ps-lg-3'>
                        <Select
                            styles={customStyles}
                            components={{ Control }}
                            defaultValue={{ value: bureauList[0], label: bureauList[0] }}
                            placeholder='Select Credit Bureau'
                            isSearchable={false}
                            options={bureauList.map((bureau) => ({
                                value: bureau,
                                label: bureau,
                            }))}
                            onChange={(bureau) => {
                                setSelectedBureau(bureau.value)
                                dispatch(dispatchAction(SELECTED_BUREAU, bureau.value, false));
                                getCreditReport(bureau.value);
                                setIsViewAll(true)
                            }}
                            formatOptionLabel={bureau => (
                                <div className="country-option">
                                    <img src={
                                        bureau.value == "EXPERIAN" ? ExperianLogo :
                                            bureau.value == "TransUnion" ? CibillLogo :
                                                bureau.value == "EQUIFAX" ? EquifaxLogo : CrifLogo} alt="dropdown-logo" className='dropdown-logo' />
                                </div>
                            )}
                        />
                    </div>}
                    <div className='container-lg container-fluid  mt-3 my-sm-3'>
                        <div className='row align-items-start align-items-lg-center align-items-md-center g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0'>
                            <div className='col-5'>
                                <div className='ps-sm-2'>
                                    <h1 className='username-text mb-2 mb-sm-3'>Hi {userData?.given_name}!</h1>
                                    <p className='credit-bureau-text mb-2'>Check Out Your <span className='bold-txt'>{selectedBureau} Credit Report</span></p>
                                    {/* <p className='credit-bureau-text mb-1'>Next Report In :  <span className='bold-txt'> 27 Days</span></p> */}
                                    {creditReportData != null && <div className='d-flex flex-column flex-lg-row flex-md-row flex-sm-row align-content-center
                                      align-items-start align-items-lg-center align-items-md-center align-items-sm-center'>
                                        <p className='credit-bureau-text pe-2 m-0'>Get latest credit score: </p>
                                        <div className="refresh-score-btn" onClick={() => {
                                            getCreditScore();
                                        }}>
                                            <div className='d-flex flex-row align-content-center align-items-center p-0 m-0'>
                                                <span className="bi bi-arrow-repeat p-0 m-0 icon-refresh" ></span>
                                                <p className='ps-2 refresh-score-text'>Refresh Score</p>
                                            </div>
                                        </div>
                                    </div>}
                                </div>


                            </div>
                            <div className='col-7'>
                                <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                                    <div className='text-end text-sm-end me-sm-2'>
                                        <p className='mb-0 userscore-textes'>Your Credit score</p>
                                        <h1 className={creditReportData && creditReportData !== null && creditReportData?.score?.score > 0 ? 'credit-score' : 'credit-score0'} >
                                            {(creditReportData && creditReportData !== null) ? (creditReportData?.score?.score && creditReportData?.score?.score !== null && Number(creditReportData?.score?.score) >= 0) ? creditReportData.score.score : '0' : '---'}
                                        </h1>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                                        <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                                            {(creditReportData?.score?.score >= 780 && creditReportData?.score?.score <= 900) &&
                                                <div className='excellect-box score-box'>
                                                    <p className='mb-0 score-box-text'>Excellent</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 706 && creditReportData?.score?.score <= 779) &&
                                                <div className='goods-box score-box'>
                                                    <p className='mb-0 score-box-text'>Good</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 631 && creditReportData?.score?.score <= 705) &&
                                                <div className='average-box score-box'>
                                                    <p className='mb-0 score-box-text'>Average</p>
                                                </div>}
                                            {(creditReportData?.score?.score <= 630) &&
                                                <div className='poor-box score-box'>
                                                    <p className='mb-0 score-box-text'>Poor</p>
                                                </div>}
                                            {(creditReportData?.score?.score === undefined || creditReportData?.score?.score === null || creditReportData?.score?.score === 0) &&
                                                <div className='score-not-show-box score-box mx-2'>
                                                    <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                                                </div>}
                                        </div>
                                        <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1" onClick={() => { openScoreDetails() }}></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className='crdit-score-dailog'>
                                {scoreDitailsDialog && (
                                    <ReactDialogBox
                                        closeBox={closeScoreDetails}
                                        headerBackgroundColor='white'
                                        headerTextColor='black'
                                        closeButtonColor='black'
                                        bodyBackgroundColor='white'
                                        bodyTextColor='black'
                                        headerText="Credit Score Range"
                                        bodyHeight={dialogHeight}
                                        borderRadius='8px'
                                        headerHeight='65px'
                                    >
                                        <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                                    </ReactDialogBox>)}
                            </div>
                        </div>
                    </div>
                </section>



                <section>
                    <ErrorMessage message={message} state={state} />
                </section>

                {/* && creditReportData === null */}
                {checkInitStageObj.statusCode !== '' && creditReportData === null && <CreditStagePrompt isStageCompleted={
                    checkInitStageObj.stage1 === true && checkInitStageObj.stage2 === true
                } >
                </CreditStagePrompt>}

                {/* {getRefreshTokenStatus && getRefreshTokenStatus.statusCode && getRefreshTokenStatus.statusCode === "SUCCESS" &&
                  <section id="refresh-score">                        
                    <div className='container-lg container-fluid my-3'>
                        <div className="show-message-box alert-success d-flex align-items-center w-100 justify-content-between">
                            <div className='d-flex align-items-center align-items-sm-center'>
                                <div className="circle red"></div>
                                <p className='mb-0 ms-3 error-message-text-email'>Refresh Score to get latest Credit Score, Insights and much more.</p>
                            </div>
                            <button type="button" className="message-box-btn " onClick={() => { refreshTokenBtn() }}>
                                <span className="bi bi-arrow-repeat"></span>
                                <span className="msg-btn-text">Refresh Score</span>
                            </button>
                        </div>
                    </div>
                    <div className='container-lg container-fluid my-3'>
                        <button type="button" className="message-box-btn score-btn-dis" onClick={() => { refreshTokenBtn() }}>
                                    <span className="bi bi-arrow-repeat"></span>
                                    <span className="msg-btn-text">Refresh Score</span>
                        </button>
                     </div>
                </section> }     */}

                <section id='insights'>
                    <div className='container-lg container-fluid my-3'>
                        {analysisReportData && <div className='insights-main-box'>
                            <div className='row g-2'>
                                <div className='col-12 p-0'>
                                    <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>INSIGHTS</p>

                                </div>
                            </div>
                            <div className='dividers w-100'></div>
                            <div className='row g-2'>
                                <div className='col-12 p-0 m-0'>
                                    <p className='ps-2 ps-lg-4 ps-md-3 m-0 pt-3 insights-sub-text'>Check out the factors affecting your credit score!</p>
                                </div>
                                <div className='col-12 parent-insight'>
                                    <AliceCarousel
                                        items={insightsItem}
                                        responsive={responsives}>
                                    </AliceCarousel>
                                    <div id='insightdialog'>
                                        {insightsDialog && (
                                            <ReactDialogBox
                                                closeBox={closeinsght}
                                                headerBackgroundColor='white'
                                                headerTextColor='black'
                                                closeButtonColor='black'
                                                bodyBackgroundColor='white'
                                                bodyTextColor='black'
                                                bodyHeight={dialogHeight}
                                                borderRadius='8px'
                                            >
                                                <Insights closeDialog={closeinsght} setDialogHeight={setDialogHeight} setAnalysisReportData={setAnalysisReportData} selectedAnalysisName={selectedAnalysisName} analysisReportData={analysisReportData[selectedAnalysisName]} />
                                            </ReactDialogBox>)}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section >



                {creditReportData?.creditAccountInformationDetails && <section id='summatyaccount'>
                    <div className='container-lg container-fluid my-3'>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 id='accountInformationButtons'
                                    className={`accordion-header accordion-button align-items-start pb-0 p-0 ${isCollapsedCreditInfo ? 'collapsed' : ''}`}
                                    type="button" data-bs-target="#panelsStayOpen-collapseOne"
                                    // className="accordion-header accordion-header accordion-button align-items-start pb-0 collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
                                    aria-controls="panelsStayOpen-collapseOne" onClick={toggleCollapseCreditInfo}>
                                    <div className={creditReportData && creditReportData?.reportSummary ? 'w-100 m-0 pb-3' : 'w-100 m-0 pb-0'}>
                                        <div className='d-flex justify-content-between py-3 align-items-center ps-2 ps-lg-4 ps-md-3'>
                                            <p className="acc-info-text m-0">Credit Account Information</p>
                                            <div className='pe-3'>
                                                <img src={ICArrowUp} className={`arrow_icon ${isCollapsedCreditInfo ? 'rotate-180' : ''}`}></img>
                                            </div>
                                            {/* <span className='text-end me-2 view-text-all'>View All</span> */}
                                        </div>
                                        {creditReportData && creditReportData?.reportSummary && <div className='dividers w-100'></div>}
                                        {creditReportData && creditReportData?.reportSummary && <section>
                                            <div className='my-2 ps-2 ps-lg-4 ps-md-3'>
                                                <div className='row align-items-center g-3'>
                                                    <div className='col-lg-2'>
                                                        <p className='account-summary-text'>Account Summary</p>
                                                    </div>
                                                    <div className='col-lg-10 row g-2'>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='balance-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Balance</p>
                                                                    {creditReportData?.reportSummary?.currentBalanceAccountSummary && <h3 className='summary-numbers'>$  {(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount && creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount != null) ? creditReportData.reportSummary.currentBalanceAccountSummary.totalCurrentBalanceAmount : '-' : '-'}</h3>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='payments-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Payments</p>
                                                                    <h3 className='summary-numbers'> -</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='open-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Open Accounts</p>
                                                                    <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts && creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.activeAccounts : '-' : '-'}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='closed-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Closed Accounts</p>
                                                                    <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts && creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.closedAccounts : '-' : '-'}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>}
                                    </div>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className={`accordion-collapse collapse ${isCollapsedCreditInfo ? '' : 'show'}`} data-bs-parent="#accordionPanelsStayOpenExample">
                                    <div className="accordion-body">
                                        <div className='row m-0 p-0'>
                                            <div className='col-12 m-0 p-0'>
                                                <div className='account-accordion-box'>
                                                    <div className="accordion" id="accordionFlushExample">
                                                        {creditReportData?.creditAccountInformationDetails
                                                            && creditReportData?.creditAccountInformationDetails.length > 0
                                                            && accountDetails(creditReportData.creditAccountInformationDetails, creditReportData.personalInformation)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }

                {creditReportData?.personalInformation && <div className='container-lg container-fluid my-3' id='accountinfo'>
                    <div className="accordion" id="accordionPanelsStayTwoExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header mb-0">
                                <div className='d-flex flex-row justify-content-between py-1'>
                                    <div id='personalInformationCollapseButton'
                                        className={`accordion-button acc-info-text m-0 ${isCollapsedPersonalInfo ? 'collapsed' : ''}`} type="button" data-bs-target="#panelsStayOpen-collapseTwo" aria-controls="panelsStayOpen-collapseTwo"
                                        onClick={() => {
                                            toggleCollapsedPersonalInfo()
                                            // onPersonalInformationExpand()
                                        }}>
                                        Personal Information
                                    </div>
                                    <div className='pe-3 pt-1'>
                                        <img src={ICArrowUp} className={`arrow_icon ${isCollapsedPersonalInfo ? 'rotate-180' : ''}`}></img>
                                    </div>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className={`accordion-collapse collapse ${isCollapsedPersonalInfo ? '' : 'show'}`} data-bs-parent="#accordionPanelsStayTwoExample">
                                <div className="accordion-body">
                                    <div className='row g-2 mt-2'>
                                        <div className='col-lg-6 pe-lg-2'>
                                            <div className='account-details-box p-0 m-0'>
                                                <p className='account-details-text  m-0 py-3'>Personal Information</p>
                                                <div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Name</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.name ?? "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Date of Birth</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.dateOfBirth ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Gender</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.gender ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Mobile Number</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.mobileNumber ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Email Address</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.eamilAddress ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Occupation</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.employmentInfo?.occupation ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>PAN</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.pan ?? "N/A"}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 ps-lg-2 pt-lg-0 pt-sm-0 pt-md-2 pt-2'>
                                            <div className='account-details-box p-0 m-0 '>
                                                <p className='account-details-text m-0 py-3'>Address</p>
                                                <div className='address-box'>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Address</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.address ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='row g-2 personal-infomation-dialog-box'>
                                        <div className='col-md-6'>
                                            {creditReportData && creditReportData?.personalInformation &&
                                                <div className='right-side-border-box' ref={ref} id='PersonalInfo'>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <i className="bi bi-person person-icon"></i>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>BIO</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    <div className='row my-2 '>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Name</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.name && creditReportData?.personalInformation?.name != null) ? creditReportData.personalInformation.name : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>DOB</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.dateOfBirth && creditReportData?.personalInformation?.dateOfBirth != null) ? creditReportData.personalInformation.dateOfBirth : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>PAN</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.pan && creditReportData?.personalInformation?.pan != null) ? creditReportData.personalInformation.pan : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Passport</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>{(creditReportData && creditReportData !== null) ? (creditReportData?.personalInformation?.passportNumber && creditReportData?.personalInformation?.passportNumber != null) ? creditReportData.personalInformation.passportNumber : '' : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Email id</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            {creditReportData?.personalInformation.emailAddressVariations && eamilAddress(creditReportData.personalInformation.emailAddressVariations)}
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <p className='report-main-text'>Contact </p>
                                                        </div>
                                                        <div className='col-8'>
                                                            {creditReportData?.personalInformation.mobileNumberVariations && mobileNumbers(creditReportData.personalInformation.mobileNumberVariations)}
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>
                                                   
                                                </div>}

                                        </div>
                                        <div className="collapse" id="collapseExampleOne">
                                            <div className="card card-body">
                                                Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                            </div>
                                        </div>
                                        <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExampleOne" aria-expanded="false" aria-controls="collapseExampleOne">
                                            Button with data-bs-target
                                        </button>
                                        <div className='col-md-6'>
                                            <div className='personal-details-addres-box' id='personalInformation'>
                                                {creditReportData && creditReportData?.personalInformation?.addressVariations && <div className='p-3'>
                                                    <div className='row my-2'>
                                                        <div className='col-4'>
                                                            <i className="bi bi-house-door person-icon"></i>
                                                        </div>
                                                        <div className='col-8'>
                                                            <p className='report-sub-text'>Address</p>
                                                        </div>
                                                    </div>
                                                    <div className='border'></div>

                                                    {creditReportData?.personalInformation?.addressVariations && addressCreate(creditReportData.personalInformation.addressVariations)}

                                                    
                                                </div>}
                                            </div>
                                            {creditReportData && creditReportData?.personalInformation?.addressVariations && <div>
                                                {isViewAll && <div className='row mb-0 mt-3'>
                                                    <div className='col-12  row '>
                                                        <div className='text-center'>
                                                            <p className="mb-0 view-profile-info-text" onClick={() => { personalInformationAddresViewAll() }}>View All</p>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <section>
                    <FullScreenModal isOpen={isModalOpen} onClose={closeModal}>
                        <section className='px-4 py-3'>
                            {/* <p>*This process completion is mandatory.</p> */}
                            <section className='p-0 m-0 '  >

                                <array-account-enroll
                                    appKey={initOnboarArrayObj.appKey}
                                    sandbox="true"
                                    firstName={initOnboarArrayObj.firstName}
                                    lastName={initOnboarArrayObj.lastName}
                                    showQuickView={false}
                                    streetAddress="123 Main St"
                                    city="Springfield"
                                    state="TX"
                                    zipCode="78228"
                                    dobDay="01"
                                    dobYear="1990"
                                    dobMonth="01"
                                    ssn="123456789"
                                    userId={initOnboarArrayObj.userId}
                                    emailAddress={initOnboarArrayObj.email === null ? '' : initOnboarArrayObj.email}
                                    phone={initOnboarArrayObj.mobileNumber === null ? '' : initOnboarArrayObj.mobileNumber}
                                ></array-account-enroll>
                            </section>
                        </section>
                    </FullScreenModal>
                </section>

            </div >

            {/* {getRefreshTokenStatus && getRefreshTokenStatus.tokenStatus && getRefreshTokenStatus.tokenStatus === "INVALID" &&  
           <div className='container-lg container-fluid'>
                <div className='row my-2 g-0 align-items-center'>
                    <div className='col-sm-12'>
                        <div className='boxes'>
                            <div className='row g-2 align-items-start'>
                                <div className='col-sm-8 col-md-8'>
                                    <div className='row text-start align-items-center align-items-sm-start'>
                                        <div className='col-12 d-flex'>
                                            <div className='mt-1 boost-score-image-box'>
                                                <img src={Score} className='' width='45' />
                                            </div>
                                            <div className='boost-gl-section'>
                                                <p className='boost-score-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'>Get credit reports, statements and bills.</p>
                                                <p className='boost-score-sub-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'>Login with Google and Stay on top of your Credit Score</p>
                                            </div>
                                    </div>
                                    </div>
                                </div>  

                                <div className='col-sm-4 text-start col-md-4'>
                                    <div className='score-buttton-box box-score-credit-box'>
                                        <div className="abcRioButton abcRioButtonwhite gl-btn" onClick={() => { submit() }}>
                                            <div className="abcRioButtonContentWrapper">
                                                <div className="abcRioButtonIcon gl-padding">
                                                    <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18 gl-svg-btn">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                                                        </path>
                                                            <path fill="none" d="M0 0h48v48H0z"></path>
                                                        </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <span className="abcRioButtonContents gl-btn-text">
                                                    <span id="gl-btn">Login with Google</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>} */}


            {/* <BankStatment setCreditReportData={setCreditReportData} setAnalysisReportData={insightsAnalysisReports} bureauDataGenerate={bureauDataGenerate} setMessage={setMessage} scrollToTop={props.scrollToTop} /> */}
            {/* <Banking /> */}

            <section className={creditReportData === null
                || creditReportData === undefined ? `extra-space-no-cred-data-footer` : ''}>
                <Footer />
            </section>
        </>
    );
}

export default ScoreCards;