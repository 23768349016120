
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../App.css';
import '../../Quick-Books/quickbook.css'
import '../../Sign Up/loginpage/login.css'
import { setAuthHeader } from '../../../config/api';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
import { dispatchAction } from '../../../redux/actions/common_action';


import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import InputMask from 'react-input-mask'
import { QBOCallback, } from '../../../redux/actions/auth_action';

const Logo = require("../../../images/assets/Logo.png");
const CQBTN = require("../../../images/assets/connect-qb-btn.png");
const DotBlue = require("../../../images/assets/dot-blue.png");
const LoadingSpinner = require("../../../images/assets/loading_qb.gif");

function QBLoaderScreen(props) {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { user } = useSelector(state => {
        return state.authReducer;
    });
    useEffect(() => {
        QBCallbackAPi()
        // nextProcess();
    }, []);

    const nextProcess = async (token) => {

        await delay(2000);
        if (token) {
            navigate('/qb-dashboard');
        }

        //  navigate('/dashboard');
    };


    const QBCallbackAPi = () => {
        var fullUrl = window.location.href;
        console.log(fullUrl);
        QBOCallback(getUrlParams(fullUrl)).then(resp => {
            console.log(resp);
            localStorage.setItem('token', resp.token)
            localStorage.setItem('companyName', resp.companyName)
            localStorage.setItem('realmId', resp.realmId)
            const payload = {
                "statusCode": "OTP_VERIFIED",
                "statusMessage": "OTP Verified",
                "data": {
                    "access_token": resp.token,
                    "expires_in": 3600,
                    "refresh_expires_in": 7200,
                    "refresh_token": "",
                    "token_type": "Bearer"
                }
            }
            console.log("CALL " + resp.token);
            setAuthHeader(resp.token)
            dispatch(dispatchAction(SIGN_IN_SUCCESS, payload.data, false));
            console.log("CALLv 2 " + resp.token);
            nextProcess(resp.token);
        }).catch(error => {
            setLoader(false)
            // const data= { errorCode: "INCORRECT_OTP", errorMessage: "Incorrect OTP" }
            //dispatch(dispatchAction(SIGN_IN_ERROR, error.message, true));
            console.log("QBCallbackAPi EE " + error)
        });
    }
    const getUrlParams = (url) => {
        const params = new URLSearchParams(url.split('?')[1]);
        return {
            code: params.get('code'),
            realmId: params.get('realmId'),
            state: params.get('state'),
        };
    };
    return (<>

        <div style={{ marginBottom: 0 }}>
            <div className='container mt-3'>
                <div className='row g-3 mb-3 align-items-center'>
                    <div className='col-5'>
                        <a className='navbar-brand logo-images-boxes' href='/connect-quickbook'>
                            <img src={Logo} className='img-fluid' /></a>
                    </div>
                </div>
            </div>
            <div className='container mt-2 mt-md-4'>
                <div className='row main-box-border'>
                    <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                        <div className='stay-box text-uppercase'>
                            <div>
                                <h1 className='color text-uppercase'>
                                    Hang on, Boost ai is getting ready
                                </h1>

                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                        <div className='px-sm-1 row'>
                            <div className='form-box pt-2 col-md-8 offset-md-2'>
                                <h1 className=' text-start mb-md-0'>
                                    Processing
                                </h1>
                                <p className='fw-semibold mt-md-3'>
                                    Link your QuickBooks account to get Insights & Recommendations on your business
                                </p>
                                <div className='form-input-box login-box '>
                                    <form className='m-0 p-0'>

                                        <div className='m-0 p-0 form-inner-box pb-5'>
                                            <div className='row align-items-center pb-5'>
                                                <img src={LoadingSpinner} className="loading-qb" alt="tick"></img>
                                            </div>
                                            <div className='pb-5'></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer-box'>
            <Footer />
        </div>
    </>);

}

export default QBLoaderScreen;