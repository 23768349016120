import httpClient from "../../config/api";

const creditReport = (bureau) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/credit/report/get' + (bureau !== '' ? '?bureauName=' + bureau : ''),
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const boostScoreReport = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/score/get',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const scoreByUploadStatement = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/score/uploadStatement',
      data: data, headers: { "Content-Type": "multipart/form-data" }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });

const creditReportService = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/credit/report/upload',
      data: data, headers: { "Content-Type": "multipart/form-data" }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {

        reject(error.response.data);
      });
  });

const refreshToken = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/user/google/oauth/refreshToken/verify',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const reportDownload = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      responseType: 'blob',
      url: 'api/v1/boost/report/get',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const downloadExcelReport = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      responseType: 'blob',
      url: 'api/v1/boost/report/get/excel',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const createDisputeData = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/credit/report/dispute',
      data: data, headers: { "Content-Type": "application/json" }
    })
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getDispute = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/user/dispute/get',
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getFipTSPUrl = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/fiu/get/url',
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error.response.data);
    });
  });

const getFiuStatus = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/fiu/get/status',
      data: data, headers: { "Content-Type": "application/json" }
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error.response.data);
    });
  });

const initCreditRequest = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/user/credit/report/init'
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const fetchCreditReport = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/credit/report/fetch',
      data: data, headers: { "Content-Type": "application/json" }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const initiateAccount = (uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/initiate-add-account',
      headers: {
        "x-reference-id": uuid,
      }
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error);
    });
  });

const tokenExchange = (data,sessionId,uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/token/exchange',
      data: data,
      headers: {
        "Content-Type": "application/json",
        "sessionId": sessionId,
        "x-reference-id": uuid,
      }
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error);
    });
  });

const plaidStatus = (data) =>
    new Promise((resolve, reject) => {
      httpClient({
        method: 'POST',
        url: 'api/v1/get/plaid/status',
        data: data,
        headers: {
          "Content-Type": "application/json"
        }
      }).then(resp => {
        resolve(resp);
      }).catch(error => {
        reject(error);
      });
});

const checkStageStatus = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/get/user/credit/data',
      headers: { "Content-Type": "application/json" }
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });


const uploadGenDocument = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: '/api/v1/upload/file',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      },
      onUploadProgress: (progressEvent) => {
        // console.log("onUploadProgress", progressEvent);
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });


export {
  creditReport,
  boostScoreReport,
  scoreByUploadStatement,
  creditReportService,
  refreshToken,
  reportDownload,
  createDisputeData,
  getDispute,
  getFipTSPUrl,
  getFiuStatus,
  downloadExcelReport,
  initCreditRequest,
  fetchCreditReport,
  initiateAccount,
  tokenExchange,
  uploadGenDocument,
  checkStageStatus,
  plaidStatus
}
