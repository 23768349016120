import React, { useState } from 'react';
import './bank-statement.css';
import '../../../App.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { scoreByUploadStatement } from '../../../redux/actions/dashboard_action';
import Loader from '../../loader/loader';
import Q from 'q';
const PoweredBy = require("../../../images/assets/Experian_logo.png");

const BankStatmentForm = (props) => {

    const [selectedBank, setSelectedBank] = useState();
    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedAccount, setSelectedAccount] = useState('SAVING')
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [scoreError, setScoreError] = useState();
    const [showError, setShowError] = useState(false);


    const [bankList, setBankList] = useState([{
        value: 'AXIS',
        label: 'Axis Bank'
    },
    {
        value: 'HDFC',
        label: 'HDFC Bank'
    },
    {
        value: 'ICICI',
        label: 'ICICI Bank'
    },
    {
        value: 'SBI',
        label: 'State Bank of India'
    },
    {
        value: 'KOTAK',
        label: 'Kotak Bank'
    }])

    const onBanckChange = (value) => {
        setSelectedBank(value.value)
    }

    const backFiuErrorScreen = () => {
        props.setFiuErrorScreen(true);
        props.setFiuErrorModel(true);
        props.setIsFiuOpenDialog(false);
        props.setIsOpenDialog(true);
        props.setFipTspModel(false);
    }

    const closePopup = () => {
        props.setFiuErrorModel(false);
        props.setIsOpenDialog(false);
        props.setFipTspModel(false);
    }
    

    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const sumbitBankStatement = () => {
        setShowError(false)
        if (!selectedBank || selectedBank === '' || !selectedFiles || selectedFiles === '') {
            setShowError(true)
        } else {
            var param = new FormData();
            param.append("files", selectedFiles[0]);
            param.append("bankName", selectedBank);
            param.append("accountType", selectedAccount);
            setLoader(true)
            scoreByUploadStatement(param).then(resp => {
                setLoader(false);
                setSelectedBank(undefined);
                setSelectedFiles(undefined);
                props.setDialogTitle('Your boost Score')
                props.setDialogHeight('360px')
                setScore(resp["score"])
                setScoreError(undefined)
                props.setScore(resp["score"]) 
                props.setAnalysisReportData(resp?.analysis ? resp.analysis : [])
                props.setIncomeVsExpenseData(resp?.analysis?.incomeVsExpenseAnalysis  ? resp.analysis.incomeVsExpenseAnalysis : [])
                props.setTopSpendingCategoryData(resp?.analysis?.categoryAnalysis ?  resp.analysis.categoryAnalysis : [])
                props.setTopMerchantData(resp?.analysis?.merchantAnalysis ? resp.analysis.merchantAnalysis :[])
                props.setTopCardUtilizationData(resp.analysis.creditCardUtilization)
                props.setMonthlyObligationData(resp.analysis.obligation)
                props.setInvestmentData(resp.analysis.investment)
                props.setMessage({ success: resp.statusMessage })
                props.setIsOpenDialog(false)
                props.closeDialog()
                props.scrollToTop()
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                window.dispatchEvent(new Event('resize'))
            }).catch((error) => {
                setScoreError(error?.statusMessage)
                setLoader(false);
            })
        }
    }

    return (
        <>
            {loader && <Loader />}
            <div className='col-12'>
                <span id="boot-icon" className="bi bi-arrow-left"  onClick={() => {
                    backFiuErrorScreen()
                }}></span>
                <span id="close-icon" className='bi bi-x' onClick={() => {
                    closePopup()
                }}></span>
            </div>
            <div className='container'>
                {score < 0 && <form className="row g-2 needs-validation" noValidate>
                    <div className='col-12'>
                        <p className='mb-0 text-center upload-title-txt'>Upload Bank Statement</p>
                    </div>
            
                    <div className="col-12">
                        <label htmlFor="validationCustom04" className="label-label mb-2">Bank</label>
                        <Dropdown options={bankList} onChange={(value) => {
                            onBanckChange(value)
                        }} value={selectedBank} placeholder="Select your bank" />
                        {(showError && (!selectedBank || selectedBank === '')) && <span className="demo-tetx">Please select your bank</span>}
                    </div>
                    <div className="col-12 my-3">
                        <label className="label-label mb-2">Upload File</label>
                        <input type='file' className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please select file</span>}
                        {scoreError && <span className="demo-tetx">{scoreError}</span>}
                    </div>
                    <div className="col-12 text-center mt-3">
                        <button className="upload-statement-butoon" type="button" onClick={() => {
                            sumbitBankStatement()
                        }}>Submit</button>
                    </div>
                    <div className='col-12 text-center my-3 align-items-center justify-content-center'>
                        <p className='poweredby-text mb-0'>Powered by</p>
                        <img src={PoweredBy} width='120' />
                    </div>
                </form>}
            </div>
        </>
    )
}

export default BankStatmentForm;