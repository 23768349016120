import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../redux/actions/auth_action";
import './sidebar.css';

const Sidebar = () => {
    const [showNav, setShowNav] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentRoute, setCurrentRoute] = useState('/');
    const [showCredit, setShowCredit] = useState(false);
    const [showBoost, setShowBoost] = useState(false);
    const [showQBGenAI, setQBGenAI] = useState(false);
    const [showIntegration, setIntegration] = useState(false);
    const logout = async () => {
        var result = await dispatch(logOut());
        if (result) {
            navigate('/login')
        }
    }

    const ScoreBoost = () => {
        navigate('/boostscore')
    }

    const ScoreCredit = () => {
        navigate('/dashboard')
    }

    const Dispute = () => {
        navigate('/disputelist')
    }

    const QBIntegrationScreen = () => {
        navigate('/qb-integration')
    }

    const QBGenAI = () => {
        navigate('/qb-dashboard')
    }

    useEffect(() => {
        setCurrentRoute(window.location.pathname);
        setShowBoost(false);
        setShowCredit(false);
        setIntegration(false);
        setQBGenAI(false);
        const isShowBoost = window.location.pathname === '/boostscore';
        if (isShowBoost) {
            setShowBoost(true)
        }
        const isShowCredit = window.location.pathname === '/dashboard';
        if (isShowCredit) {
            setShowCredit(true)
        }

        const showIntegrationGenAI = window.location.pathname === '/qb-integration';
        if (showIntegration) {
            setIntegration(true)
        }
        const showQBGenAI = window.location.pathname === '/qb-dashboard';
        if (showQBGenAI) {
            setQBGenAI(true)
        }
    }, [])

    return (
        <div className={`l-navbar${showNav ? ' shows' : ''}`}>
            <nav className="nav navbox">
                <div>
                    <div className='icon-text-box'>
                        <div className='d-flex justify-content-between'>
                            {/* <div className='left-arow-icon'>
                                {!showNav && <i className={`bi bi-list`}
                                    onClick={() => setShowNav(!showNav)} />}
                            </div>
                            <div className='menu-icon'>
                                {showNav && <i className={`bi bi-arrow-left`}
                                    onClick={() => {
                                        setShowNav(!showNav)
                                        var sidebarCollapse = document.getElementById('sliderCollapse');
                                        sidebarCollapse.classList.add("collapsed");
                                        var sidebarSubMenuList = document.getElementById('collapseExampleSidebar');
                                        sidebarSubMenuList.classList.remove('show')
                                    }} />}
                            </div> */}
                        </div>
                    </div>
                    <div className="nav_list">
                        <ul>
                            <li>
                                <a className={currentRoute === "/dashboard" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { ScoreCredit() }} id="sliderCollapse" data-bs-toggle="collapse" href="#collapseExampleSidebar" role="button" aria-controls="collapseExampleSidebar">
                                    <i className="bi bi-file-bar-graph nav_icon"></i><span className="nav_name">Credit Report <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                </a>
                                <div className={`collapse ${showCredit ? 'show' : ''}`} id="collapseExampleSidebar">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#creditscore">Credit Score</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#insights">Insights</a>
                                            </li>
                                            <li>
                                                <a className="nav_links" href="#summatyaccount">Account Summary</a>
                                            </li>
                                            {/* <li>
                                                <a className="nav_links" href="#accountinfo">Upload Credit Report</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className={currentRoute === "/boostscore" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { ScoreBoost() }} data-bs-toggle="collapse" href="#collapseExampleTwo" role="button" aria-expanded="false" aria-controls="collapseExampleTwo">
                                    <i className="bi bi-lightning-charge nav_icon"></i><span className="nav_name">Boost Score &nbsp;<i className="bi bi-chevron-down nav_icon nav-icons ps-2"></i></span>
                                </a>
                                <div className={`collapse ${showBoost ? 'show' : ''}`} id="collapseExampleTwo">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className={window.location.hash === "#boostscore" ?
                                                    "nav_links active" : "nav_links"} href="#boostscore">Boost score</a>
                                            </li>
                                            <li>
                                                <a className={window.location.hash === "#insights" ?
                                                    "nav_links active" : "nav_links"} href="#insights">Insights</a>
                                            </li>
                                            <li>
                                                <a className={window.location.hash === "#analysis" ?
                                                    "nav_links active" : "nav_links"} href="#analysis">Analysis</a>
                                            </li>
                                            <li>
                                                <a className={window.location.hash === "#bankaccount" ?
                                                    "nav_links active" : "nav_links"} href="#bankaccount">Add bank account</a>
                                            </li>
                                            {/* <li>
                                                <a className={window.location.hash === "#uploadbankstatement" ?
                                                    "nav_links active" : "nav_links"} href="#uploadbankstatement">Add bank statement</a>
                                            </li> */}

                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className={currentRoute === "/disputelist" ? "nav_link active" : "nav_link"} onClick={() => { Dispute() }} data-bs-toggle="collapse" href="#collapseExampleThree" role="button" aria-controls="collapseExampleThree">
                                    <i className="bi bi-shield-exclamation nav_icon"></i><span className="nav_name">Credit Report Dispute</span>
                                    <div className="collapse" id="collapseExampleThree">
                                        <div className="card card-body">
                                        </div>
                                    </div>
                                    {/* <br />
                                    <span className="cooming-soon-text">(Coming soon)</span> */}
                                </a>
                            </li>
                            <li>
                                <a className={currentRoute === "/qb-dashboard" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { QBGenAI() }} id="sliderCollapse" data-bs-toggle="collapse" href="#collapseExampleFour" role="button" aria-controls="collapseExampleFour">
                                    <i className="bi bi-file-bar-graph nav_icon"></i><span className="nav_name">Generative AI <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                </a>
                                <div className={`collapse ${showQBGenAI ? 'show' : ''}`} id="collapseExampleFour">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#qb-chat">Boost AI</a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className={currentRoute === "/qb-integration" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { QBIntegrationScreen() }} data-bs-toggle="collapse" href="#collapseExampleFive" role="button" aria-expanded="false" aria-controls="collapseExampleFive">
                                    <i className="bi bi-lightning-charge nav_icon"></i><span className="nav_name">Intuit QuickBooks</span>
                                </a>
                                <div className={`collapse `} id="collapseExampleFive">
                                    <div className="card card-body">
                                        {/* <ul className="ms-5">
                                            <li>
                                                <a className={window.location.hash === "#intuitQB" ?
                                                    "nav_links active" : "nav_links"} href="#intuitQB">Intuit QuickBooks</a>
                                            </li>

                                        </ul> */}
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <a className="nav_link" rel="noopener">
                                    <i className="bi bi-people nav_icon"></i><span className="nav_name">Money Boosters</span>
                                    <br />
                                    <span className="cooming-soon-text">(Coming soon)</span>
                                </a>
                            </li>
                            <li>
                                <a className="nav_link" rel="noopener">
                                    <i className="bi bi-credit-card nav_icon"></i><span className="nav_name">Boost Credit Card</span>
                                    <br />
                                    <span className="cooming-soon-text">(Coming soon)</span>
                                </a>
                            </li>
                            <li>
                                <a className="nav_link" rel="noopener">
                                    <i className="bi bi-bank nav_icon"></i><span className="nav_name">Boost Savings account</span>
                                    <br />
                                    <span className="cooming-soon-text">(Coming soon)</span>
                                </a>
                            </li>
                            <li>
                                <a className="nav_link" rel="noopener">
                                    <i className="bi bi-gear nav_icon"></i><span className="nav_name">Account settings</span>
                                    <br />
                                    <span className="cooming-soon-text">(Coming soon)</span>
                                </a>
                            </li> */}
                            {/* <li>
                                <a className={currentRoute === "/login" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { logout() }}>
                                    <i className="bi bi-box-arrow-right nav_icon"></i><span className="nav_name" >Logout</span>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
export default Sidebar;