const getControlWidth = () => {
    const pageWidth = window.innerWidth;
    return pageWidth > 576 ? '100px' : '120px';
};



export const customStyles = {

    control: (base, state) => ({
        ...base,
        height: '30px',
        border: '1.5px solid #4243f1', // Add a border
        borderRadius: '8px',
        padding: '0px 0px',
        fontSize: '12px',
        cursor: 'pointer',
        boxShadow: '0 2px 12px rgba(0, 0, 0, .1)',
        fontFamily: 'Poppins',
        fontWeight: '400'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),

    menu: (provided) => ({
        ...provided,
        width: getControlWidth(),
        borderRadius: '12px',
        padding: '0px 0px',
        cursor: 'pointer',

    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: '0px',
        borderRadius: '12px',
        fontSize: '14px'

    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'black',
        backgroundColor: state.isSelected ? '#EDEBFD' : 'white',
        borderBottom: state.isSelected ? 'none' : '1px solid #D1D1D1',
        padding: '10px 10px',
        cursor: 'pointer',
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontFamily: 'Poppins',
            fontWeight: '400',
            color: '#1c1c1c', // Set the color of the placeholder text
            fontSize: '1em', // Adjust the font size as needed
            // Add other styles here
        };
    },


};