import React, { useState, useEffect } from 'react';
import './dispute.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Dashboard/SideBar/sidebar';
import MenuBar from '../Dashboard/Menubar/menubar';
import Loader from '../loader/loader'
import { getDispute } from '../../redux/actions/dashboard_action';
import { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


const DisputeHistory = (props) => {

    const GoBackImg = require("../../images/assets/backgo.png");
    const AxisBank = require("../../images/Bank/Bank.png");
    const [disputeHistory, setdisputeHistory] = useState();
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();
    const GoBack = () => {
        navigate('/disputelist')
    }

    useEffect(() => {
        setLoader(true)
        getDispute().then(resp => {
            console.log(resp.data);
            setdisputeHistory(resp.data)
            setLoader(false)
        }).catch(error => {
            console.log(error)
            setLoader(false)
        })

    }, []);

    const DisputeDetails = (disputesData) => {
        navigate('/disputedetails',{ state: { "disputeData" : disputesData } })
    }

    const accountDetails = (disputesData) => {
        var disputes = [];
        for (let index = 0; index < disputesData.length; index++) {
            disputes.push(<div className="accordion-item dispute-accordion my-3" key={index + "Dispute"} >
                    <div className="accordion-header accordion-button align-items-start pb-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="false" aria-controls={"collapseOne"}>
                        <div className='row w-100 align-items-start g-2 g-sm-0'>
                            <div className='col-12 col-sm-3'>
                                <div className='d-flex align-items-start'>
                                    <div className='bank-image-box'>
                                        <img src={AxisBank} width={100}></img>
                                    </div>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>BANK</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className='reported-date'>{disputesData[index].data.lender ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-3'>
                                <div className='d-flex align-items-start'>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>Dispute Id</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className='reported-date'>{disputesData[index].disputeId ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-2'>
                                <div className='d-flex align-items-start'>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>Account Number</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className='reported-date'>{disputesData[index].data.accountNumber ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-2'>
                                <div className='d-flex align-items-start'>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>STATUS</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className={"dis-account-type account-active" }>{disputesData[index].disputeStatus ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-2'>
                                <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
                                    <button className='raise_dispute_btn'  onClick={() => { DisputeDetails(disputesData[index]) }}>DETAILS</button>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div> )
        }
            return disputes;  
        }

    return (
        <>  
            {loader && <Loader />}
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='disputeDetails' className="sidebat-inner-box sidnav-inner-box">
                    <div className='container-lg container-fluid my-3'>
                        <div className="go-back" onClick={() => { GoBack() }}><img src={GoBackImg} /> Go Back</div>
                        <div className='disptue-section mt-4'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h1 className='dispute-title'>Dispute history</h1>
                                </div>
                            </div>
                            {disputeHistory && disputeHistory.disputes &&
                            <div className="accordion-body">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='account-accordion-box'>
                                                    <div className="accordion" id="accordionFlushExample">
                                                        {disputeHistory && disputeHistory.disputes && accountDetails(disputeHistory.disputes)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </div>}

                            {disputeHistory && disputeHistory.disputes == "" && 
                                <div className='text-center boosted-score-text pt-5'><p>No Dispute History Found</p></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DisputeHistory;