import React, { useState, useEffect } from 'react'
import './boost-score-bank.css'
import '../../../App.css'
import BankStatmentForm from '../bank-statement/bank-statement'
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-js-dialog-box/dist/index.css'

const AddBank = require("../../../images/assets/AddBank.png");
const GetInsights = require("../../../images/assets/Getinsights.png");
const Finances = require("../../../images/assets/Finances.png");

const Boostscorebank = (props) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Upload Bank Statement')
    const [dialogHeight, setDialogHeight] = useState('auto');

    const openDialog = () => {
        setIsOpenDialog(true)
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setDialogTitle('Upload Bank Statement')
        setDialogHeight('auto')
        props.scrollToTop()
    }

    return (
        <>
            <div className='mb-4'>
                <div className='container-lg container-fluid my-md-4'>
                    <div className='bank-state-box'>
                        <div className='row'>
                            <div className='co-12 text-center '>
                                <h1 className='boosted-score-text'>Get A boosted credit score</h1>
                                <p className='boosted-moneytetx'>Share the bank statement and  get insights and a boosted score</p>
                            </div>
                        </div>

                        <div className='row mt-3 g-2 g-sm-4 text-center'>
                            <div className='col-sm-12 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>01.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={AddBank} className='img-fluid ' />
                                        </div>
                                        <p className='get-score-images-text text-break '>Upload your bank statements </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>02.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={GetInsights} className='img-fluid ' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Get insights and recommendations    </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>03.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={Finances} className='img-fluid ' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Boost your credit score and finances  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 g-3'>
                            <div className='col-12 text-center my-3'>
                                <button className='mb-0 upload-statement-btn' onClick={() => { openDialog() }}>UPLOAD BANK STATEMENT</button>
                            </div>
                            
                            {isOpenDialog && (
                                <ReactDialogBox
                                    closeBox={closeDialog}
                                    headerBackgroundColor='white'
                                    headerTextColor='black'
                                    headerHeight='65px'
                                    closeButtonColor='black'
                                    bodyBackgroundColor='white'
                                    bodyTextColor='black'
                                    bodyHeight={dialogHeight}
                                    headerText={dialogTitle}
                                    borderRadius='8px'
                                >
                                <BankStatmentForm
                                    closeDialog={closeDialog}
                                    setScore={props.setScore}
                                    setDialogTitle={setDialogTitle}
                                    setDialogHeight={setDialogHeight}
                                    setMessage={props.setMessage}
                                    setAnalysisReportData={props.setAnalysisReportData}
                                    setIncomeVsExpenseData={props.setIncomeVsExpenseData}
                                    setTopSpendingCategoryData={props.setTopSpendingCategoryData}
                                    setTopMerchantData={props.setTopMerchantData}
                                    setTopCardUtilizationData={props.setTopCardUtilizationData}
                                    setMonthlyObligationData={props.setMonthlyObligationData}
                                    setInvestmentData={props.setInvestmentData}
                                />
                                </ReactDialogBox>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Boostscorebank;