import '../../../App.css'

import '../../Dashboard/Header-Page/Scroe-card.css';
import { useEffect, useState, useRef } from 'react';
import { ReactDialogBox } from 'react-js-dialog-box'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../loader/loader';
import AIInfo from '../../Boost-AI/AI-Info/ai-info';
import ChatAI from '../../Boost-AI/Chat/chatai';
import Footer from '../../landing-page/Footer/Footer';
const BMLOGO = require("../../../images/assets/ai-profile.png");
const AILOGOGIF = require("../../../images/boost-ai.gif");

const INFO_IC = require("../../../images/assets/info.png");
const CHAT_IC = require("../../../images/assets/msg_ic.png");
const PRIVACY_IC = require("../../../images/assets/privacy.png");

function QBChat(props) {

    const [showChatIcon, setShowChatIcon] = useState(false)  // Boost AI Icon
    const [showChatAIInfoBox, setShowChatAIInfoBox] = useState(false) // Manage Screen AI Info OR Chat AI

    const [showAIInfoBox, setShowAIInfoBox] = useState(false) // Manage Info Screen
    const [showGenAI, setShowGenAI] = useState(false) // Manage Gen AI Screen

    const [dialogHeight, setDialogHeight] = useState('auto');
    const [dialogTitle, setDialogTitle] = useState('BOOST AI')

    const [chatUrl, setChatUrl] = useState('https://genai-us-uat.boost.money/api/v1/bot/chat')


    useEffect(() => {
        setShowGenAI(false)
    }, [])

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    // const openChatAIInfo = () => {
    //     localStorage.removeItem('messagesAI');
    //     setShowGenAI(true)
    // }


    const openChatAIInfo = () => {
        setShowChatAIInfoBox(true)
        setShowAIInfoBox(true)
        setShowChatIcon(false)
    }

    const closeInfoDialog = () => {
        setShowChatAIInfoBox(false)
        setShowChatIcon(true);

        setShowAIInfoBox(false)
        setShowGenAI(false)

        setDialogTitle('BOOST AI')
        setDialogHeight('auto')
    }


    const navigate = useNavigate();

    const ref = useRef(null);
    const [loader, setLoader] = useState(false);

    const AIInfoComponent = () => {
        return (<section className='px-3 pb-5'>
            <div className="accordion" id="accordionPanelsStayTwoExample">
                <div className="accordion-item">
                    <h2 className="accordion-header mb-0">
                        <div className='d-flex flex-row justify-content-between py-1'>
                            <div id='personalInformationCollapseButton'
                                className={`accordion-button acc-info-text m-0 `} type="button" data-bs-target="#panelsStayOpen-collapseTwo" aria-controls="panelsStayOpen-collapseTwo"
                            >
                                BOOST AI (BETA)
                            </div>

                        </div>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className={`accordion-collapse collapse show`} data-bs-parent="#accordionPanelsStayTwoExample">
                        <AIInfo setShowChatAIInfoBox={setShowChatAIInfoBox}
                            setChatUrl={setChatUrl}
                         setShowChatIcon={setShowChatIcon} setShowAIInfoBox={setShowAIInfoBox}
                          setShowGenAI={setShowGenAI}></AIInfo>
                    </div>
                </div>
            </div>
        </section>);
    };

    return (
        <>
            {loader && <Loader />}
            <div className='mt-4 mt-sm-2'>

                <section className='' id='qb-chat'>

                    <div className='container-lg container-fluid mt-3 ps-lg-3'>
                        <h1 className='header-title mb-2 mb-sm-3'>Generative AI</h1>
                    </div>
                    <AIInfoComponent></AIInfoComponent>

                    {/* <AIInfo setShowChatAIInfoBox={setShowChatAIInfoBox} setShowChatIcon={setShowChatIcon} setShowAIInfoBox={setShowAIInfoBox} setShowGenAI={setShowGenAI}></AIInfo> */}
                    {showGenAI && <div className='chat-dialog'>
                        <ReactDialogBox
                            headerBackgroundColor='white'
                            headerTextColor='black'
                            closeButtonColor='black'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight={dialogHeight}
                            borderRadius='8px'
                            headerHeight='65px'
                            headerText="BOOST AI"
                            closeBox={closeInfoDialog}>

                            <ChatAI closeInfoDialog={closeInfoDialog} chatUrl={chatUrl}> </ChatAI>
                        </ReactDialogBox> </div>}

                </section>

            </div >
            {/* <Footer></Footer> */}


        </>
    );
}

export default QBChat;