import './landing-page.css';
import '../../App.css'
import Slider from "./Slider/Slider";
import Header from "./header/header";
import Testimonial from "./Testimonial/Testimonial";
import Invitation from "./Invitation/Invitation";
import LandingFooter from './Footer/LandingFooter.js';

function LandingPage() {
    return (
        <>
            <Header />
            <Slider />
            {/* <Testimonial />
            <Invitation /> */}
            {/* <Invitation /> */}
            <LandingFooter />
        </>
    );
}

export default LandingPage;