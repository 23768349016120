// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-success-main-box {
    background: #FFFFFF !important;
    border: 1px solid #00B112 !important;
    border-radius: 12px !important;
    border-left: 12px solid #00B112 !important;
}

.error-mess-text-box,
.close-circle-box {
    padding: 10px 15px;
    vertical-align: middle;
}

.success-mess-close-box {
    border: none;
    background-color: transparent;
    border-left: 1px solid #00B112 !important;
}

.error-mess-close-box {
    border: none;
    background-color: transparent;
    border-left: 1px solid #F30505;
}

.exclamations-icons {
    font-size: 28px !important;
    color: #F30505 !important;
}

.success-exclamations-icons {
    font-size: 28px !important;
    color: #00B112 !important;
}

.circle-box-icon {
    font-size: 30px !important;
    color: #000000 !important;
}

.error-message-text-email {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}

.alert {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
}

.circle-close-box {
    background-color: transparent;
    border: none;
    font-size: 22px;
    color: #000000;
    margin-left: 5px;
}

@media (max-width:767px) {
    .error-message-text-email {
        font-size: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/error-message/error-message.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,oCAAoC;IACpC,8BAA8B;IAC9B,0CAA0C;AAC9C;;AAEA;;IAEI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,iCAAiC;AACrC;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".show-success-main-box {\n    background: #FFFFFF !important;\n    border: 1px solid #00B112 !important;\n    border-radius: 12px !important;\n    border-left: 12px solid #00B112 !important;\n}\n\n.error-mess-text-box,\n.close-circle-box {\n    padding: 10px 15px;\n    vertical-align: middle;\n}\n\n.success-mess-close-box {\n    border: none;\n    background-color: transparent;\n    border-left: 1px solid #00B112 !important;\n}\n\n.error-mess-close-box {\n    border: none;\n    background-color: transparent;\n    border-left: 1px solid #F30505;\n}\n\n.exclamations-icons {\n    font-size: 28px !important;\n    color: #F30505 !important;\n}\n\n.success-exclamations-icons {\n    font-size: 28px !important;\n    color: #00B112 !important;\n}\n\n.circle-box-icon {\n    font-size: 30px !important;\n    color: #000000 !important;\n}\n\n.error-message-text-email {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 18px;\n    color: #000000;\n}\n\n.alert {\n    padding-top: 0.1rem !important;\n    padding-bottom: 0.1rem !important;\n}\n\n.circle-close-box {\n    background-color: transparent;\n    border: none;\n    font-size: 22px;\n    color: #000000;\n    margin-left: 5px;\n}\n\n@media (max-width:767px) {\n    .error-message-text-email {\n        font-size: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
