
import { useEffect, useState } from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { loginAction, connectQBO } from '../../redux/actions/auth_action';
import '../../App.css';
import '../Quick-Books/quickbook.css'
import '../Sign Up/loginpage/login.css'
import { useSelector } from 'react-redux';
import Loader from '../loader/loader';
import Footer from '../landing-page/Footer/Footer'
import InputMask from 'react-input-mask'

const Logo = require("../../images/assets/Logo.png");
const CQBTN = require("../../images/assets/connect-qb-btn.png");
const DotBlue = require("../../images/assets/dot-blue.png");

function ConntectQuickBook(props) {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const connectQBNext = () => {
        navigate('/qb-loader-screen')
    }

    const connectQBAPi = () => {
        setLoader(true)
        connectQBO().then(resp => {
            console.log("connectQBAPi " + resp)
            window.open(resp, "_blank")
            setLoader(false)

        }).catch(error => {
            console.log("connectQBAPi EE " + error)
            setLoader(false)

        });
    }

    return (<>
        {loader && <Loader />}
        <div style={{ marginBottom: 0 }}>
            <div className='container mt-3'>
                <div className='row g-3 mb-3 align-items-center'>
                    <div className='col-5'>
                        <a className='navbar-brand logo-images-boxes' href='/connect-quickbook'>
                            <img src={Logo} className='img-fluid' /></a>
                    </div>
                </div>
            </div>
            <div className='container mt-2 mt-md-4'>
                <div className='row main-box-border'>
                    <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                        <div className='stay-box text-uppercase'>
                            <div>
                                <h1 className='color text-uppercase'>
                                    Get started with boost.Money
                                </h1>

                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                        <div className='px-sm-1 row'>
                            <div className='form-box pt-2 col-md-8 offset-md-2'>
                                <h1 className=' text-start mb-md-0'>
                                    Connect to QuickBooks
                                </h1>
                                <p className='fw-semibold mt-md-3'>
                                    Link your QuickBooks account to get Insights & Recommendations on your business
                                </p>
                                <div className='form-input-box login-box '>
                                    <form className='m-0 p-0'>
                                        <div className=' m-0 p-0 form-inner-box mb-md-2  '>
                                            <ul className='cq-ul'>
                                                <li className='cq-li'><span><img src={DotBlue} className='dot-blue-ic' /></span>Get Business Transactions</li>
                                                <li className='cq-li pt-2'><span><img src={DotBlue} className='dot-blue-ic' /></span>Monitor your Cashflows</li>
                                                <li className='cq-li pt-2'><span><img src={DotBlue} className='dot-blue-ic' /></span>Track your Invoices</li>
                                                <li className='cq-li pt-2'><span><img src={DotBlue} className='dot-blue-ic' /></span>Talk to your data through Boost AI</li>
                                            </ul>
                                        </div>
                                        <div className='m-0 p-0 form-inner-box pt-5'>
                                            <div className='row g-2 align-items-center pb-4'>
                                                <img src={CQBTN} alt='cq-btn'
                                                    onClick={connectQBAPi}
                                                    className='img-connect-qb' />

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer-box'>
            <Footer />
        </div>
    </>);

}

export default ConntectQuickBook;