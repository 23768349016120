import { useEffect } from 'react';

function UseScriptCode(src) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => console.log(`${src} loaded successfully`);

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src]);
}

export default UseScriptCode;