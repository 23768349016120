import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../boost-score-bank/boost-score-bank.css'
import './add-bank-account.css'
import '../../../App.css'
import 'react-js-dialog-box/dist/index.css'

const FiuSuccess = require("../../../images/assets/fiu_success.png");

const Fiusuccessscreen = (props) => {

    const [loader, setLoader] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        }
    }, [])

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const backToHome = () => {
        props.setFiuSuccessModel(false);
        props.setFipTspModel(false);
        props.setIsOpenDialog(false);
        props.scrollToTop();
    }

    return (
        <>
            <div className='mb-4 fiu-box'>
                <div className='container-lg container-fluid my-md-4 fiu-response'>
                    <div className='row'>
                        <div className='col-12 text-center my-3'>
                            <img src={FiuSuccess} alt='fiu_error' className='fiu_error_success_img' />
                            <div className="fiu_success_text pt-2">Link Successful</div>
                        </div>

                        <div className='col-12 text-center my-3'>
                            <div className='no_ac_box p-3'>
                                <div className='no_ac_text'>Congratulations!!!</div>
                                <span className='no_ac_sub_txt'>We’ve successfully raised a request to link your bank account with Boost.Money</span>
                            </div>
                        </div>

                        <div className='col-12 text-center my-3 pt-50 mb-0'>
                            <button className='mb-0 upload-statement-btn' onClick={() => { backToHome() }}>Back to home</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fiusuccessscreen;