import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginAction } from '../../../redux/actions/auth_action';
import '../../../App.css';
import './login.css'
import { useSelector } from 'react-redux';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import InputMask from 'react-input-mask'

const Logo = require("../../../images/assets/Logo.png");

function Login() {
    const [mobileNumber, setMobileNumber] = useState("")
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [email, setEmail] = useState('');
    const { user } = useSelector(state => {
        return state.authReducer;
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.access_token) {
            navigate('/dashboard');
        }
    }, [])

    const validateMobileNo = (mobileNo) => {
        setMobileNumber(mobileNo = mobileNo.replace(/[^0-9-]/g, ''))
        if (mobileNo) {
            var usMobile = mobileNo.split('-').join('');
            setMobileNumber(usMobile);
        }
    }

    const setEmailAddress = (email) => {
        setEmail(email);
    }

    function validateEmail(email) {
        // Basic pattern to check if email is not empty and follows basic email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email !== '' && emailPattern.test(email);
    }

    const login = () => {
        setUserExists('')
        setShowError(false)

        if (email === undefined || email === '' || !validateEmail(email)) {
            setShowError(true)
        } else {
            const param = {
                // "mobileNumber": mobileNumber
                "email": email
            };
            setLoader(true)
            loginAction(param).then(resp => {
                setLoader(false)
                console.log("login resp ", resp)
                navigate('/otp', {
                    state: {
                        mobileNumber: mobileNumber,
                        otpReferenceId: resp.otpReferenceId,
                        emailId: email, from: 'login'
                    }
                });
            }).catch(error => {
                console.log(error);
                setLoader(false)
                setUserExists(error?.response.data.errorMessage);
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // console.log('clicked');
            login()
        }
    };
    const btnEnableDisable = {
        opacity: (!validateEmail(email) || email == '') ? 0.5 : 1.0,
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mb-md-0'>
                                        Login To Your Account
                                    </h1>
                                    <p className='fw-semibold mt-md-3'>
                                        Enter Email Address
                                    </p>
                                    <div className='form-input-box login-box'>
                                        <form>

                                            <div className='form-inner-box mb-md-5'>

                                                <div className='mb-1 mb-md-5'>

                                                    <div className=''>
                                                        <label className='label-label'>Email Address </label>
                                                    </div>
                                                    <div>
                                                        <input type="email" id='email' className='input-box form-control' value={email} onChange={(e) => { setEmailAddress(e.target.value) }} onKeyDown={handleKeyDown} />
                                                        {(showError && email === '') && <span className="demo-tetx">Please enter email address</span>}
                                                    </div>
                                                    {email !== '' && !validateEmail(email) && <span className="demo-tetx"> Please enter valid email address</span>}
                                                    {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                                </div>
                                            </div>


                                            {/* <div className='form-inner-box mb-md-5'>
                                                <div className='mb-1 mb-md-2'>
                                                    <label className='label-label'>Mobile Number </label>
                                                </div>
                                                <div className='mb-1 mb-md-5'>

                                                    <InputMask
                                                        type="tel"
                                                        mask="999-999-9999"
                                                        maskChar=''
                                                        className='input-box form-control'
                                                        value={mobileNumber}
                                                        onChange={(e) => {
                                                            validateMobileNo(e.target.value)
                                                        }}
                                                        onKeyUp={(e) => {
                                                            handleKeyDown(e)
                                                        }}
                                                        autoComplete='off'
                                                        placeholder='123-456-7891'
                                                    ></InputMask>

                                                    {/* <input type="tel" minLength="10" maxLength="10" autoComplete='off' className='input-box form-control' placeholder='555-555-5555' pattern="\d{3}-\d{3}-\d{4}"
                                                        value={mobileNumber}
                                                        onChange={(e) => {
                                                            setMobileNumber(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                    /> */}
                                            {/* {(showError && (mobileNumber === undefined || mobileNumber === '')) && <span className="demo-tetx">Please enter your mobile number</span>}
                                            {(showError && (mobileNumber.length !== 10 && mobileNumber !== '')) && <span className="demo-tetx">Please enter valid mobile number</span>}
                                            {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>} */}
                                            {/* </div>
                                            </div> */}




                                            <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-12 col-md-6 order-2 order-md-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span>
                                                                <span className='new-user-text'>New user? </span>
                                                                <Link className='new-user-sign-up-text' to={'/signup'}>
                                                                    <span> Sign Up</span>
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 order-1 order-md-2 text-end'>
                                                        <div className='text-end my-2 my-sm-0'>
                                                            <button type='button' className='next-button'
                                                                style={btnEnableDisable}
                                                                onClick={() => {
                                                                    login();
                                                                }}>Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer isChatIconShow={false} />
            </div>
        </>
    );
}

export default Login;