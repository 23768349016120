import React, { useState } from 'react';
import './credit-report.css'
import '../../../App.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Loader from '../../loader/loader';
import { creditReportService } from '../../../redux/actions/dashboard_action';
import showPwdImg from '../../../images/assets/show-password.png';
import hidePwdImg from '../../../images/assets/hide-password.png';

const CreditReport = (props) => {

    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedBureau, setSelectedBureau] = useState();
    const [bureauError, setBureauError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [showError, setShowError] = useState(false);

    const [creditBureau, setCreditBureau] = useState([{
        label: 'Experian',
        value: 'EXPERIAN'
    },
    {
        label: 'TransUnion - CIBIL',
        value: 'CIBIL'
    },
    {
        label: 'CRIF',
        value: 'CRIF'
    },
    {
        label: 'Equifax',
        value: 'EQUIFAX'
    }]);

    const onBureauChange = (value) => {
        setSelectedBureau(value.value)
    }

    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const sumbitCreditBureau = () => {
        setShowError(false)
        if (!selectedBureau || selectedBureau === '' || !selectedFiles || selectedFiles === '') {
            setShowError(true)
        } else {
            var param = new FormData();
            param.append("file", selectedFiles[0]);
            param.append("bureauName", selectedBureau);
            setLoader(true)
            creditReportService(param).then(resp => {
                props.setCreditReportData(resp.data)
                props.bureauDataGenerate(resp?.bureauName ?? '', resp?.availableOtherBureauData ?? [])
                props.setAnalysisReportData(resp?.analysis ?? [])
                setLoader(false);
                setSelectedBureau(undefined);
                setSelectedFiles(undefined);
                if (resp.data && resp.data != null) {
                    props.setDialogTitle('Your Credit Score')
                    props.setDialogHeight('390px')
                    setScore(resp["data"]["score"]["score"])
                    setBureauError(undefined)
                    props.closeDialog()
                    props.setMessage({ success: resp.statusMessage })
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }
            }).catch((error) => {
                setBureauError(error?.errorMessage)
                setLoader(false);
            })
        }
    }

    return (
        <>
            {loader && <Loader />}
            <div className='container'>
                {score < 0 && <form className="row g-2 needs-validation" noValidate>
                    <div className="col-12">
                        <label htmlFor="validationCustom04" className="label-label mb-2">Credit Bureau</label>
                        <Dropdown options={creditBureau} onChange={(value) => {
                            onBureauChange(value)
                        }} value={selectedBureau} placeholder="Select credit bureau" />
                        {(showError && (!selectedBureau || selectedBureau === '')) && <span className="demo-tetx">Please select your credit bureau</span>}
                    </div>
                    <div className="col-12 my-3">
                        <label className="label-label mb-2">Upload File</label>
                        <input type='file' className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please upload file</span>}
                    </div>
                    <div className='col-12 '>
                        <label className='label-label mb-2'>Password</label>
                        <div className='password-box'>
                            <input
                                name="pwd"
                                placeholder="Enter Password"
                                type={isRevealPwd ? "text" : "password"}
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                                className='form-control input-box'
                            />
                            <img className='password-icon'
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                    </div>
                    <div>
                        <p className='demo-tetx'>{bureauError === undefined ? '' : bureauError}</p>
                    </div>
                    <div className="col-12 text-center mt-3">
                        <button className="upload-statement-butoon" type="button" onClick={() => {
                            sumbitCreditBureau()
                        }} >Submit</button>
                    </div>
                </form>}
            </div>
        </>
    )
}

export default CreditReport;