import React, { useEffect, useState } from 'react';
import './boost-score-credit.css';
import '../../../App.css';
import { boostScoreReport, refreshToken } from '../../../redux/actions/dashboard_action';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Boostscorebank from '../boost-score-bank/boost-score-bank';
import Addbankaccount from '../add-bank-account/add-bank-account';
import getUserData from '../../../Common/helper';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import AliceCarousel from 'react-alice-carousel';
import { authCode, emailVerification, emailParseData } from '../../../redux/actions/auth_action';
import ErrorMessage from '../../Dashboard/error-message/error-message';
import ScoreDetails from '../../Dashboard/Header-Page/components/score-details/score-range-dailog';
import { ReactDialogBox } from 'react-js-dialog-box';
import ReactApexChart from "react-apexcharts";
import BoostInsights from '../../Dashboard/Header-Page/components/insihght/boost-insights-dilog';
import Select, { components } from 'react-select';
import { customStyles } from '../../Dashboard/Header-Page/components/custom-style-component-bureau';

//Graph Component Defined 
import TranscationAnalysis from '../Component/Graph/transcation-analysis';
import TopCategory from '../Component/Graph/top-category';
import TopMerchant from '../Component/Graph/top-merchant';
import CreditCard from '../Component/Graph/credit-card-util';
import TopInvestment from '../Component/Graph/top-investment';
import MonthlyObligation from '../Component/Graph/monthaly_obligation';
import GetBankLogo from '../Component/Graph/get-bank-logo';

const Score = require("../../../images/assets/BoostScore.png");
const Expense = require("../../../images/assets/income_expense.png");
const Obligation = require("../../../images/assets/income_obligation.png");
const Investments = require("../../../images/assets/income_investments.png");
const Insurance = require("../../../images/assets/income_insurance.png");

const BankLogo = require("../../../images/Bank/Bank.png");
const SpendingLogo = require("../../../images/assets/top_spending.png");
const MerchantLogo = require("../../../images/assets/top_merchant.png");
const CreditCardLogo = require("../../../images/assets/credit_card.png");
const InvestmentLogo = require("../../../images/assets/top_investment.png");
const ObligationLogo = require("../../../images/assets/top_obligation.png");
const ConsentLogo = require("../../../images/assets/consent_logo.png");

const Boostscorecredit = (props) => {

  const [boostScoreReportData, setBoostScoreReportData] = useState();
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [userExists, setUserExists] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogHeight, setDialogHeight] = useState('auto');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [message, setMessage] = useState();
  const [analysisReportData, setAnalysisReportData] = useState();
  const [incomeVsExpenseData, setIncomeVsExpenseData] = useState();
  const [topSpendingCategoryData, setTopSpendingCategoryData] = useState();
  const [topMerchantData, setTopMerchantData] = useState();
  const [topCardUtilizationData, setTopCardUtilizationData] = useState();
  const [topMonthlyObligationData, setMonthlyObligationData] = useState();
  const [selectedAnalysisData, setSelectedAnalysisData] = useState();
  const [insightsDialog, setInsightsDialog] = useState(false);
  const [investmentData, setInvestmentData] = useState();
  const [getRefreshTokenStatus, setRefreshTokenData] = useState();
  const { state } = useLocation();
  const [insightsItem, setInsightsItem] = useState([])
  const [bankList, setBankList] = useState([])
  const [acHolderName, setAcHolderName] = useState()
  const [transactionPeriod, setTransactionPeriod] = useState()
  const [newBankName, setNewBankname] = useState();
  const [bankIndexCurrent, setBankIndexCurrent] = useState(0)

  let bankIndex = 0;
  let bankNameList = [];
  useEffect(() => {
    if (!user?.access_token) {
      navigate('/');
    } else {

      const data = getUserData()
      setUserData(data)

      getBoostScoreReport(0);
      //getRefreshTokenData();
    }
  }, [])

  const refreshTokenBtn = () => {
    setLoader(true);
    var param = {
      "isBankStatement": true
    }
    setMessage("")
    emailParseData(param).then(resp => {
      setLoader(false);
      if (resp.statusCode && resp.statusCode === "SUCCESS") {
        setMessage({ success: resp.statusMessage });
        navigate('/boostscore', { state: { "success": resp.statusMessage } });
        getBoostScoreReport();
      } else {
        setMessage({ error: resp.statusMessage });
        navigate('/boostscore', { state: { "error": resp.statusMessage } });
      }
    }).catch(error => {
      setLoader(false);
      if (error.statusCode === "ERROR") {
        setMessage({ error: error.statusMessage });
        navigate('/boostscore', { state: { "error": error.statusMessage } });
      }
    })
  }

  const submit = async () => {
    var param = {
      "email": email,
      "isBankStatement": true,
      "isCreditReport": false,
      "isBankStatementAsync": false
    }
    setLoader(true);
    emailVerification(param).then(resp => {
      setLoader(false);
      if (resp.statusCode === "SUCCESS") {
        window.location = resp.url;
      }
    }).catch(error => {
      setLoader(false);
      if (error.statusCode === "ERROR") {
        setMessage({ error: error.statusMessage });
        navigate('/boostscore', { state: { "error": error.statusMessage } });
      }
    })
  }

  const { user } = useSelector(state => {
    return state.authReducer;
  });

  const openInsights = (data, boostData) => {
    setSelectedAnalysisData(data);
    setInsightsDialog(true)
    setDialogHeight('auto')
    // window.dispatchEvent(new Event('resize'))
  }

  const openScoreDetails = (name) => {
    setScoreDitailsDialog(true)
    setDialogHeight('auto')
  }

  const closeinsght = () => {
    setInsightsDialog(false)
    setDialogHeight('auto')
    window.dispatchEvent(new Event('resize'))
  }

  const closeDialog = () => {
    setIsOpenDialog(false)
    setDialogHeight('auto')
  }

  const getRefreshTokenData = () => {
    refreshToken().then(resp => {
      setRefreshTokenData(resp);
    }).catch(error => {
      setRefreshTokenData(error);
    })
  }

  const insightsAnalysisReports = (analysisReportData) => {
    var i = [];
    analysisReportData?.ratio?.forEach(boostData => {
      i.push(
        <div className="col insights-main-box-height" key={boostData.status}>
          <div className='insights-sub-main-box h-100'>
            <div className="cards h-100">
              <div className="card-bod px-2 pt-3 pb-2">
                <div className='insights-imag-box mb-2'>
                  {(boostData && boostData.label === 'Income to Expense') ? <img src={Expense} className='img-fluid pb-1' alt="expense" /> : ""}
                  {(boostData && boostData.label === 'Income to Investments') ? <img src={Investments} className='img-fluid pb-1' alt="investments" /> : ""}
                  {(boostData && boostData.label === 'Income to Obligations') ? <img src={Obligation} className='img-fluid pb-1' alt="obligation" /> : ""}
                  {(boostData && boostData.label === 'Income to Insurance') ? <img src={Insurance} className='img-fluid pb-1' alt="insurance" /> : ""}
                </div>
                <h5 className="card-title insights-box-main-title p-0 m-0 truncate">{(boostData && boostData !== null) ? (boostData?.label && boostData?.label != null) ? boostData.label : '' : ''}</h5>
              </div>
              <div className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights(boostData, analysisReportData) }}>
                <p className="card-text insights-card-main-text p-0 m-0">{(boostData && boostData !== null) ? (boostData.status && boostData.status !== null) ? boostData?.status : '' : ''}</p>
                <div className='d-flex justify-content-between align-items-center mt-1'>
                  <h5 className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(boostData && boostData !== null) ? (boostData?.ratio && boostData?.ratio !== null) ? (boostData?.ratio) : '' : ''}</h5>
                  <i className="bi bi-chevron-right right-ariw-icons"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    setInsightsItem(i)
  }


  const Control = ({ children, ...props }) => {
    const [inputValue, setInputValue] = useState('');
    const { selectProps, hasValue } = props;

    return (
      <components.Control {...props}>
        <div style={{ position: 'relative', display: 'flex', }}>
          {hasValue || inputValue ? (
            <label
              style={{
                position: 'absolute',
                left: '6px',
                top: '-24px',
                backgroundColor: 'white',
                padding: '0 4px',
                fontSize: '80%',
                fontWeight: '400',
                fontFamily: 'Poppins',
                color: '#000000',
              }}
            >
              {selectProps.placeholder}
            </label>
          ) : null}
          {children}
        </div>
      </components.Control>
    );
  };
  const responsives = {

    0: {
      items: 1.8
    },
    360: {
      items: 2
    },
    375: {
      items: 2
    },
    390: {
      items: 2.1
    },
    412: {
      items: 2.2
    },
    414: {
      items: 2.2
    },
    430: {
      items: 2.3
    },
    540: {
      items: 3,
    },
    576: {
      items: 3
    },
    768: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    820: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    1024: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    1200: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
  };

  const handleChildCallback = (data) => {
    getBoostScoreReport(0)
  };


  const getBoostScoreReport = (index = 0) => {
    setLoader(true);
    boostScoreReport().then((resp) => {
      getBankList(resp.accounts);
      let resAccount = resp.accounts[index]
      setBoostScoreReportData(resp);
      setAnalysisReportData(resAccount.analysis)
      setNewBankname(resAccount?.analysis.accountDetail.bankName + " Bank | " + resAccount?.analysis.accountDetail.accountType + ' Account - ' + resAccount?.analysis.accountDetail.accountNumber.slice(-4))
      setAcHolderName(resAccount.analysis.accountDetail.accountHolderName)
      setTransactionPeriod(resAccount.analysis.accountDetail.transactionStartDate + ' - ' + resAccount.analysis.accountDetail.transactionEndDate)
      setIncomeVsExpenseData(resAccount.analysis.incomeVsExpenseAnalysis);
      setTopSpendingCategoryData(resAccount.analysis.categoryAnalysis);
      setTopMerchantData(resAccount.analysis.merchantAnalysis);
      setTopCardUtilizationData(resAccount.analysis.creditCardUtilization);
      setMonthlyObligationData(resAccount.analysis.obligation);
      setInvestmentData(resAccount.analysis.investment);
      insightsAnalysisReports(resAccount.analysis)
      setBankIndexCurrent(index)


      // PREV CODe
      // setAnalysisReportData(resp.analysis)
      // setAcHolderName(resp.analysis.accountDetail.accountHolderName)
      // setTransactionPeriod(resp.analysis.accountDetail.transactionStartDate + ' - ' + resp.analysis.accountDetail.transactionEndDate)
      // setIncomeVsExpenseData(resp.analysis.incomeVsExpenseAnalysis);
      // setTopSpendingCategoryData(resp.analysis.categoryAnalysis);
      // setTopMerchantData(resp.analysis.merchantAnalysis);
      // setTopCardUtilizationData(resp.analysis.creditCardUtilization);
      // setMonthlyObligationData(resp.analysis.obligation);
      // setInvestmentData(resp.analysis.investment);
      setLoader(false);

      if (resAccount.score > 0) {
        setScore(resAccount.score)
      }
      window.dispatchEvent(new Event('resize'))
    }).catch((error) => {
      setLoader(false);
    })
  }

  const getBankList = (bankLists) => {

    if (bankLists) {
      for (let index = 0; index < bankLists.length; index++) {
        const bankData = {
          bankName: bankLists[index].bankName,
          accountSubType: bankLists[index].accountSubType,
          accountType: bankLists[index].accountType,
          accountNumber: bankLists[index].accountNumber.slice(-4)
        };
        bankNameList.push(bankData)
        // bankNameList.push(bankLists[index].analysis.accountDetail.bankName + " | " + bankLists[index].analysis.accountDetail.accountType + ' Account - ' + bankLists[index].analysis.accountDetail.accountNumber.slice(-4));
      }
    }

    setBankList(bankNameList)

  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submit()
    }
  };

  const closeScoreDetails = () => {
    setScoreDitailsDialog(false)
    setDialogHeight('auto')
  }

  // let income = [];
  // let expense = [];
  // let categorie = [];


  // let categorieName = [];
  // let totalAmount = [];
  // let spendingPer = [];

  // let categorieMerName = [];
  // let totalMerAmount = [];

  // let cardData = [];
  // let cardCategoriesName = [];

  // let monthYearData = [];
  // let monthAmount = [];
  // let totalTransaction = [];

  // let invCategorieName = [];
  // let invTotalAmount = [];
  // let invSpendingPer = [];

  // useEffect(() => {

  //   {
  //     incomeVsExpenseData && incomeVsExpenseData.monthWiseData && incomeVsExpenseData.monthWiseData.forEach((incomeData) => {
  //       income.push(incomeData.incomeAmount)
  //       expense.push(incomeData.expenseAmount)
  //       categorie.push(incomeData.monthYear)
  //     })
  //   }

  //   {
  //     topSpendingCategoryData && topSpendingCategoryData.categoryData && topSpendingCategoryData.categoryData.forEach((spendingData) => {
  //       categorieName.push(spendingData.categoryName)
  //       totalAmount.push(spendingData.totalAmount)
  //       spendingPer.push(parseInt(spendingData.spendingPercentage))
  //     })
  //   }

  //   {
  //     topMerchantData && topMerchantData.categoryData && topMerchantData.categoryData.forEach((merchantData) => {
  //       categorieMerName.push(merchantData.categoryName)
  //       totalMerAmount.push(merchantData.totalAmount)
  //     })
  //   }

  //   {
  //     topCardUtilizationData && topCardUtilizationData.monthWiseAmount && topCardUtilizationData.monthWiseAmount.forEach((cardUtilization) => {
  //       cardData.push(cardUtilization.amount)
  //       cardCategoriesName.push(cardUtilization.monthYear)
  //     })
  //   }

  //   {
  //     topMonthlyObligationData && topMonthlyObligationData.monthWiseAmount && topMonthlyObligationData.monthWiseAmount.forEach((monthObligation) => {
  //       monthAmount.push(monthObligation.amount)
  //       monthYearData.push(monthObligation.monthYear)
  //       totalTransaction.push(monthObligation.totalTransaction)
  //     })
  //   }

  //   {
  //     investmentData && investmentData.categoryData && investmentData.categoryData.forEach((investment) => {
  //       invCategorieName.push(investment.categoryName)
  //       invSpendingPer.push(investment.spendingPercentage)
  //       invTotalAmount.push(investment.totalAmount)
  //     })
  //   }
  //   window.dispatchEvent(new Event('resize'))
  // })

  // const averageBalance = incomeVsExpenseData && incomeVsExpenseData.data && incomeVsExpenseData.data != null ? incomeVsExpenseData.data[0].amount : null

  // const barChart = {

  //   series: [{
  //     type: 'column',
  //     name: 'Income',
  //     data: income
  //   }, {
  //     type: 'column',
  //     name: 'Expense',
  //     data: expense
  //   }
  //   ],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350,
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     colors: ['#4580F6', '#F99176'],
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 4,
  //         horizontal: false,
  //         columnWidth: '40%',
  //         endingShape: 'rounded'
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent']
  //     },
  //     xaxis: {
  //       categories: categorie,
  //     },
  //     fill: {
  //       opacity: 1,
  //       type: "gradient",
  //       gradient: {
  //         type: "vertical",
  //         shadeIntensity: 1,
  //         opacityFrom: 1,
  //         opacityTo: 1,
  //         colorStops: [
  //           [
  //             {
  //               offset: 0,
  //               color: '#61DFFB',
  //               opacity: 1
  //             },
  //             {
  //               offset: 100,
  //               color: '#3549F3',
  //               opacity: 1
  //             }
  //           ],
  //           [
  //             {
  //               offset: 0,
  //               color: '#FECE92',
  //               opacity: 1
  //             },
  //             {
  //               offset: 100,
  //               color: '#F45D5F',
  //               opacity: 1
  //             }
  //           ]
  //         ]
  //       },
  //     },
  //     annotations: {
  //       yaxis: [
  //         {
  //           y: averageBalance,
  //           borderColor: '#000000',
  //           strokeDashArray: 5,
  //           label: {
  //             borderColor: '#000000',
  //             style: {
  //               color: '#000000',
  //               background: '#000000'
  //             },
  //           }
  //         }
  //       ]
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val.toLocaleString()
  //         }
  //       }
  //     },
  //     legend: {
  //       labels: {
  //         useSeriesColors: false,
  //       },
  //     },
  //   }
  // };

  // const pieChart = {

  //   series: spendingPer,
  //   options: {
  //     chart: {
  //       width: '55%',
  //       type: 'pie',
  //     },
  //     labels: categorieName,
  //     theme: {
  //       monochrome: {
  //         enabled: true
  //       }
  //     },
  //     plotOptions: {
  //       pie: {
  //         dataLabels: {
  //           offset: -5,
  //         }
  //       }
  //     },
  //     fill: {
  //       colors: ['#334BF3', '#8593F8', '#ADB7FA', '#D6DBFD', '#dddff0']
  //     },
  //     tooltip: {
  //       enabled: true,
  //       intersect: true,
  //       shared: false,
  //       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //         const categoryName = w.globals.labels[seriesIndex].toLowerCase()
  //         const categoryAmount = totalAmount[seriesIndex]
  //         return '<div class="arrow_box">' +
  //           '<p class="arrow-text">' + categoryName + '</p>' +
  //           '<p class="arrow-amt">' + '$ ' + categoryAmount.toLocaleString() + '</p>' +
  //           '</div>'
  //       }
  //     },
  //     dataLabels: {
  //       formatter(val, opts) {
  //         const dt = opts.w.globals.series[opts.seriesIndex]
  //         return [dt + "%"]
  //       }
  //     },
  //     legend: {
  //       show: false
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 1000,
  //         options: {
  //           chart: {
  //             height: 250
  //           },
  //         }
  //       }
  //     ]
  //   },
  // };

  // const investmentChart = {

  //   series: invSpendingPer,
  //   options: {
  //     chart: {
  //       width: '55%',
  //       type: 'pie',
  //     },
  //     labels: invCategorieName,
  //     theme: {
  //       monochrome: {
  //         enabled: true
  //       }
  //     },
  //     plotOptions: {
  //       pie: {
  //         dataLabels: {
  //           offset: -5,
  //         }
  //       }
  //     },
  //     fill: {
  //       colors: ['#334BF3', '#8593F8', '#ADB7FA', '#D6DBFD', '#dddff0']
  //     },
  //     tooltip: {
  //       enabled: true,
  //       intersect: true,
  //       shared: false,
  //       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //         const categoryName = w.globals.labels[seriesIndex].toLowerCase()
  //         const categoryAmount = invTotalAmount[seriesIndex]
  //         return '<div class="arrow_box">' +
  //           '<p class="arrow-text">' + categoryName + '</p>' +
  //           '<p class="arrow-amt">' + '$ ' + categoryAmount.toLocaleString() + '</p>' +
  //           '</div>'

  //       }
  //     },
  //     dataLabels: {
  //       formatter(val, opts) {
  //         const dt = opts.w.globals.series[opts.seriesIndex]
  //         return [dt + "%"]
  //       }
  //     },
  //     legend: {
  //       show: false
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 1000,
  //         options: {
  //           chart: {
  //             height: 250
  //           },
  //         }
  //       }
  //     ]
  //   },
  // };

  // const horizontalChart = {
  //   series: [{
  //     data: totalMerAmount
  //   }],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350,
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     fill: {
  //       opacity: 1,
  //       type: "gradient",
  //       gradient: {
  //         shadeIntensity: 1,
  //         opacityFrom: 1,
  //         opacityTo: 1,
  //         colorStops: [
  //           {
  //             offset: 0,
  //             color: "#7D25F6",
  //             opacity: 1
  //           },
  //           {
  //             offset: 100,
  //             color: "#2553F6",
  //             opacity: 1
  //           },
  //         ]
  //       }
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 4,
  //         horizontal: true,
  //         columnWidth: '20%',
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     xaxis: {
  //       categories: categorieMerName,
  //       labels: {
  //         show: true,
  //         hideOverlappingLabels: true,
  //         showDuplicates: false,
  //       }
  //     },
  //     yaxis: {
  //       labels: {
  //         show: true
  //       }
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 1000,
  //         options: {
  //           chart: {
  //             width: 260
  //           },
  //           yaxis: {
  //             labels: {
  //               show: false
  //             }
  //           },
  //           xaxis: {
  //             labels: {
  //               show: false,
  //             }
  //           },
  //           dataLabels: {
  //             enabled: true,
  //             textAnchor: 'start',
  //             style: {
  //               colors: ['#fff'],
  //               fontSize: '12px'
  //             },
  //             formatter: function (val, opt) {
  //               return opt.w.globals.labels[opt.dataPointIndex] + ":  " + "$ " + val.toLocaleString()
  //             },
  //             offsetX: -70,
  //             dropShadow: {
  //               enabled: true
  //             }
  //           },
  //           stroke: {
  //             width: 1,
  //             colors: ['#fff']
  //           },
  //         }
  //       }
  //     ]
  //   },
  // };

  // const lineChart = {

  //   series: [{
  //     name: "Transcation",
  //     data: cardData
  //   }],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: 'line',
  //       zoom: {
  //         enabled: false
  //       },
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       curve: 'straight'
  //     },
  //     fill: {
  //       opacity: 1,
  //       type: "gradient",
  //       gradient: {
  //         shadeIntensity: 1,
  //         opacityFrom: 1,
  //         opacityTo: 1,
  //         colorStops: [
  //           {
  //             offset: 0,
  //             color: "#364CF3",
  //             opacity: 1
  //           },
  //           {
  //             offset: 100,
  //             color: "#F66F67",
  //             opacity: 1
  //           },
  //         ]
  //       }
  //     },
  //     grid: {
  //       row: {
  //         colors: ['#f3f3f3', 'transparent'],
  //         opacity: 0.5
  //       },
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val.toLocaleString()
  //         }
  //       }
  //     },
  //     xaxis: {
  //       type: 'category',
  //       categories: cardCategoriesName
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 1000,
  //         options: {
  //           chart: {
  //             width: 300
  //           },
  //         }
  //       }
  //     ]

  //   },
  // };

  // const barChart1 = {

  //   series: [{
  //     name: 'Income',
  //     data: monthAmount
  //   }
  //   ],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350,
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 4,
  //         horizontal: false,
  //         columnWidth: '30%',
  //         endingShape: 'rounded',
  //         dataLabels: {
  //           position: 'top',
  //         },
  //       },
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function (val, opts) {
  //         const dataIndex = totalTransaction[opts.dataPointIndex];
  //         return dataIndex;
  //       },
  //       offsetY: -20,
  //       style: {
  //         fontSize: '12px',
  //         colors: ["#304758"]
  //       }
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent']
  //     },
  //     xaxis: {
  //       categories: monthYearData
  //     },
  //     fill: {
  //       opacity: 1,
  //       type: "gradient",
  //       gradient: {
  //         type: "vertical",
  //         shadeIntensity: 1,
  //         opacityFrom: 1,
  //         opacityTo: 1,
  //         colorStops: [
  //           {
  //             offset: 0,
  //             color: "#FECE92",
  //             opacity: 1
  //           },
  //           {
  //             offset: 100,
  //             color: "#F45D5F",
  //             opacity: 1
  //           },
  //         ]
  //       }
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val.toLocaleString()
  //         }
  //       }
  //     }
  //   },

  // };

  return (
    <>
      {loader && <Loader />}
      <div className='boost-score-heding-page'>
        {/* <div className='container-lg container-fluid mt-4 my-sm-3'>
          <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
            <div className='col-5'>
              <div className='ps-sm-2'>
                <h1 className='username-text mb-2 mb-sm-3'>Hi {userData?.given_name}!</h1>

              </div>
            </div>
            <div className='col-7'>
              <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                <div className='text-end text-sm-end me-sm-2'>
                  <p className='mb-0 userscore-textes'>Your <span className='boost-text'>Boost</span> score</p>
                  <h1 className={score && score !== null && score > 0 ? 'credit-score' : 'credit-score0'} >
                    {(score && score !== null) ? (score && score !== null && score !== null && Number(score) >= 0) ? score : '0' : '---'}
                  </h1>
                </div>
                <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                  <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                    {(score >= 780 && score <= 900) &&
                      <div className='excellect-box score-box'>
                        <p className='mb-0 score-box-text'>Excellent</p>
                      </div>}
                    {(score >= 706 && score <= 779) &&
                      <div className='goods-box score-box'>
                        <p className='mb-0 score-box-text'>Good</p>
                      </div>}
                    {(score >= 631 && score <= 705) &&
                      <div className='average-box score-box'>
                        <p className='mb-0 score-box-text'>Average</p>
                      </div>}
                    {(score > 0 && score <= 630) &&
                      <div className='poor-box score-box'>
                        <p className='mb-0 score-box-text'>Poor</p>
                      </div>}
                    {(score == undefined || score == null || score == 0) &&
                      <div className='score-not-show-box score-box mx-2'>
                        <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                      </div>}
                  </div>
                  <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1"
                    onClick={() => { openScoreDetails() }}
                  ></i></div>
                </div>
              </div>
            </div>

            <div className='crdit-score-dailog'>
              {scoreDitailsDialog && (
                <ReactDialogBox
                  closeBox={closeScoreDetails}
                  headerBackgroundColor='white'
                  headerTextColor='black'
                  closeButtonColor='black'
                  bodyBackgroundColor='white'
                  bodyTextColor='black'
                  headerText="Credit Score Range"
                  bodyHeight={dialogHeight}
                  borderRadius='8px'
                  headerHeight='65px'
                >
                  <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                </ReactDialogBox>)}
            </div>
          </div>
        </div> */}

        <div className='container-lg container-fluid mt-3'>
          <h1 className='header-title mb-2 mb-sm-3 ps-2'>Boost Report</h1>
        </div>

        {bankList.length !== 0 && <div className='container-lg container-fluid my-sm-3 pt-2 ps-lg-3'>
          <Select
            styles={customStyles}

            components={{ Control }}
            value={{ value: bankList[bankIndexCurrent], label: bankList[bankIndexCurrent] }}
            defaultValue={{ value: bankList[bankIndexCurrent], label: bankList[bankIndexCurrent] }}
            placeholder='Select Bank Account'
            isSearchable={false}
            options={bankList.map((bankNames, index) => ({
              value: bankNames,
              label: bankNames,
            }))}
            onChange={(index) => {
              bankIndex = bankList.indexOf(index.value);
              setBankIndexCurrent(bankIndex)
              getBoostScoreReport(bankIndex);
            }}
            formatOptionLabel={bankNames => (
              <div className='d-flex flex-row p-0 m-0  align-items-center'>
                <div className="bank-option pt-1">
                  <img src={GetBankLogo(bankNames.value.bankName)} alt="dropdown-logo" className='bank-dropdown-logo' />
                </div>
                <div className='p-0 m-0 ps-2 pt-1'>
                  <p className='p-0 m-0 bank-name'>{bankNames.value.bankName}</p>
                  <div className='d-flex flex-row p-0 m-0'>
                    <p className='p-0 m-0 text-capitalize bank-acc-name'>{bankNames.value.accountSubType}</p>
                    <p className='p-0 m-0 px-1 bank-acc-name'>|</p>
                    <p className='p-0 m-0 text-capitalize bank-acc-name'>{bankNames.value.accountType} - {bankNames.value.accountNumber}</p>
                  </div>
                </div>
              </div>
            )}
          />
        </div>}

        <div className='container-lg container-fluid mt-4 my-sm-3 '>

          <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
            <div className='col-5'>
              <div className='ps-sm-2'>
                <h1 className='username-text mb-2 mb-sm-3'>Hi {userData?.given_name}!</h1>
                <p className='credit-bureau-text mb-2'>A/C Holder Name: <span className='bold-txt'>{acHolderName}</span></p>
                <p className='credit-bureau-text mb-1'>Transaction Period: <span className='bold-txt'> {transactionPeriod}</span></p>
              </div>
            </div>
            <div className='col-7' id='boostscore'>
              <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                <div className='text-end text-sm-end me-sm-2'>
                  <p className='mb-0 userscore-textes'>Your <span className='boost-text'>Boost</span> score</p>
                  <h1 className={score && score !== null && score > 0 ? 'credit-score' : 'credit-score0'} >
                    {(score && score !== null) ? (score && score !== null && score !== null && Number(score) >= 0) ? score : '0' : '---'}
                  </h1>
                </div>
                <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                  <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                    {(score >= 780 && score <= 900) &&
                      <div className='excellect-box score-box'>
                        <p className='mb-0 score-box-text'>Excellent</p>
                      </div>}
                    {(score >= 706 && score <= 779) &&
                      <div className='goods-box score-box'>
                        <p className='mb-0 score-box-text'>Good</p>
                      </div>}
                    {(score >= 631 && score <= 705) &&
                      <div className='average-box score-box'>
                        <p className='mb-0 score-box-text'>Average</p>
                      </div>}
                    {(score > 0 && score <= 630) &&
                      <div className='poor-box score-box'>
                        <p className='mb-0 score-box-text'>Poor</p>
                      </div>}
                    {(score == undefined || score == null || score == 0) &&
                      <div className='score-not-show-box score-box mx-2'>
                        <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                      </div>}
                  </div>
                  <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1"
                    onClick={() => { openScoreDetails() }}
                  ></i></div>
                </div>
              </div>
            </div>

            <div className='crdit-score-dailog'>
              {scoreDitailsDialog && (
                <ReactDialogBox
                  closeBox={closeScoreDetails}
                  headerBackgroundColor='white'
                  headerTextColor='black'
                  closeButtonColor='black'
                  bodyBackgroundColor='white'
                  bodyTextColor='black'
                  headerText="Boost Score Range"
                  bodyHeight={dialogHeight}
                  borderRadius='8px'
                  headerHeight='65px'
                >
                  <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                </ReactDialogBox>)}
            </div>
          </div>
        </div>


        <section>
          <ErrorMessage message={message} state={state} />
        </section>

        {getRefreshTokenStatus && getRefreshTokenStatus.statusCode && getRefreshTokenStatus.statusCode === "SUCCESS" && <section id="refresh-score">
          <div className='container-lg container-fluid my-3'>
            <div className="show-message-box alert-success d-flex align-items-center w-100 justify-content-between">
              <div className='d-flex align-items-center align-items-sm-center'>
                <div class="circle red"></div>
                <p className='mb-0 ms-3 error-message-text-email'>Refresh Score to get latest Credit Score, Insights and much more.</p>
              </div>
              <button type="button" className="message-box-btn" onClick={() => { refreshTokenBtn() }}>
                <span className="bi bi-arrow-repeat"></span>
                <span className="msg-btn-text">Refresh Score</span>
              </button>
            </div>
          </div>
          <div className='container-lg container-fluid my-3'>
            <button type="button" className="message-box-btn score-btn-dis" onClick={() => { refreshTokenBtn() }}>
              <span className="bi bi-arrow-repeat"></span>
              <span className="msg-btn-text">Refresh Score</span>
            </button>
          </div>
        </section>}

        {analysisReportData && analysisReportData.ratio &&
          <section id='insights'>
            <div className='container-lg container-fluid my-3'>
              <div className='insights-main-box'>
                <div className='row g-2'>
                  <div className='col-12 p-0'>
                    <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>INSIGHTS</p>

                  </div>
                </div>
                <div className='dividers w-100'></div>
                <div className='row g-2'>
                  <div className='col-12 p-0 m-0'>
                    <p className='insights-sub-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Check out the factors affecting your BOOST score!</p>
                  </div>
                  <div className='col-12 parent-insight'>
                    {/* <div className="row row-cols-2 row-cols-md-5 row-cols-xl-5 g-3 gt-1">
                      {analysisReportData && analysisReportData.ratio && analysisReportData.ratio.map((boostData, inx) => {

                        return (
                          <>
                            <div className="col insights-main-box-height" key={inx} >
                              <div className='insights-sub-main-box h-100'>
                                <div className="cards h-100">
                                  <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                      {(boostData && boostData.label === 'Income to Expense') ? <img src={Expense} className='img-fluid' /> : ""}
                                      {(boostData && boostData.label === 'Income to Investments') ? <img src={Investments} className='img-fluid' /> : ""}
                                      {(boostData && boostData.label === 'Income to Obligations') ? <img src={Obligation} className='img-fluid' /> : ""}
                                      {(boostData && boostData.label === 'Income to Insurance') ? <img src={Insurance} className='img-fluid' /> : ""}
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(boostData && boostData !== null) ? (boostData?.label && boostData?.label != null) ? boostData.label : '' : ''}</h5>
                                  </div>
                                  <div className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights(boostData, analysisReportData) }}>
                                    <p className="card-text insights-card-main-text">{(boostData && boostData !== null) ? (boostData.status && boostData.status !== null) ? boostData?.status : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                      <h5 className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(boostData && boostData !== null) ? (boostData?.ratio && boostData?.ratio !== null) ? (boostData?.ratio) : '' : ''}</h5>
                                      <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div></>)
                      })}
                    </div> */}

                    <AliceCarousel
                      items={insightsItem}
                      responsive={responsives}>
                    </AliceCarousel>

                    <div className='insightdialog'>
                      {insightsDialog && (
                        <ReactDialogBox
                          closeBox={closeinsght}
                          headerBackgroundColor='white'
                          headerTextColor='black'
                          closeButtonColor='black'
                          bodyBackgroundColor='white'
                          bodyTextColor='black'
                          bodyHeight={dialogHeight}
                          borderRadius='8px'
                        >
                          <BoostInsights closeDialog={closeinsght}
                            setDialogHeight={setDialogHeight}
                            selectedAnalysisData={selectedAnalysisData}
                            setAnalysisReportData={analysisReportData}
                            setIncomeVsExpenseData={incomeVsExpenseData}
                            setTopSpendingCategoryData={topSpendingCategoryData}
                            setTopMerchantData={topMerchantData}
                            setTopCardUtilizationData={topCardUtilizationData}
                            setMonthlyObligationData={topMonthlyObligationData}
                            setInvestmentData={investmentData}
                          />
                        </ReactDialogBox>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section >}

        {analysisReportData && analysisReportData.sectionData && <section id='summatyaccount' className='accountdetailchart'>
          <section id='analysis'>
            <div className='container-lg container-fluid my-3'>
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    <div className='w-100'>
                      <div>
                        <div className='d-flex justify-content-between'>
                          <p className="acc-info-text" >Boost score analysis</p>
                          <span className='text-end me-2 view-text-all'>View All</span>
                        </div>
                      </div>
                      <section>
                        <div className='border-top'>
                          <div className='my-2'>
                            <div className='row align-items-center g-3'>
                              <div className='col-lg-2'>
                                <p className='account-summary-text'>Transaction Summary</p>
                                {/* <div className='dividers w-100'></div> */}
                              </div>
                              <div className='col-lg-10 row g-2'>
                                {analysisReportData && analysisReportData.sectionData && analysisReportData.sectionData.amountData.map((amtData, inx) => {

                                  return (
                                    <div className='col-6 col-md-3' key={inx}>
                                      <div className='acsummary-box'>
                                        {(amtData && amtData.title === "Total Income" ? <div className='balance-left-border-box'></div> : amtData.title === "Investments" ? <div className='payments-left-border-box'></div> : amtData.title === "Expenses" ? <div className='open-left-border-box'></div> : amtData.title === "Obligations" ? <div className='closed-left-border-box'></div> : "")}
                                        <div className='text-center mx-auto'>
                                          <p className='summary-sub-text'>{(amtData && amtData?.title !== null) ? (amtData?.title && amtData?.title != null) ? amtData?.title : '-' : '-'}</p>
                                          <h3 className='summary-numbers'>{(amtData && amtData?.amount !== null) ? (amtData?.amount && amtData?.amount != null) ? "$ " + amtData?.amount.toLocaleString() : '-' : '-'}</h3>
                                        </div>
                                      </div>
                                    </div>)
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </h2>

                  <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body">

                      {incomeVsExpenseData && incomeVsExpenseData.data != null &&
                        <TranscationAnalysis incomeVsExpenseData={incomeVsExpenseData} />
                      }
                      <div className='row mt-5 spending-category'>
                        {topSpendingCategoryData && topSpendingCategoryData.data != null &&
                          <TopCategory topSpendingCategoryData={topSpendingCategoryData} />
                        }
                        {topMerchantData && topMerchantData.data != null &&
                          <TopMerchant topMerchantData={topMerchantData} />
                        }
                      </div>
                      <div className='row mt-5 spending-category'>
                        {topCardUtilizationData && topCardUtilizationData.data != null &&
                          <CreditCard topCardUtilizationData={topCardUtilizationData} />
                        }
                        {investmentData && investmentData.data != null &&
                          <TopInvestment investmentData={investmentData} />
                        }
                      </div>
                      {topMonthlyObligationData && topMonthlyObligationData.data != null &&
                        <MonthlyObligation topMonthlyObligationData={topMonthlyObligationData} />
                      }


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>}

        {/* {getRefreshTokenStatus && getRefreshTokenStatus.tokenStatus && getRefreshTokenStatus.tokenStatus === "INVALID" && <div className='container-lg container-fluid'>
          <div className='row my-2 g-0 align-items-center'>
            <div className='col-sm-12'>
              <div className='boxes'>
                <div className='row g-2 align-items-start'>
                  <div className='col-sm-8 col-md-8'>
                    <div className='row text-start align-items-center align-items-sm-start'>
                      <div className='col-12 d-flex'>
                        <div className='mt-1 boost-score-image-box'>
                          <img src={Score} className='img-fluid' />
                        </div>
                        <div className='boost-gl-section'>
                          <p className='boost-score-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'>Get credit reports, statements and bills.</p>
                          <p className='boost-score-sub-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'>Login with Google and Stay on top of your Credit Score</p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='col-sm-4 col-md-4'>
                    <div className='score-buttton-box box-score-credit-box'>

                      <div className="abcRioButton abcRioButtonwhite gl-btn" onClick={() => { submit() }}>
                        <div className="abcRioButtonContentWrapper">
                          <div className="abcRioButtonIcon gl-padding">
                            <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18 gl-svg-btn">
                              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                              </path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                              </g>
                              </svg>
                            </div>
                          </div>
                          <span className="abcRioButtonContents gl-btn-text">
                            <span id="gl-btn">Login with Google</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='dashboard-error-view'>
                      {(showError && (!email || email === '')) && <span className="demo-tetx text-light">Please enter your email</span>}
                      {emailErrorMessage !== '' && <span className="demo-tetx mt-2 text-light">{emailErrorMessage}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>} */}

        {/* <Boostscorebank setScore={setScore}
          setMessage={setMessage}
          scrollToTop={props.scrollToTop}
          setAnalysisReportData={setAnalysisReportData}
          setIncomeVsExpenseData={setIncomeVsExpenseData}
          setTopSpendingCategoryData={setTopSpendingCategoryData}
          setTopMerchantData={setTopMerchantData}
          setTopCardUtilizationData={setTopCardUtilizationData}
          setMonthlyObligationData={setMonthlyObligationData}
          setInvestmentData={setInvestmentData}
        /> */}

        <Addbankaccount setScore={setScore}
          setMessage={setMessage}
          scrollToTop={props.scrollToTop}
          setAnalysisReportData={setAnalysisReportData}
          setIncomeVsExpenseData={setIncomeVsExpenseData}
          setTopSpendingCategoryData={setTopSpendingCategoryData}
          setTopMerchantData={setTopMerchantData}
          setTopCardUtilizationData={setTopCardUtilizationData}
          setMonthlyObligationData={setMonthlyObligationData}
          setInvestmentData={setInvestmentData}
          onChildEvent={handleChildCallback}

        />
        <div>
          <Footer />
        </div>

      </div >

    </>
  )
}

export default Boostscorecredit