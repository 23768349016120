
import { useEffect, useState } from 'react';
import '../../../App.css';
import QBMenuBar from './qb-menubar/qb-menubar';
import QBSidebar from './qb-sidebar/qb-sidebar';
import MenuBar from '../../Dashboard/Menubar/menubar';
import Sidebar from '../../Dashboard/SideBar/sidebar';
import QBChat from '../qb-chat/qb-chat';

function QBDashboard(props) {
    const [loader, setLoader] = useState(false);
   

    return (<>
        <MenuBar />
        <div className='sidebar-box sidnav-box'>
            <Sidebar></Sidebar>
            <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                <QBChat>

                </QBChat>
            </div>


        </div>
    </>);

}

export default QBDashboard;