// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.early-access-button {
    width: max-content;
}

@media (max-width:767px) {
    .early-access-button {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/landing-page/Slider/early-access/early-access.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".early-access-button {\n    width: max-content;\n}\n\n@media (max-width:767px) {\n    .early-access-button {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
