import React, { useState, useEffect } from "react";

import "../../profile/profile_component/profile_dropdown.css";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getUserData from '../../../Common/helper';

const IC_LOGOUT = require("../../../images/assets/ic-logout.png");
const IC_USER = require("../../../images/assets/user-list-ic.png");

const QBProfileDropdown = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isConfingShowProd, setConfigShowProd] = useState(false);
    const [userData, setUserData] = useState();

    // useEffect(() => {
    //     const data = getUserData();
    //     setUserData(data);
    // }, []);

    const profilePage = () => {
        //navigate('/connect-quickbook')
        props.setShowProfile(false)
    }

    const logout = async () => {
        navigate('/connect-quickbook')
        // var result = dispatch(logOut());
        // if (result) {
        //     navigate('/login')
        // }
    }

    return (
        <>
            <div className="profile-dropdown-view">
                <div className="pro-row-dropdown">
                    <div className="pro-dropdown-circular">
                        {/* <img
                  src={Avatar_logo}
                  className="pro-dropdown-avatar"
                  alt="avatar"
                ></img> */}
                        {userData && <div className="pro-dropdown-avatar">{userData && userData?.name != undefined ? userData?.name.charAt(0).toUpperCase() : ''}</div>}

                    </div>
                    <div className="pro-dropdown-data">
                        <p className="pro-dropdwon-username">{userData?.name ?? 'B'}</p>

                    </div>
                    <div className="pro-dropdown-divider"></div>

                    <div className="pro-dropdown-3-items" onClick={() => {
                        profilePage()
                    }}>
                        <div className="pro-dropdown-items">
                            <img
                                src={IC_USER}
                                className="pro-dropdown-item-icon"
                                alt="setting"
                            ></img>
                            <p className="pro-dropdown-item-name">My Account</p>
                        </div>
                        {/* <img src={IC_ARROW}  className="pro-dropdown-item-icon"  alt="arrow" ></img> */}
                    </div>
                    <div className="pro-dropdown-space"></div>

                    <div className="pro-dropdown-items" onClick={() => { logout() }}>
                        <img
                            src={IC_LOGOUT}
                            className="pro-dropdown-item-icon"
                            alt="logout"
                        ></img>
                        <p className="pro-dropdown-item-name">Logout</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QBProfileDropdown;