import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactDialogBox } from 'react-js-dialog-box'
import AIInfo from '../AI-Info/ai-info';
import '../boost-ai.css'
import ChatAI from '../Chat/chatai';

const BankBoostMoney = require("../../../images/boost-ai.gif");

function ChatIcon(props) {
    const navigate = useNavigate();
    const[showChatIcon,setShowChatIcon] = useState(false)  // Boost AI Icon
    const[showChatAIInfoBox,setShowChatAIInfoBox] = useState(false) // Manage Screen AI Info OR Chat AI

    const[showAIInfoBox, setShowAIInfoBox] = useState(false) // Manage Info Screen
    const[showGenAI,setShowGenAI] = useState(false) // Manage Gen AI Screen

    const [dialogHeight, setDialogHeight] = useState('auto');
    const [dialogTitle, setDialogTitle] = useState('BOOST AI')


    useEffect(() => {
        if (user?.access_token) {
            setShowChatIcon(true)
        }
    }, [])

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const openChatAIInfo = () =>{
        setShowChatAIInfoBox(true)
        setShowAIInfoBox(true)
        setShowChatIcon(false)
    }

    const closeInfoDialog = () => {
        setShowChatAIInfoBox(false)
        setShowChatIcon(true);

        setShowAIInfoBox(false)
        setShowGenAI(false)

        setDialogTitle('BOOST AI')
        setDialogHeight('auto')
    }

    return (
        <>
        {showChatIcon && <div className='chat-box' onClick={() => {openChatAIInfo()}}>
            <img src={BankBoostMoney} className='chat-ai-icon' alt='chat-gif'/>
        </div>}

        {showChatAIInfoBox && (
            <div className='chat-dialog'>
                <ReactDialogBox
                    headerBackgroundColor='white'
                    headerTextColor='black'
                    closeButtonColor='black'
                    bodyBackgroundColor='white'
                    bodyTextColor='black'
                    bodyHeight={dialogHeight}
                    borderRadius='8px'
                    headerHeight='65px'
                    headerText="BOOST AI"
                    closeBox={closeInfoDialog}
                >

                {showAIInfoBox &&
                    <AIInfo setShowChatAIInfoBox={setShowChatAIInfoBox} setShowChatIcon={setShowChatIcon} setShowAIInfoBox={setShowAIInfoBox} setShowGenAI={setShowGenAI}></AIInfo>
                }
                
                {showGenAI && 
                     <ChatAI closeInfoDialog={closeInfoDialog}></ChatAI>
                }
        </ReactDialogBox> </div> )}
        </>
    );
}

export default ChatIcon;