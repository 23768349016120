import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Tooltip from '../../../Component/tool-tip';

const TranscationAnalysis = (props) => {

  let income = [];
  let expense = [];
  let categorie = [];
  let incomeVsExpenseData = props.incomeVsExpenseData ? props.incomeVsExpenseData : null

  useEffect(() => {
    {
      incomeVsExpenseData && incomeVsExpenseData.monthWiseData && incomeVsExpenseData.monthWiseData.forEach((incomeData) => {
        income.push(incomeData.incomeAmount)
        expense.push(incomeData.expenseAmount)
        categorie.push(incomeData.monthYear)
      })
    }
  })

  const averageBalance = incomeVsExpenseData && incomeVsExpenseData?.data && incomeVsExpenseData?.data[0] && incomeVsExpenseData?.data[0] != null ? incomeVsExpenseData.data[0].amount : null

  const barChart = {
    series: [{
      type: 'column',
      name: 'Income',
      data: income
    }, {
      type: 'column',
      name: 'Expense',
      data: expense
    }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      colors: ['#4580F6', '#F99176'],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: categorie,
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            [
              {
                offset: 0,
                color: '#61DFFB',
                opacity: 1
              },
              {
                offset: 100,
                color: '#3549F3',
                opacity: 1
              }
            ],
            [
              {
                offset: 0,
                color: '#FECE92',
                opacity: 1
              },
              {
                offset: 100,
                color: '#F45D5F',
                opacity: 1
              }
            ]
          ]
        },
      },
      annotations: {
        yaxis: [
          {
            y: averageBalance,
            borderColor: '#000000',
            strokeDashArray: 5,
            label: {
              borderColor: '#000000',
              style: {
                color: '#000000',
                background: '#000000'
              },
            }
          }
        ]
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val.toLocaleString()
          }
        }
      },
      legend: {
        labels: {
          useSeriesColors: false,
        },
      },
    }
  };

  return (
    <>
      {incomeVsExpenseData && incomeVsExpenseData.data != null &&
        <div className='row'>
          <div className='col-12'>
            <div className='account-details-box'>
              <div className="accordion incomevsexpense" id="accordionFlushExample">
                <p className="acc-info-text">Transaction Analysis
                  <Tooltip content="Income to Expense analysis for the past 12 months">
                    <i className="bi bi-info-circle boost-info-icon me-1"></i>
                  </Tooltip>
                </p>
                <div className='dividers w-100'></div>

                <div className="average-monthly-row mb-3">
                  <div className="average-monthly-column income-clm">Average Monthly Income :  ${incomeVsExpenseData && incomeVsExpenseData?.data[1] != null ? incomeVsExpenseData.data[1].amount : ''}</div>
                  <div className="average-monthly-column expense-clm">Average Monthly Expense :  ${incomeVsExpenseData && incomeVsExpenseData?.data[2] != null ? incomeVsExpenseData.data[2].amount : ''}</div>
                  <div className="average-monthly-column">Average Monthly Balance :  ${incomeVsExpenseData && incomeVsExpenseData?.data[0] != null ? incomeVsExpenseData.data[0].amount : ''}</div>
                </div>
                <ReactApexChart options={barChart.options} series={barChart.series} type="bar" height={350} />
                <p className="chart-bottom-text">MONTHLY INCOME TO EXPENSE CHART</p>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default TranscationAnalysis