import React, { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";

const OpenPlaidLink = ({ token, onSuccess, onExit, clientName, env, product }) => {
    const config = {
        token,
        onSuccess,
        onExit,
        clientName,
        env,
        product,
    };

    const { open, ready } = usePlaidLink(config);

    // This opens Link as soon as it's ready
    useEffect(() => {
        if (!ready) {
            return;
        }
        open();
    }, [ready, open]);

    // Don't render anything, just open Link
    return null;
};

export default OpenPlaidLink;
