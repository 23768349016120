import '../../../App.css'
import './neobanking.css'
const CreditScore = require("../../../images/icon1.png");
const BankBoostMoney = require("../../../images/icon3.png");
function Banking() {

    return (
        <>
            <div className='my-5 my-sm-2'>
                <div className='container-lg container-fluid'>
                    <div className='banking-box'>
                        <h1 className='banking-heading-text mt-5'><span className='boost-text'>Boost</span> neo banking</h1>
                    </div>
                    <div className='row boxes-box px-md-5 g-4'>
                        <div className='col-sm-12 col-md-6'>
                            <div className='boxs-cards'>
                                <div className="card-body">
                                    <div className="details-txt text-center mt-3">
                                        <h2 className="testimonial-text text-testy "> <span className='boost-text'> boost</span> credit card</h2>
                                        <p className="testy1-text my-4">A new way of banking with the credit card</p>
                                        <img className=" mt-5" src={CreditScore} alt="alternative" width='130' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 text-center'>
                            <div className='boxs-cards'>
                                <div className="card-body">
                                    <div className="details-txt text-center mt-3">
                                        <h2 className="testimonial-text testy-text"><span className='boost-text'>boost</span> savings account</h2>
                                        <p className="testy1-text my-4">Open a savings account Without hassel</p>
                                        <img className=" mt-5" src={BankBoostMoney} alt="alternative" width='130' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banking;