import { Fragment, useEffect, useState } from 'react';
import './disconnect-modal.css';



function DisconnectModal({ onClose, onClickDisconnect }) {


    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content-experian bg-white" onClick={e => e.stopPropagation()}>

                <section className='mt-4'>

                    <h4>Revoke access?</h4>
                    <p>Do you want to disconnect and revoke Intuit QuickBooks?</p>

                    <div className='d-inline-flex'>
                        <button className="btn-qb-disconnect btn-qb-discard" onClick={onClose}>
                            <span className='m-0 p-0'>Discard </span></button>

                        <div className='px-3'></div>

                        <button className="btn-qb-disconnect" onClick={onClickDisconnect}>
                            <span className='m-0 p-0'>Disconnect </span></button>
                    </div>

                </section>




            </div>
        </div>
    );
}
export default DisconnectModal;