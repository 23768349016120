import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import './qb-sidebar.css';

const QBSidebar = () => {
    const [showNav, setShowNav] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentRoute, setCurrentRoute] = useState('/');
    const [showQBGenAI, setQBGenAI] = useState(false);
    const [showIntegration, setIntegration] = useState(false);


    const QBIntegration = () => {
        navigate('/qb-integration')
    }

    const QBGenAI = () => {
        navigate('/qb-dashboard')
    }

    useEffect(() => {
        setCurrentRoute(window.location.pathname);
        setIntegration(false);
        setQBGenAI(false);
        const showIntegrationGenAI = window.location.pathname === '/qb-integration';
        if (showIntegrationGenAI) {
            setIntegration(true)
        }
        const showQBGenAI = window.location.pathname === '/qb-dashboard';
        if (showQBGenAI) {
            setQBGenAI(true)
        }
    }, [])

    return (
        <div className={`l-navbar${showNav ? ' shows' : ''}`}>
            <nav className="nav navbox">
                <div>
                    <div className='icon-text-box'>
                        <div className='d-flex justify-content-between'>

                        </div>
                    </div>
                    <div className="nav_list">
                        <ul>
                            <li>
                                <a className={currentRoute === "/qb-dashboard" ? "nav_link active " : "nav_link"} rel="noopener" onClick={() => { QBGenAI() }} id="sliderCollapse" data-bs-toggle="collapse" href="#collapseExampleSidebar" role="button" aria-controls="collapseExampleSidebar">
                                    <i className="bi bi-file-bar-graph nav_icon"></i><span className="nav_name">Generative AI <i className="bi bi-chevron-down nav_icon nav-icons"></i></span>
                                </a>
                                <div className={`collapse ${showQBGenAI ? 'show' : ''}`} id="collapseExampleSidebar">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className="nav_links" href="#qb-chat">Boost AI</a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className={currentRoute === "/qb-integration" ? "nav_link active" : "nav_link"} rel="noopener" onClick={() => { QBIntegration() }} data-bs-toggle="collapse" href="#collapseExampleTwo" role="button" aria-expanded="false" aria-controls="collapseExampleTwo">
                                    <i className="bi bi-lightning-charge nav_icon"></i><span className="nav_name">Integrations &nbsp;<i className="bi bi-chevron-down nav_icon nav-icons ps-2"></i></span>
                                </a>
                                <div className={`collapse ${showIntegration ? 'show' : ''}`} id="collapseExampleTwo">
                                    <div className="card card-body">
                                        <ul className="ms-5">
                                            <li>
                                                <a className={window.location.hash === "#intuitQB" ?
                                                    "nav_links active" : "nav_links"} href="#intuitQB">Intuit QuickBooks</a>
                                            </li>



                                        </ul>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
export default QBSidebar;